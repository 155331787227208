import HttpClient from './http-clients/portaljad'

const baseUrl = 'regioes-jadlog'

export default class RegioesJadlogService {
  static async findAll() {
    try {
      const { data } = await HttpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}