<template>
  <b-card>
    <div class="d-flex flex-column mb-1">
      <span>Nome: {{ data.campoNome }}</span>
    </div>
    <div class="d-flex flex-column mb-1">
      <span>Usuário: {{ data.usuarioNome }}</span>
      <span>Data: {{ data.dataModificacao }}</span>
    </div>
    <div
      v-for="(item, index) in data.campo"
      :key="index"
    >
      <div>
        <span>
          Campo: {{ item.campo }}
        </span>
      </div>
      <div class="pb-1">
        <span>
          Valor: {{ translateData(item.valor) }}
        </span>
      </div>
    </div>
  </b-card>
</template>

<script>

export default {
  name: 'DetailsLog',
  props: {
    data: {
      type: undefined,
      required: true,
    },
  },
  methods: {
    translateData(data) {
      return data.replace('true', 'Sim').replace('false', 'Não')
    },
  },
}
</script>