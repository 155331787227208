<template>
  <div>
    <b-card 
      no-body 
      class="mb-2"
    >
      <div class="m-2">
        <validation-observer #default="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <b-row align-v="center">
              <b-col cols="3">
                <validation-provider 
                  #default="validationContext"
                  v-model="dateRange"
                  name="date-range"
                >
                  <date-range :date-range.sync="dateRange" />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                cols="4"
              >
                <b-form-checkbox
                  v-model="reversaCliente"
                  switch
                >
                  <span class="pl-1">Considerar somente datas sem movimento</span>
                </b-form-checkbox>
              </b-col>
              <b-col 
                cols="2"
                class="d-flex justify-content-end"
              >
                <div>
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mb-sm-0 mr-0"
                  >
                    Pesquisar
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
    <b-card
      no-body 
      class="mb-2"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1"
              placeholder="Buscar..."
              debounce="500"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-center mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>clientes por página</label>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-model="mostrarSemCadastro"
              @change="onSubmit"
            >
              Mostrar Sem Cadastro
            </b-form-checkbox>
          </b-col>
          <b-col 
            cols="12"
            md="2"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="gerarExcel(dateRange.startDate, dateRange.endDate)"
            >
              <span class="text-nowrap">Exportar Excel</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card 
      no-body 
      class="mb-2"
    >
      <div class="m-2">
        <b-table
          ref="tableRef"
          show-empty
          :items="dadosTable"
          :fields="tableColumns"
          :filter="search"
          :per-page="perPage"
          :current-page="currentPage"
          sticky-header
          :empty-text="carregando ? 'Carregando...' : 'Nenhum dado encontrado'"
          empty-filtered-text="Nenhum resultado encontrado com os filtros informados."
          @filtered="onFiltered"
        >
          <template #cell(valor)="data">
            <span>{{ data.item.valor ? maskCurrency(data.item.valor) : '' }}</span>
          </template>
          <template #cell(porcentagem)="data">
            <span>{{ `${data.item.porcentagem ? maskDecimal(data.item.porcentagem) : 0}%` }}</span>
          </template>
          <template #cell(porcentagemAcumulada)="data">
            <span>{{ `${data.item.porcentagemAcumulada ? maskDecimal(data.item.porcentagemAcumulada) : 0}%` }}</span>
          </template>
          <template #head(classificacao)="{}">
            <feather-icon
              icon="MenuIcon"
              size="16"
            />
          </template>
          <template #cell(classificacao)="data">
            <b-badge
              :variant="data.item.rank === 'A' ? 'success' : (data.item.rank === 'B' ? 'warning' : 'danger')"
            >
              {{ data.item.rank }}
            </b-badge>
          </template>
          <template slot="bottom-row">
            <td/><td/>
            <td class="table-footer">
              Total Geral
            </td>
            <td class="table-footer">
              {{ maskCurrency(totalSoma('valor')) }}
            </td>
          </template>
        </b-table>
        <div class="m-2">
          <b-row v-if="totalRows > 0">
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Mostrando do 
                {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
                {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                  totalRows
                }} resultados</span>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import DateRange from '@/components/form/DateRange.vue'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import moment from 'moment'
import utils from '@/helpers/utils'
import ExcelComposition from '@/components/exportar/excel.composition'

export default {
  name: 'CurvaAbc',
  components: { DateRange },
  methods: {
    separaValores(obj, tipoSoma) {
      if (tipoSoma === 'valor') {
        const value = obj.valor ? obj.valor : 0
        return Number(value)
      }
      return 0
    },
    totalSoma(tipoSoma) {
      let total = 0
      if (this.$refs.tableRef) {
        const array = this.$refs.tableRef.filteredItems
        array.forEach(obj => {
          const valor = this.separaValores(obj, tipoSoma)
          total += valor
        })
      }
      return total
    },
  },
  setup() {
    const { appCarregando } = $themeConfig.app
    const curvaAbcStore = createNamespacedHelpers('curvaAbc')

    const state = reactive({
      dateRange: {
        startDate: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        endDate: utils.dateRangeComTimeZone(),
      },
      mostrarSemCadastro: true,
      dados: curvaAbcStore.useGetters(['dados']).dados,
      dadosTable: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      carregando: false,
      reversaCliente: false,
    })
    watch(() => state.carregando, value => {
      if (value) {
        appCarregando.show()
      } else {
        appCarregando.hide()
      }
    })

    const sendRequest = filtros => {
      state.carregando = true
      store.dispatch('curvaAbc/findDados', filtros).then(() => {
        state.carregando = false
      })
    }

    watch(() => state.dados, value => {
      state.dadosTable = []
      if (value && value.length) {
        state.totalRows = value.length
        const total = value.length
        const classifA = Math.round(0.2 * total)
        const classifB = classifA + Math.round(0.3 * total)
        let acum = 0.00
        value.forEach((obj, index) => {
          acum += Number(obj.porcentagem)
          let rank
          if (acum > 100 || (index + 1) === total) {
            acum = 100
          }
          if ((index + 1) <= classifA) {
            rank = 'A'
          } else if ((index + 1) <= classifB) {
            rank = 'B'
          } else {
            rank = 'C'
          }
          state.dadosTable.push({
            ...obj,
            numero: index + 1,
            porcentagemAcumulada: acum,
            rank,
          })
        })
      } else {
        state.totalRows = 0
      }
    })

    const table = {
      search: ref(''),
      sortBy: ref(''),
      isSortDirDesc: ref(false),
      tableColumns: [
        { key: 'clienteId', label: 'Código', sortable: true },
        { key: 'clienteNome', sortable: true, label: 'Cliente', tdClass: 'text-nowrap' },
        { key: 'clienteDesde', sortable: true, label: 'Cliente Desde' },
        { key: 'valor', label: 'Valor Total', sortable: true },
        { key: 'porcentagem', label: '%', sortable: true },
        { key: 'porcentagemAcumulada', label: '% Acumulada', sortable: true },
        { key: 'classificacao', formatter: (_value, _key, item) => (item.rank ? item.rank : ''), sortable: true, sortByFormatted: true },
      ],
    }

    const onSubmit = () => {
      state.currentPage = 1
      table.search.value = ''
      const filtros = {
        dataInicial: state.dateRange.startDate,
        dataFinal: state.dateRange.endDate,
        mostrarSemCadastro: state.mostrarSemCadastro,
        reversaCliente: state.reversaCliente,
        
      }
      sendRequest(filtros)
    }

    onMounted(() => {
      onSubmit()
    })

    const { getValidationState } = formValidation()

    const tableRef = ref(null)
    const { downloadSpreadsheetWithHeader } = ExcelComposition()
    const downloadExcel = (dataInicio, dataFim) => {
      const dados = tableRef.value.sortedItems.map(d => ({
        clienteId: d.clienteId,
        nome: d.clienteNome,
        clienteDesde: d.clienteDesde,
        valor: Number(d.valor),
        porcentagem: Number(d.porcentagem),
        porcentagemAcumulada: Number(d.porcentagemAcumulada),
        rank: d.rank,
      }))
      const nomeRelatorio = 'Curva ABC'
      const heading = [['Cód', 'Nome Cliente', 'Cliente Desde', 'Valor Total', '%', '% Acumulada', 'Rank']]
      appCarregando.hide()
      if (dados.length === 0) {
        swal.fire({
          icon: 'warning',
          title: 'Sem dados para exportar',
          text: 'O Resultado da sua busca não retornou dados para exportação.',
          showConfirmButton: false,
          timer: 3000,
        })
      } else {
        downloadSpreadsheetWithHeader(nomeRelatorio, heading, dataInicio, dataFim, dados, nomeRelatorio)
      }
    }
    const gerarExcel = (dataInicio, dataFim) => {
      appCarregando.show()
      setTimeout(() => {
        downloadExcel(dataInicio, dataFim)
      }, 100)
    }

    const onFiltered = filteredItems => {
      state.currentPage = 1
      state.totalRows = filteredItems.length
    }

    return {
      ...toRefs(state),
      onSubmit,
      getValidationState,
      ...table,
      ...utils,
      tableRef,
      gerarExcel,
      onFiltered,
    }
  },
}
</script>

<style scoped>
.table-footer {
    font-weight: bold;
    background: #E9E9E9;
    border-bottom: 3px solid #F55559;
    font-size: 1.2rem;
  }
</style>