<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="d-flex justify-content-between flex-column"
        >
          <div class="d-flex justify-content-start">
            <div class="d-flex flex-column ml-1">
              <h4 class="mb-1">
                {{ regiaoImportacao.nome }}
              </h4>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="outline-danger"
                  @click="direcionarParaRegioes()"
                >
                  Voltar
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th
                class="pb-50"
                style="width:30%"
              >
                <feather-icon
                  icon="ArrowUpIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Arquivo Importado</span>
              </th>
              <td class="pb-50">
                <a
                  :href="`${regiaoImportacao.urlArquivoImportado}`"
                  target="_blank"
                >
                  {{ regiaoImportacao.nomeArquivoImportado }}
                </a>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Data de Importacao</span>
              </th>
              <td class="pb-50">
                {{ maskDate(regiaoImportacao.dataImportacao) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Data de Vigência</span>
              </th>
              <td class="pb-50">
                {{ maskDate(regiaoImportacao.dataVigencia) }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">Regiões</span>
          </b-card-title>
          <b-col cols="8">
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text>
                    <feather-icon
                      icon="FilterIcon"
                      size="18"
                    />
                  </b-input-group-text>
                </template>
                <b-form-input
                  v-model="search"
                  class="d-inline-block mr-1"
                  placeholder="Buscar..."
                />
              </b-input-group>
            </div>
          </b-col>
        </b-card-header>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Mostrando</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>regiões por página</label>
        </b-col>
        <b-table
          id="my-table"
          :items="regioes"
          :fields="tableColumns"
          responsive
          :filter="search"
          stacked="sm"
          sort-by="localidade"
          :per-page="perPage"
          :current-page="currentPage"
        />
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                aria-controles="my-table"
                :total-rows="regioes.length"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { mapGetters } from 'vuex'
import utils from '@/helpers/utils'
import { reactive, toRefs, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import RegioesComposition from '../regioes.composition'

export default {
  name: 'IndexVue',

  computed: {
    ...mapGetters({
      firebaseUser: 'firebase/firebaseUser',
    }),
  },
  watch: {
    firebaseUser() {
      this.$store.dispatch('regioes/findAll')
    },
  },
  beforeMount() {
    this.$store.dispatch('regioesJadlog/findAll')
  },

  methods: {
    direcionarParaRegioes() {
      this.$router.push('/cadastro/regioes')
    },
  },

  setup() {
    const regioesJadStore = createNamespacedHelpers('regioesJadlog')

    const regiaoImportacaoInit = RegioesComposition().regioes.value[0]
    const state = reactive({
      regiaoImportacao: _.cloneDeep(regiaoImportacaoInit),
      regioes: regioesJadStore.useGetters(['regioes']).regioes,
      search: '',
      perPageOptions: [10, 25, 50, 100],
      perPage: 10,
      regioesTable: null,
      currentPage: 1,
      sortBy: 'nome',
      isSortDirDesc: false,
    })
    const tableColumns = [
      { key: 'ufDestino', sortable: true, label: 'Estado Destino' },
      { key: 'localidade', sortable: true, label: 'Localidade' },
      { key: 'regiao', sortable: true, label: 'Tarifa' },
      { key: 'cepDestinoInicial', sortable: true, label: 'De' },
      { key: 'cepDestinoFinal', sortable: true, label: 'Até' },
      { key: 'prazoExpresso', sortable: true, label: 'Prazo Expresso' },
      { key: 'prazoRodoviario', sortable: true, label: 'Prazo Rodoviário' },
      { key: 'temPagamentoDestino', sortable: false, label: 'FRAP' },
      { key: 'temRedespachoFluvial', sortable: false, label: 'Fluvial' },
      { key: 'unidadeDistribuidora', sortable: true, label: 'Unidade Distribuidora' },
    ]

    const tableCounts = computed(() => {
      const localItemsCount = state.regioesTable.value ? state.regioesTable.value.localItems.length : 0
      return {
        primeiro: state.perPage.value * (state.currentPage.value - 1) + (localItemsCount ? 1 : 0),
        ultimo: state.perPage.value * (state.currentPage.value - 1) + localItemsCount,
        total: 0,
      }
    })

    return {
      ...toRefs(state),
      ...utils,
      tableCounts,
      // TABLE
      tableColumns,
      // MISC
      avatarText,
    }
  },
}
</script>

<style scoped>

</style>