import MovimentoService from '@/service/MovimentoService'

export default {
  namespaced: true,
  state: {
    movimentos: [],
  },
  getters: {
    movimentos(state) {
      return state.movimentos
    },
  },
  mutations: {
    UPDATE_MOVIMENTOS(state, movimentos) {
      state.movimentos = movimentos
    },
    SAVE_MOVIMENTO(state, movimento) {
      state.movimentos.push(movimento)
    },
  },
  actions: {
    async findAll({ commit }) {
      const movimentos = await MovimentoService.findAll()
      commit('UPDATE_MOVIMENTOS', movimentos)
    },
    async save(_, multipart) {
      const movements = await MovimentoService.uploadMultipart(multipart)
      return movements
    },
    async removerImportacoes(_, importacaoId) {
      return MovimentoService.deleteImport(importacaoId)
    },
  },
}