import ServicoService from '@/service/ServicoService'

export default {
  namespaced: true,
  state: {
    servicos: null,
    servicosOptions: [],
  },
  getters: {
    servicos(state) {
      return state.servicos
    },
    servicosOptions(state) {
      return state.servicosOptions
    },
  },
  mutations: {
    UPDATE_SERVICOS(state, servicos) {
      state.servicos = servicos
    },
    UPDATE_SERVICOS_OPTIONS(state, servicosOptions) {
      state.servicosOptions = servicosOptions
    },
  },
  actions: {
    async findAll({ commit }) {
      const servicos = await ServicoService.findAll()
      commit('UPDATE_SERVICOS', servicos)
      return servicos
    },
    setOptions({ getters, commit }) {
      const options = getters.servicos.map(servico => ({
        label: servico.nomeServico,
        text: servico.nomeServico,
        value: servico.id,
        objValue: { servicoId: servico.id },
      }))
      commit('UPDATE_SERVICOS_OPTIONS', options)
    },
  },
}