import HttpClient from '@/service/http-clients/portaljad'

const baseUrl = 'fatura-cliente'

export default class PerfilService {
  static async findAll() {
    try {
      const { data } = await HttpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async delete(id) {
    try {
      await HttpClient.delete([baseUrl, id].join('/'))
    } catch (error) {
      throw error.response.dada
    }
  }

  static async save(multipartForm) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'arquivos'].join('/'), multipartForm)
      return data
    } catch (error) {
      throw error.response.data
    }
  }
}