<template>
  <div>
    <add-regiao-sidebar
      :active.sync="isAddRegioesSidebarActive"
      @aoSalvar="atualizaLista"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>importações por página</label>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-model="mostrarInativos"
            >
              Mostrar Regiões Inativas
            </b-form-checkbox>
          </b-col>
          <b-col 
            cols="12"
            md="3"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="isAddRegioesSidebarActive = true"
            >
              <span class="text-nowrap">Importar Regiões</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="tableRef"
        class="position-relative"
        :items="regioes"
        :fields="tableColumns"
        :filter="search"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="regiaoImportacaoId"
        :sort-by.sync="sortBy"
        show-empty
        stacked="md"
        empty-text="Nenhuma região cadastrada"
        :sort-desc.sync="isSortDirDesc"
        empty-filtered-text="Nenhum resultado encontrado com os filtros informados."
        @filtered="onFiltered"
      >
        <template #cell(dataVigencia)="data">
          <span>{{ maskDate(data.item.dataVigencia) }}</span>
        </template>
        <template #cell(dataImportacao)="data">
          <span>{{ maskDate(data.item.dataImportacao) }}</span>
        </template>
        <template #cell(ativo)="data">
          <b-badge
            v-if="data.item.ativo"
            variant="success"
          >
            SIM
          </b-badge>
          <b-badge
            v-if="!data.item.ativo"
            variant="light-primary"
          >
            NÃO
          </b-badge>
        </template>
        <template #cell(ações)="data">
          <b-button
            variant="link"
            @click="openModal(data)"
          >
            <feather-icon
              icon="EyeIcon"
              size="18"
            />
          </b-button>
        </template>
      </b-table>
      <div class="m-2">
        <b-row v-if="totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="modal"
      title="Detalhes"
      ok-only
      ok-title="OK"
      size="xl"
      style="max-height: 1000px"
    >
      <details-card :data="modalData"/>

    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/helpers/utils'
import { BBadge } from 'bootstrap-vue'
import { reactive, ref, toRefs } from '@vue/composition-api'
import RegioesComposition from '../regioes.composition'
import AddRegiaoSidebar from '../components/add-regiao-sidebar'
import DetailsCard from '../components/datails.vue'

export default {
  name: 'IndexVue',
  components: { AddRegiaoSidebar, BBadge, DetailsCard },
  computed: {
    ...mapGetters({
      firebaseUser: 'firebase/firebaseUser',
    }),
  },
  watch: {
    firebaseUser() {
      this.$store.dispatch('regioes/findAll')
    },
  },
  beforeMount() {
    this.$store.dispatch('regioes/findAll')
    this.$store.dispatch('clientes/findAll')
  },
  setup() {
    const modal = ref(null)
    const state = reactive({
      modalData: {},
    })

    const openModal = data => {
      state.modalData = data.item
      modal.value.show()
    }

    return {
      openModal,
      modal,
      ...toRefs(state),
      ...RegioesComposition(),
      ...utils,
    }
  },
  methods: {
    atualizaLista() {
      this.refresh()
    },
  },
}
</script>

<style scoped>

</style>