<template>
  <div>

    <add-perfil-sidebar
      :active.sync="perfilAddSidebarActive"
      @refresh="refresh"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>perfis por página</label>
          </b-col>
          <b-col 
            cols="12"
            md="3"
            class="d-flex justify-content-end"
          >
            <b-button
              v-if="permissoes.editar"
              variant="primary"
              @click="perfilAddSidebarActive = true"
            >
              <span class="text-nowrap">Adicionar Perfil</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="tableRef"
        class="position-relative"
        :items="perfis"
        :fields="tableColumns"
        :filter="search"
        primary-key="perfilId"
        :sort-by.sync="sortBy"
        show-empty
        stacked="md"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page="currentPage"
        empty-filtered-text="Nenhum resultado encontrado com os filtros informados."
        @filtered="onFiltered"
      >
        <template 
          v-if="permissoes.editar || permissoes.visualizar || permissoes.excluir"
          #cell(ações)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item 
              v-if="permissoes.visualizar"
              :to="{ name: 'perfis-visualizar', params: { perfilId: data.item.perfilId} }"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">Detalhes</span>
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="permissoes.editar"
              :to="{ name: 'perfis-editar', params: { perfilId: data.item.perfilId} }"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template
          v-else
          #cell(ações)=""
          class="d-flex justify-content-end"
        >
          <span title="Nenhuma ação permitida">
            <feather-icon icon="SlashIcon"/>
          </span>
        </template>
      </b-table>
      <div class="m-2">
        <b-row v-if="totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import PerfisComposition from '../perfis.composition'
import AddPerfilSidebar from '../components/add-perfil-sidebar'

export default {
  name: 'IndexVue',
  components: { AddPerfilSidebar },
  beforeMount() {
    const { empresaId } = JSON.parse(localStorage.getItem('userName'))
    this.$store.dispatch('perfis/findAll', empresaId)
  },
  setup() {
    const permissoes = JSON.parse(localStorage.getItem('permissoes')).filter(p => p.permissaoId === 3)[0]
    return { 
      ...PerfisComposition(),
      permissoes,
    }
  },
}
</script>

<style scoped>
.asd-asdfasdf {
  display: flex;
  justify-content: end;
}
</style>