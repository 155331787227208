<template lang="">
  <div>
    <vue-apex-charts
      type="line"
      height="430px"
      width="99%"
      :options="options"
      :series="series"
    />
  </div>
</template>
<script>

import VueApexCharts from 'vue-apexcharts'
import { reactive, toRefs, watch } from '@vue/composition-api'

export default {
  name: 'GraficoTracking',
  components: { 
    VueApexCharts,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const chartOptions = {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      colors: ['#0011ff'],
      stroke: {
        curve: 'smooth',
        width: 1,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 5,
      },
      annotations: {
        yaxis: [
          {
            y: -2,
            y2: 0,
            yAxisIndex: 0,
            opacity: 0.3,
            fillColor: '#FF999D',
          },
          {
            y: 0,
            y2: 1,
            yAxisIndex: 0,
            opacity: 0.3,
            fillColor: '#DFFFE8',
          },
          {
            y: 1,
            y2: 2,
            yAxisIndex: 0,
            opacity: 0.3,
            fillColor: '#FFFFD2',
          },
          {
            y: 2,
            y2: 4,
            yAxisIndex: 0,
            opacity: 0.3,
            fillColor: '#FFEBD0',
          },
          {
            y: 4,
            y2: 8,
            yAxisIndex: 0,
            opacity: 0.3,
            fillColor: '#FFEBE7',
          },
          {
            y: 8,
            y2: 15,
            yAxisIndex: 0,
            opacity: 0.3,
            fillColor: '#FFD6CE',
          },
        ],
      },
      fill: {
        opacity: 1,
        type: 'solid',
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        min: -1,
        max: 15,
        tickAmount: 16,
        title: {
          align: 'right',
          offsetX: 5,
          text: 'Tempo de Resposta',
        },
        labels: {
          formatter: value => {
            if (value === 0 || value === -1) {
              return ''
            } return `${value}s`
          },
        },
      },
    }
    const state = reactive({
      options: {
        ...chartOptions,
        ...{
          xaxis: {
            categories: props.categories,
          },
        },
      },
    })

    watch(() => props.categories, value => {
      state.options = {
        ...state.options,
        ...{
          xaxis: {
            categories: value,
          },
        },
      }
    })
    return {
      ...toRefs(state),
    }
  },
}
</script>
<style lang="scss">
  tspan {
    font-size: 10px ;
  }
</style>