import PerfilService from '@/service/PerfilService'

export default {
  namespaced: true,
  state: {
    perfil: null,
    perfis: null,
    perfilOptions: [],
  },
  getters: {
    perfil(state) {
      return state.perfil
    },
    perfis(state) {
      return state.perfis
    },
    perfilOptions(state) {
      return state.perfilOptions
    },
  },
  mutations: {
    UPDATE_PERFIL(state, perfil) {
      state.perfil = perfil
    },
    UPDATE_PERFIS(state, perfis) {
      state.perfis = perfis
    },
    SAVE_PERFIL(state, perfil) {
      state.perfis.push(perfil)
    },
    EDIT_PERFIL(state, perfil) {
      if (state.perfis) {
        const index = state.perfis.findIndex(item => item.perfilId === perfil.perfilId)
        Object.assign(state.perfis[index], perfil)
      } else {
        state.perfil = perfil
      }
    },
    DELETE_PERFIL(state, perfilId) {
      state.perfis = state.perfis.filter(perfil => perfil.perfilId !== perfilId)
    },
    UPDATE_PERFIL_OPTIONS(state, perfilOptions) {
      state.perfilOptions = perfilOptions
    },
  },
  actions: {
    async findById({ commit }, perfilId) {
      const perfil = await PerfilService.findById(perfilId)
      commit('UPDATE_PERFIL', perfil)
      return perfil
    },
    async findAll({ commit }, empresaId) {
      const perfis = await PerfilService.findAll(empresaId)
      commit('UPDATE_PERFIS', perfis)
      return perfis
    },
    async findAllComPermissao({ commit }, empresaId) {
      const perfis = await PerfilService.findAllComPermissao(empresaId)
      commit('UPDATE_PERFIS', perfis)
      return perfis
    },
    async save({ commit }, perfil) {
      const perfilId = await PerfilService.save(perfil)
      const perfilToSave = { ...perfil }
      perfilToSave.perfilId = perfilId
      commit('SAVE_PERFIL', perfil)
    },
    async edit({ commit }, { perfilId, perfil }) {
      const edited = await PerfilService.edit(perfilId, perfil)
      commit('EDIT_PERFIL', edited)
    },
    async delete({ commit }, perfilId) {
      const deleted = await PerfilService.delete(perfilId)
      commit('DELETE_PERFIL', deleted)
    },
    setOptions({ commit, getters }) {
      const options = getters.perfis.map(perfil => ({
        value: perfil.perfilId,
        label: perfil.nome,
      }))
      commit('UPDATE_PERFIL_OPTIONS', options)
    },
  },
}