import HttpClient from '@/service/http-clients/portaljad'

const baseUrl = 'permissoes'

export default class PermissaoService {
  static async findById(permissaoId) {
    try {
      const { data } = await HttpClient.get([baseUrl, permissaoId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAll() {
    try {
      const { data } = await HttpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async save(permissao) {
    try {
      const { data } = await HttpClient.post(baseUrl, permissao)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async edit(permissaoId, permissao) {
    try {
      const { data } = await HttpClient.put([baseUrl, permissaoId].join('/'), permissao)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}