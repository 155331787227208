import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default () => {
  const taxasStore = createNamespacedHelpers('taxas')
  const tableColumns = [
    { key: 'id', sortable: true, label: 'TAXA ID' },
    { key: 'tipoTaxa', sortable: true, label: 'TIPO DA TAXA' },
    { key: 'nomeTaxa', sortable: true, label: 'NOME' },
    { key: 'dataCriacao', sortable: true, label: 'DATA CRIAÇÃO' },
    { key: 'ativo', sortable: true, label: 'ATIVO' },
    { key: 'ações', sortable: false },
  ]

  const state = reactive({
    taxas: taxasStore.useGetters(['taxas']).taxas,
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    perPageOptions: [10, 25, 50, 100],
  })

  watch(() => state.taxas, value => {
    state.currentPage = 1
    if (value && value.length) {
      state.totalRows = value.length
    } else {
      state.totalRows = 0
    }
  })

  const search = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const addTaxaSidebarActive = ref(false)

  const onFiltered = filteredItems => {
    state.currentPage = 1
    state.totalRows = filteredItems.length
  }

  return {
    ...toRefs(state),
    search,
    sortBy,
    isSortDirDesc,
    tableColumns,
    addTaxaSidebarActive,
    onFiltered,
  }
}