<template>
  <div>
    <template v-if="perfil">
      <b-card>
        <b-row>

          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <div class="d-flex justify-content-start">
              <b-avatar
                ref="previewEl"
                :text="avatarText(perfil.nome)"
                :variant="`light-success`"
                size="90px"
                rounded
              />
              <div class="d-flex flex-column ml-1 justify-content-center">
                <h4 class="mb-1">
                  {{ perfil.nome }}
                </h4>
              </div>
            </div>
          </b-col>
        </b-row>
        <validation-observer
          #default="{handleSubmit}"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row class="my-2">
              <b-col cols="12">
                <validation-provider
                  #default="validationContext"
                  name="Nome"
                  rules="required"
                >
                  <b-form-group
                    label="Nome"
                    label-for="nome"
                  >
                    <b-form-input
                      id="nome"
                      v-model="perfil.nome"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="João da Silva"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-card
              no-body
              class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <feather-icon
                    icon="LockIcon"
                    size="18"
                  />
                  <span class="align-middle ml-50">Permissões do perfil</span>
                </b-card-title>
              </b-card-header>
              <b-table
                key="permissaoId"
                striped
                responsive
                class="mb-0"
                :items="perfil.permissoes"
                :fields="tableColumns"
                show-empty
                empty-text="Nenhuma permissão cadastrada"
              >
                <template #cell(visualizar)="data">
                  <b-form-checkbox
                    v-model="data.item.visualizar"
                  />
                </template>
                <template #cell(editar)="data">
                  <b-form-checkbox
                    v-model="data.item.editar"
                  />
                </template>
                <template #cell(excluir)="data">
                  <b-form-checkbox
                    v-model="data.item.excluir"
                  />
                </template>
              </b-table>
            </b-card>
          </b-form>
        </validation-observer>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="salvarPerfil()"
        >
          Salvar Mudanças
          <feather-icon
            icon="SaveIcon"
            class="ml-1"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          class="mr-sm-1 mr-0 mb-1 mb-sm-0"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="resetForm()"
        >
          Reset
          <feather-icon
            icon="RefreshCwIcon"
            class="ml-1"
          />
        </b-button>
        <b-button
          v-if="permissoes.excluir"
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="deletePerfil(perfil)"
        >
          Deletar
          <feather-icon
            icon="TrashIcon"
            class="ml-1"
          />
        </b-button>
      </b-card>
    </template>
  </div>
</template>

<script>
import { watch, reactive, toRefs } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'

const appLoading = $themeConfig.app.appLoadingImage

export default {
  name: 'IndexVue',
  beforeMount() {
    this.$store.dispatch('perfis/findById', this.$router.currentRoute.params.perfilId)
    this.$store.dispatch('permissoes/findAll')
  },
  methods: {
    deletePerfil(perfil) {
      this.$swal({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Você tem certeza que quer deletar esse perfil?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não, calma aí.',
        confirmButtonText: 'Sim, eu tenho!',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          appLoading.show()
          this.$store.dispatch('perfis/delete', perfil.perfilId).then(() => {
            appLoading.hide()
            this.$swal({
              icon: 'success',
              html:
                '<h1>Deletado com sucesso!</h1><br/><span style="font-size: 8px">Espero que você saiba o que está fazendo...</span>',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }).then(() => {
            this.$router.push('/cadastro/perfis')
          })
        }
      })
    },
    salvarPerfil() {
      this.$swal({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Deseja salvar as alterações?',
        showCancelButton: true,
        confirmButtonText: 'Sim, eu desejo',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('perfis/edit', {
            perfilId: this.perfil.perfilId,
            perfil: this.perfil,
          }).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Parabéns!',
              text: 'Você alterou os dados do perfil!',
              showConfirmButton: false,
              timer: 2000,
            })
          }).then(() => {
            this.$router.push('/cadastro/perfis')
          })
        }
      })
    },
  },
  setup() {
    const perfisStore = createNamespacedHelpers('perfis')
    const permissoesStore = createNamespacedHelpers('permissoes')
    const { findById } = perfisStore.useActions(['findById'])
    const { findAll } = permissoesStore.useActions(['findAll'])
    const state = reactive({
      perfil: perfisStore.useGetters(['perfil']).perfil,
      permissoes: permissoesStore.useGetters(['permissoes']).permissoes,
      perfilPermissoes: [],
    })

    watch(() => state.perfil, perfil => {
      findAll().then(value => {
        const permissoesDoPerfil = perfil.permissoes
        state.perfil.permissoes = []
        value.forEach(permissao => {
          const permissaoDoPerfil = permissoesDoPerfil.find(p => p.permissaoId === permissao.permissaoId)
          state.perfil.permissoes.push({
            perfilPermissaoId: permissaoDoPerfil ? permissaoDoPerfil.perfilPermissaoId : null,
            perfilId: permissaoDoPerfil ? permissaoDoPerfil.perfilId : perfil.perfilId,
            descricao: permissao.descricao,
            permissaoId: permissaoDoPerfil ? permissaoDoPerfil.permissaoId : permissao.permissaoId,
            visualizar: permissaoDoPerfil ? permissaoDoPerfil.visualizar : permissao.visivel,
            editar: permissaoDoPerfil ? permissaoDoPerfil.editar : permissao.editavel,
            excluir: permissaoDoPerfil ? permissaoDoPerfil.excluir : permissao.excluivel,
          })
        })
      })
    })

    const resetPerfil = () => {
      findById(state.perfil.perfilId)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPerfil)

    const tableColumns = [
      { key: 'descricao', sortable: true },
      { key: 'visualizar', sortable: false },
      { key: 'editar', sortable: false },
      { key: 'excluir', sortable: false },
    ]

    const permissoes = JSON.parse(localStorage.getItem('permissoes')).filter(p => p.permissaoId === 3)[0]

    return {
      ...toRefs(state),
      // FORM CONFIG
      refFormObserver,
      getValidationState,
      resetForm,
      // MISC
      avatarText,
      tableColumns,
      permissoes,
    }
  },
}
</script>

<style scoped>

</style>