export default class DepartamentoAdapter {
  static toApi(departamento) {
    return {
      ...departamento,
      numeroDocumento: departamento.numeroDocumento.replaceAll(/[.,()/\s-]+/g, ''),
      cep: departamento.cep.replaceAll(/[.,()/\s-]+/g, ''),
      ativo: true,
    }
  }

  static toStore(departamento) {
    return {
      ...departamento,
      selecionado: false,
    }
  }
}