<template>
  <div>
    <template v-if="departamento">
      <b-card class="p-2">
        <h5>DETALHES DO DEPARTAMENTO</h5>
        <b-row class="mt-3">
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <div class="d-flex justify-content-start">
              <b-avatar
                ref="previewEl"
                :text="avatarText(departamento.nomeDepartamento)"
                :src="departamento.urlLogo"
                :variant="`light-success`"
                size="90px"
                rounded
              />
              <div class="d-flex flex-column ml-1">
                <h4 class="mb-1">
                  {{ departamento.nomeDepartamento }}
                </h4>
                <div class="d-flex flex-wrap">
                  <b-button
                    v-if="permissoes.editar"
                    variant="primary"
                    :to="{ name: 'departamentos-editar', params: { ...departamento.clienteDepartamentoId } }"
                  >
                    Editar
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            xl="6"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Nome</span>
                </th>
                <td class="pb-50">
                  {{ departamento.nomeDepartamento }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">CPF/CNPJ</span>
                </th>
                <td class="pb-50">
                  {{ departamento.tipoDocumento == 'CPF' ? maskCpf(departamento.numeroDocumento) : maskCnpj(departamento.numeroDocumento) }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="CpuIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Código referência</span>
                </th>
                <td class="pb-50">
                  {{ departamento.codigoReferencia }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Permissão de acesso</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ departamento.todosUsuariosAcessam ? 'Sim' : 'Não' }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="MapPinIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Endereço diferente</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ departamento.temEndereco ? 'Sim' : 'Não' }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="ChromeIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Logo</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ departamento.urlLogo ? 'Sim' : 'Não' }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
        <hr>
        <div v-if="departamento.temEndereco">
          <b-row class="mt-3">
            <b-col
              cols="12"
              xl="2"
              class="d-flex justify-content-between flex-column"
            >
              <span class="font-weight-bold">Nome Endereço</span>
            </b-col>
            <b-col
              cols="12"
              xl="3"
              class="d-flex justify-content-between flex-column"
            >
              <span>{{ departamento.nomeEndereco }}</span>
            </b-col>
            <b-col
              cols="12"
              xl="2"
              class="d-flex justify-content-between flex-column"
            >
              <span class="font-weight-bold">Número</span>
            </b-col>
            <b-col
              cols="12"
              xl="5"
              class="d-flex justify-content-between flex-column"
            >
              <span>{{ departamento.numero }}</span>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col
              cols="12"
              xl="2"
              class="d-flex justify-content-between flex-column"
            >
              <span class="font-weight-bold">CEP</span>
            </b-col>
            <b-col
              cols="12"
              xl="3"
              class="d-flex justify-content-between flex-column"
            >
              <span>{{ maskCep(departamento.cep) }}</span>
            </b-col>
            <b-col
              cols="12"
              xl="2"
              class="d-flex justify-content-between flex-column"
            >
              <span class="font-weight-bold">Complemento</span>
            </b-col>
            <b-col
              cols="12"
              xl="5"
              class="d-flex justify-content-between flex-column"
            >
              <span>{{ departamento.complemento }}</span>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col
              cols="12"
              xl="2"
              class="d-flex justify-content-between flex-column"
            >
              <span class="font-weight-bold">Cidade</span>
            </b-col>
            <b-col
              cols="12"
              xl="3"
              class="d-flex justify-content-between flex-column"
            >
              <span>{{ departamento.cidade }}</span>
            </b-col>
            <b-col
              cols="12"
              xl="2"
              class="d-flex justify-content-between flex-column"
            >
              <span class="font-weight-bold">UF</span>
            </b-col>
            <b-col
              cols="12"
              xl="5"
              class="d-flex justify-content-between flex-column"
            >
              <span>{{ departamento.uf }}</span>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col
              cols="12"
              xl="2"
              class="d-flex justify-content-between flex-column"
            >
              <span class="font-weight-bold">Logradouro</span>
            </b-col>
            <b-col
              cols="12"
              xl="3"
              class="d-flex justify-content-between flex-column"
            >
              <span>{{ departamento.logradouro }}</span>
            </b-col>
            <b-col
              cols="12"
              xl="2"
              class="d-flex justify-content-between flex-column"
            >
              <span class="font-weight-bold">Bairro</span>
            </b-col>
            <b-col
              cols="12"
              xl="5"
              class="d-flex justify-content-between flex-column"
            >
              <span>{{ departamento.bairro }}</span>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <span>Nenhum endereço cadastrado</span>
        </div>
      </b-card>
      <div
        class="mb-4 mr-0 d-flex align-items-center justify-content-end"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <b-button
          variant="primary"
          class="px-3"
          @click="$router.go(-1)"
        >
          Voltar
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { reactive, toRefs } from '@vue/composition-api'
import utils from '@/helpers/utils'

const departamentosStore = createNamespacedHelpers('departamentos')

export default {
  name: 'VisualizarDepartamento',
  beforeMount() {
    this.$store.dispatch('departamentos/findById', this.$route.params.departamentoId)
  },
  setup() {
    const state = reactive({
      departamento: departamentosStore.useGetters(['selected']).selected,
    })
    const permissoes = JSON.parse(localStorage.getItem('permissoes')).filter(p => p.permissaoId === 1)[0]
    return { avatarText, ...toRefs(state), ...utils, permissoes }
  },
}
</script>

<style scoped>

</style>
