<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Razão Social"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Razão Social"
              label-for="razaoSocial"
            >
              <b-form-input
                id="razao-social"
                v-model="cliente.razaoSocial"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Razão Social"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Nome Fantasia"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Nome Fantasia"
              label-for="nomeFantasia"
            >
              <b-form-input
                id="nomeFantasia"
                v-model="cliente.nomeFantasia"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nome Fantasia"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Email"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="cliente.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="cliente@dominio.com"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Telefone"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Telefone"
              label-for="telefone"
            >
              <b-form-input
                id="telefone"
                v-model="cliente.telefone"
                v-cleave="cleaveOptions.phone"
                :state="getValidationState(validationContext)"
                trim
                placeholder="(00) 00000-0000"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Tipo de Documento"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Tipo de Documento"
              label-for="tipoDocumento"
            >
              <v-select
                v-model="cliente.tipoDocumento"
                :reduce="val=>val.value"
                :options="tipoDocumentoOptions"
                :clearable="false"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Documento"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Documento"
              label-for="numeroDocumento"
            >
              <b-form-input
                id="numeroDocumento"
                :key="cleaveKey"
                v-model="cliente.numeroDocumento"
                v-cleave="cliente.tipoDocumento ? cleaveOptions[cliente.tipoDocumento.toLowerCase()] : 
                  cleaveOptions.cpf"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Documento"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Inscrição Estadual"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Inscrição Estadual"
              label-for="inscricaoEstadual"
            >
              <b-form-input
                id="inscricaoEstadual"
                v-model="cliente.inscricaoEstadual"
                :state="getValidationState(validationContext)"
                trim
                placeholder="000000000"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="logo"
            rules="size:100"
          >
            <b-form-group
              label="Logo do cliente"
              label-for="logo"
            >
              <b-form-file
                id="logo"
                v-model="cliente.logoFile"
                placeholder="Selecione uma imagem"
                :state="getValidationState(validationContext)"
                rop-placeholder="Solte o arquivo aqui"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template #description>
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> A imagem deve possuir tamanho máximo de 100kB
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import utils from '@/helpers/utils'
import ClientesEditarComposition from '../clientes-editar.composition'

export default {
  name: 'TabGeral',
  props: {
    data: {
      required: true,
      type: undefined,
    },
  },
  setup() {
    const tipoDocumentoOptions = [
      { label: 'CPF', value: 'CPF' },
      { label: 'CNPJ', value: 'CNPJ' },
    ]

    return {
      ...ClientesEditarComposition(),
      avatarText,
      // Enums
      tipoDocumentoOptions,
      // Methods
      ...utils,
    }
  },
}
</script>

<style scoped>

</style>
