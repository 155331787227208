import DepartamentoService from '@/service/DepartamentoService'
import adapter from '@/adapters/DepartamentoAdapter'

export default {
  namespaced: true,
  state: {
    departamentos: undefined,
    selected: undefined,
    departamentosOptions: [],
  },
  getters: {
    departamentos(state) {
      return state.departamentos
    },
    selected(state) {
      return state.selected
    },
    departamentosOptions(state) {
      return state.departamentosOptions
    },
  },
  mutations: {
    UPDATE_DEPARTAMENTOS(state, departamentos) {
      state.departamentos = departamentos
    },
    UPDATE_SELECTED(state, departamento) {
      state.selected = departamento
    },
    SAVE_DEPARTAMENTO(state, departamento) {
      state.departamentos.push(departamento)
    },
    UPDATE_DEPARTAMENTOS_OPTIONS(state, departamentosOptions) {
      state.departamentosOptions = departamentosOptions
    },
    EDIT_DEPARTAMENTO(state, departamento) {
      if (state.departamentos) {
        const index = state.departamentos.findIndex(cli => cli.clienteDepartamentoId === departamento.clienteDepartamentoId)
        Object.assign(state.departamentos[index], departamento)
      } else {
        state.selected = departamento
      }
    },
    DELETE_DEPARTAMENTO(state, clienteDepartamentoId) {
      if (state.departamentos) {
        const index = state.departamentos.findIndex(cli => cli.clienteDepartamentoId === clienteDepartamentoId)
        state.departamentos.splice(index, 1)
      }
    },
  },
  actions: {
    async findAll({ commit }) {
      const departamentos = await DepartamentoService.findAll()
      commit('UPDATE_DEPARTAMENTOS', departamentos.map(departamento => adapter.toStore(departamento)))
      return departamentos
    },
    async findAllByClienteId({ commit }, clienteId) {
      const departamentos = await DepartamentoService.findAllByClienteId(clienteId)
      commit('UPDATE_DEPARTAMENTOS', departamentos.map(departamento => adapter.toStore(departamento)))
      return departamentos
    },
    async findById({ commit }, clienteDepartamentoId) {
      const departamento = await DepartamentoService.findById(clienteDepartamentoId)
      commit('UPDATE_SELECTED', adapter.toStore(departamento))
    },
    async save(_, multipart) {
      const data = await DepartamentoService.save(multipart)
      return data
    },
    async edit({ commit }, { departamentoId, multipart }) {
      const edited = await DepartamentoService.edit(departamentoId, multipart)
      commit('EDIT_DEPARTAMENTO', edited)
    },
    async delete({ commit }, clienteDepartamentoId) {
      const data = await DepartamentoService.delete(clienteDepartamentoId)
      if (data.success) {
        commit('DELETE_DEPARTAMENTO', clienteDepartamentoId)
      }
      return data
    },
    async setSelected({ commit }, selected) {
      commit('UPDATE_SELECTED', selected)
    },
    setOptions({ commit }, departamentos) {
      const options = departamentos.map(departamento => ({
        label: departamento.nomeDepartamento,
        value: departamento.clienteDepartamentoId,
      }))
      commit('UPDATE_DEPARTAMENTOS_OPTIONS', options)
    },
  },
}
