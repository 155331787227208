import ClienteEmailConfig from '@/service/ClienteEmailConfig'

export default {
  namespaced: true,
  state: {
    clienteEmail: {},
  },
  getters: {
    getClienteEmail(state) {
      return state.clienteEmail
    },
  },
  mutations: {
    UPDATE_CLIENTE_EMAIL(state, clienteEmail) {
      state.clienteEmail = clienteEmail
    },
  },
  actions: {
    async findByClienteId({ commit }, clienteId) {
      const clienteEmail = await ClienteEmailConfig.findByClienteId(clienteId)
      commit('UPDATE_CLIENTE_EMAIL', clienteEmail)
    },
  },
}