import ColaboradoresService from '@/service/ColaboradoresService'
import adapter from '@/adapters/ColaboradorAdapter'

export default {
  namespaced: true,
  state: {
    colaboradores: null,
    selected: null,
  },
  getters: {
    colaboradores(state) {
      return state.colaboradores
    },
    selected(state) {
      return state.selected
    },
  },
  mutations: {
    UPDATE_COLABORADORES(state, colaboradores) {
      state.colaboradores = colaboradores
    },
    UPDATE_SELECTED(state, colaborador) {
      state.selected = colaborador
    },
    SAVE_COLABORADOR(state, colaborador) {
      state.colaboradores.push(colaborador)
    },
    EDIT_COLABORADOR(state, colaborador) {
      if (state.colaboradores) {
        const index = state.colaboradores.findIndex(item => item.usuarioId === colaborador.usuarioId)
        Object.assign(state.colaboradores[index], colaborador)
      } else {
        state.selected = colaborador
      }
    },
    DELETE_COLABORADOR(state, usuarioId) {
      if (state.colaboradores) {
        const index = state.colaboradores.findIndex(item => item.usuarioId === usuarioId)
        state.colaboradores.splice(index, 1)
      }
    },
  },
  actions: {
    async findAll({ commit }, empresaId) {
      const colaboradores = await ColaboradoresService.findAll(empresaId)
      commit('UPDATE_COLABORADORES', colaboradores)
      return colaboradores
    },
    async findAllByCliente({ commit }, clienteId) {
      const colaboradores = await ColaboradoresService.findAllByCliente(clienteId)
      commit('UPDATE_COLABORADORES', colaboradores)
      return colaboradores
    },
    async findById({ commit }, usuarioId) {
      const colaborador = await ColaboradoresService.findById(usuarioId)
      commit('UPDATE_SELECTED', colaborador)
      return colaborador
    },
    async save({ commit }, colaborador) {
      const colaboradorToSave = adapter.toApi(colaborador)
      const usuarioId = await ColaboradoresService.save(colaboradorToSave)
      colaboradorToSave.usuarioId = usuarioId
      commit('SAVE_COLABORADOR', colaboradorToSave)
    },
    async edit({ commit }, { usuarioId, colaborador }) {
      const edited = await ColaboradoresService.edit(usuarioId, adapter.toApi(colaborador))
      commit('EDIT_COLABORADOR', edited)
    },
    async delete({ commit }, usuarioId) {
      await ColaboradoresService.deleteById(usuarioId)
      commit('DELETE_COLABORADOR', usuarioId)
    },
  },
}