<template #default="{ onSubmit }">
  <div>
    <button
      class="button-b"
      :class="[classChangeByTime(tempo), selected ? 'selected-b' : '']"
      type="submit"
      @click="onSubmit"
    >
      <div class="icon d-flex justify-content-center align-items-center">
        <feather-icon
          :icon="alertIconChange(tempo)"
          size="40"
        />
      </div>
      <div 
        class="text d-flex flex-column align-items-end pt-2 pr-2"
      >
        <h3>{{ nome }}</h3>
        <label>{{ data }}</label>
        <label>{{ tempo === -1 ? "Api Fora" : tempo + "s" }}</label>
      </div>
    </button>
  </div>
</template>
<script>

export default {
  name: 'ButtonTracking',
  props: {
    nome: {
      type: String,
      required: true,
    },
    tempo: {
      type: Number,
      required: true,
    },
    data: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['onClickButton'],
  setup(props, { emit }) {
    const alertIconChange = requestDlay => {
      if (requestDlay >= 1) {
        return 'AlertCircleIcon'
      } if (requestDlay > 0 && requestDlay < 1) {
        return 'CheckCircleIcon'
      } return 'XCircleIcon'
    }

    const classChangeByTime = requestDlay => {
      if (requestDlay >= 1) {
        return 'alert-monitor'
      } if (requestDlay > 0 && requestDlay < 1) {
        return 'ok-monitor'
      } return 'error-monitor'
    }
    
    const onSubmit = () => {
      emit('onClickButton')
    }

    return {
      onSubmit,
      alertIconChange,
      classChangeByTime,
    }
  },
}
</script>
<style lang="scss">
.button-b {
  display: flex;
  width: 250px;
  height: 100px;
  border: 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  .icon {
    width: 30%;
    height: 100%;
  }

  .text {
    width: 70%;
  }

  &.alert-monitor {
    background-color: #fafac1;
    color: #b3b301;
  }
  
  &.error-monitor {
    background-color: #FF999C;
    color: #962132;
  }

  &.ok-monitor {
    background-color: #dfffe8;
    color: #317642;
  }
}
.selected-b {
  box-shadow: inset 2px 2px 7px 1px !important;
}
</style>