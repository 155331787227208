import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import onAuthStateChanged from '@/libs/firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyAhXK8cUdakXkuk1v_6zy37NWJdCtaIqhA',
  authDomain: 'portal-jadlog.firebaseapp.com',
  projectId: 'portal-jadlog',
  storageBucket: 'portal-jadlog.appspot.com',
  messagingSenderId: '915583125161',
  appId: '1:915583125161:web:9d71f960c1d4046d278bb8',
  measurementId: 'G-W65NER3Z62',
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.auth().onAuthStateChanged(onAuthStateChanged)