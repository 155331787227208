<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="d-flex justify-content-between flex-column"
        >
          <div class="d-flex justify-content-start">
            <b-avatar
              ref="previewEl"
              :text="avatarText(tarifa.nomeTarifa)"
              :variant="`light-success`"
              size="90px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <h4 class="mb-1">
                {{ tarifa.nomeTarifa }}
              </h4>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="outline-danger"
                  @click="deleteTarifa(tarifa)"
                >
                  Deletar
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th
                class="pb-50"
                style="width:30%"
              >
                <feather-icon
                  icon="ArrowUpIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Arquivo Importado</span>
              </th>
              <td class="pb-50">
                <a
                  :href="`${tarifa.urlArquivoImportado}`"
                  target="_blank"
                >
                  {{ tarifa.nomeArquivoImportado }}
                </a>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Data de Importacao</span>
              </th>
              <td class="pb-50">
                {{ tarifa.dataImportacao }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Data de Vigência</span>
              </th>
              <td class="pb-50">
                {{ tarifa.dataVigencia }}
              </td>
            </tr>
            <tr v-if="tarifa.observacao">
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Observação</span>
              </th>
              <td class="pb-50">
                {{ tarifa.observacao }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">Valores por região e peso</span>
          </b-card-title>
          <b-col cols="8">
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text>
                    <feather-icon
                      icon="FilterIcon"
                      size="18"
                    />
                  </b-input-group-text>
                </template>
                <b-form-input
                  v-model="search"
                  class="d-inline-block mr-1"
                  placeholder="Buscar..."
                />
              </b-input-group>
            </div>
          </b-col>
        </b-card-header>
        <b-table
          :items="tarifaValores"
          :fields="tableColumns"
          :filter="search"
          responsive
          striped
          sort-by="regiao"
        >
          <template #head(regiao)="{}">
            <div class="text-nowrap">
              Região
            </div>
          </template>
        </b-table>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import { computed, reactive, toRefs } from '@vue/composition-api'
import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default {
  name: 'IndexVue',
  beforeMount() {
    this.$store.dispatch('tarifas/findById', this.$route.params.tarifaId)
  },
  methods: {
    deleteTarifa(tarifa) {
      this.$swal({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Você tem certeza que quer deletar essa tarifa?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não, calma aí.',
        confirmButtonText: 'Sim, eu tenho!',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('tarifas/deleteById', tarifa.tarifaId).then(() => {
            this.$router.go(-1)
            this.$swal({
              icon: 'success',
              title: 'Deletada com sucesso!',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    },
  },
  setup() {
    const tarifasStore = createNamespacedHelpers('tarifas')
    const state = reactive({
      tarifa: tarifasStore.useGetters(['selected']).selected,
      tarifaValores: [],
      search: '',
    })
    state.tarifaValores = computed(() => {
      const tarifaValoresPorUfERegiao = _.groupBy(state.tarifa.tarifaValores, item => `${item.ufDestino} - ${item.regiao} `)
      const tarifaValoresPorUfRegiaoPeso = []
      Object.entries(tarifaValoresPorUfERegiao).forEach(([regiao, tarifaValores]) => {
        const tarifaValoresPorPeso = _.keyBy(tarifaValores, 'pesoFinal')
        const valoresPorPeso = {}
        Object.entries(tarifaValoresPorPeso).forEach(([peso, tarifaValor]) => {
          valoresPorPeso[peso] = parseFloat(tarifaValor.valor).toLocaleString('pt-BR', {
            style: 'currency', currency: 'BRL',
          })
          if (tarifaValor.valorKgAdicional > 0) {
            valoresPorPeso.kgAdicional = parseFloat(tarifaValor.valorKgAdicional).toLocaleString('pt-BR', {
              style: 'currency', currency: 'BRL',
            })
          }
        })
        tarifaValoresPorUfRegiaoPeso.push({ regiao, ...valoresPorPeso })
      })
      return tarifaValoresPorUfRegiaoPeso
    })

    const intermediaryColumns = []

    for (let i = 1; i <= 30; i += 1) {
      intermediaryColumns.push(
        {
          key: `${i * 1000}`,
          sortable: false,
          label: `${i}kg`,
          thClass: 'nameOfTheClass',
        },
      )
    }
    const tableColumns = [
      {
        key: 'regiao',
        stickyColumn: true,
        sortable: true,
        label: 'Região',
      },
      {
        key: '250',
        sortable: false,
        label: '250g',
      },
      {
        key: '500',
        sortable: false,
        label: '500g',
      },
      {
        key: '750',
        sortable: false,
        label: '750g',
      },
      ...intermediaryColumns,
      {
        key: 'kgAdicional',
        sortable: false,
        label: 'Valor Kg Adicional',
      },
    ]

    return { avatarText, ...toRefs(state), tableColumns }
  },
}
</script>

<style lang="css" scoped>

</style>