<template>
  <section>
    <detalhes-usuario
      v-if="usuarioSelecionado && pagina === 'detalhesUsuario'"
      :usuario-selecionado="usuarioSelecionado"
      @fechar="() => pagina = 'homeUsuarios'"
      @editarUsuario="() => pagina = 'editarUsuario'"
    />
    <editar-usuario
      v-if="usuarioSelecionado && pagina === 'editarUsuario'"
      :usuario-selecionado="usuarioSelecionado"
      @fechar="() => pagina = 'homeUsuarios'"
    />
    <div v-if="pagina === 'homeUsuarios'">
      <user-add-sidebar
        :active.sync="userAddSidebarActive"
        :cliente-id="idCliente"
        @refresh="refresh"
      />
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mt-2 mb-0"
      >
        <h1 class="m-2 mt-2">
          Lista de Usuários
        </h1>
        <div class="m-2">

          <!-- Table Top -->
          <b-row>
            <!-- Search -->
            <b-col
              md="4"
              cols="12"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="search"
                  class="d-inline-block mr-1"
                  placeholder="Buscar..."
                  debounce="500"
                />
              </div>
            </b-col>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrando</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>usuários por página</label>
            
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="d-flex justify-content-end"
            >
              <b-button
                variant="primary"
                @click="userAddSidebarActive = true"
              >
                <span class="text-nowrap">Adicionar Usuário</span>
              </b-button>
            </b-col>
          </b-row>

        </div>
        <b-table
          id="my-table"
          ref="tableRef"
          class="position-relative"
          :items="users"
          :fields="tableColumns"
          :filter="search"
          empty-text="Nenhum registro encontrado"
          empty-filtered-text="Busca sem resultados"
          primary-key="userId"
          :sort-by.sync="sortBy"
          show-empty
          stacked="md"
          :sort-desc.sync="isSortDirDesc"
          :per-page="perPage"
          :current-page="currentPage"
          @filtered="onFiltered"
        >
          <template #cell(acessoLoginCliente)="data">
            <b-form-checkbox
              v-model="data.item.acessoLoginCliente"
              @change="acessoPrincipal(data.item)"
            />
          </template>
          <template
            #cell(acessar)="data"
          >
            <a
              v-if="data.item.senha && data.item.firebaseUid"
              class="ml-1"
              @click="acessar(data.item)"
            >
              <feather-icon
                icon="LogOutIcon"
                size="18"
                class="text-secondary acessar-icon"
              />
            </a>
          </template>

          <template
            #cell(acoes)="data"
          >
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="mudarPagina(data.item.usuarioId, 'editarUsuario')">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
              
              <b-dropdown-item @click="mudarPagina(data.item.usuarioId, 'detalhesUsuario')">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">Detalhes</span>
              </b-dropdown-item>
              
              <b-dropdown-item @click="deleteUser(data.item.usuarioId)">
                <feather-icon icon="TrashIcon"/>
                <span class="align-middle ml-50">Deletar</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>
        </b-table>
        <div class="m-2">
          <b-row v-if="totalRows > 0">
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Mostrando do 
                {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
                {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                  totalRows
                }} resultados</span>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </section>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import utils from '@/helpers/utils'
import UsersComposition from '@/views/cadastro/users/users.composition'
import DetalhesUsuario from '@/views/cadastro/users/components/detalhesUsuario.vue'
import EditarUsuario from '@/views/cadastro/users/components/editarUsuario.vue'
import { $themeConfig } from '@themeConfig'
import UserAddSidebar from '../../components/add-user-sidebar'

const { appCarregando } = $themeConfig.app

export default {
  name: 'TabUsers',
  components: { UserAddSidebar, DetalhesUsuario, EditarUsuario },
  props: {
    idCliente: {
      type: undefined,
      required: true,
    },
  },
  data() {
    return {
      idUsuarioSelecionado: null,
      usuarioSelecionado: null,
      pagina: 'homeUsuarios',
    }
  },
  watch: {
    pagina() {
      if (this.pagina === 'homeUsuarios') {
        this.idUsuarioSelecionado = null
        this.usuarioSelecionado = null
      }
    },
    idUsuarioSelecionado() {
      if (this.idUsuarioSelecionado) {
        this.$store.dispatch('usuarios/findById', this.idUsuarioSelecionado)
          .then(response => {
            this.usuarioSelecionado = response
          }) 
      }
    },
  },
  methods: {
    mudarPagina(usuarioId, pagina) {
      if (pagina === 'homeUsuarios') { 
        this.idUsuarioSelecionado = null 
      } else {
        this.idUsuarioSelecionado = usuarioId
        this.pagina = pagina
      }
    },
    acessoPrincipal(data) {
      if (data.acessoLoginCliente) {
        this.$swal({
          title: 'Atenção!',
          icon: 'warning',
          html: 'Deseja deixar esse usuário como principal para acesso rápido na tela de cadastro > clientes?',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim',
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.acessoPrincipalUnico(data.usuarioId)
            const dados = {
              empresaId: data.empresaId,
              clienteId: data.clienteId,
              usuarioId: data.usuarioId,
              ativo: true,
            }
            this.$store.dispatch('usuarios/acessoPrincipal', dados)
          } else {
            // eslint-disable-next-line no-param-reassign
            data.acessoLoginCliente = false
          }
        })
      } else {
        this.$swal({
          title: 'Atenção!',
          icon: 'warning',
          html: 'Deseja desativar este usuário como Acesso Rápido??',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim',
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.acessoPrincipalUnico(data.usuarioId)
            const dados = {
              empresaId: data.empresaId,
              clienteId: data.clienteId,
              usuarioId: data.usuarioId,
              ativo: false,
            }
            this.$store.dispatch('usuarios/acessoPrincipal', dados)
          } else {
            // eslint-disable-next-line no-param-reassign
            data.acessoLoginCliente = true
          }
        })
      }
    },
    deleteUser(usuarioId) {
      this.$swal({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Deseja excluir este usuário?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            appCarregando.show()
            this.$store.dispatch('usuarios/delete', usuarioId)
              .then(() => {
                this.$swal({
                  icon: 'success',
                  html:
                  '<h1>Deletado com sucesso!</h1>',
                  showConfirmButton: false,
                  timer: 2500,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })
              })
              .catch(erro => {
                Vue.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Houve um erro, por favor tente novamente. ${erro}`,
                      icon: 'AlertCircleIcon',
                      variant: 'primary',
                    },
                  },
                  {
                    timeout: 5000,
                    draggable: true,
                    hideProgressBar: false,
                  },
                )
              })
              .finally(() => {
                appCarregando.hide()
              })
          }
        })
    },
    acessar(dataItem) {
      const urlCliente = process.env.VUE_APP_ROOT_API_CLIENTE
      const url = encodeURI(`${urlCliente}auth/login/${dataItem.email}/${dataItem.senha.replaceAll('/', '-')}`)
      window.open(url, '_blank')
    },
  },
  setup() {
    return {
      ...UsersComposition(),
      avatarText,
      // Methods
      ...utils,
    }
  },
}
</script>

<style scoped lang="scss">

.acessar-icon {
  stroke: #61AA82;
  stroke-width: 2;
}

</style>