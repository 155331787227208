<template>
  <div>
    <template v-if="colaborador">
      <b-card>
        <b-row>

          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <div class="d-flex justify-content-start">
              <b-avatar
                ref="previewEl"
                :text="avatarText(colaborador.nome)"
                :variant="`light-success`"
                size="90px"
                rounded
              />
              <div class="d-flex flex-column ml-1">
                <h4 class="mb-1">
                  {{ colaborador.nome }}
                </h4>
                <h5 class="mb-3">
                  {{ colaborador.email }}
                </h5>
                <div class="d-flex flex-wrap">
                  <b-button
                    v-if="permissoes.editar"
                    variant="primary"
                    :to="{ name: 'usuarios-editar', params: { ...colaborador.usuarioId } }"
                  >
                    Editar
                  </b-button>
                  <b-button
                    v-if="colaborador.firebaseUid != currentUser.firebaseUid && permissoes.excluir"
                    variant="outline-danger"
                    class="ml-1"
                    @click="deleteUser(colaborador.usuarioId)"
                  >
                    Deletar
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            xl="6"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Nome</span>
                </th>
                <td class="pb-50">
                  {{ colaborador.nome }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Login(Email)</span>
                </th>
                <td class="pb-50">
                  {{ colaborador.email }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">CPF</span>
                </th>
                <td class="pb-50">
                  {{ maskCpf(colaborador.cpf) }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Ativo</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ colaborador.ativo ? 'Sim' : 'Não' }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Perfil</span>
                </th>
                <td class="pb-50">
                  {{ colaborador.perfilId }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { reactive, toRefs } from '@vue/composition-api'
import utils from '@/helpers/utils'

const colaboradoresStore = createNamespacedHelpers('colaboradores')

export default {
  name: 'ColaboradoresVisualizar',
  beforeMount() {
    this.$store.dispatch('colaboradores/findById', this.$route.params.usuarioId)
  },
  setup() {
    const state = reactive({
      colaborador: colaboradoresStore.useGetters(['selected']).selected,
    })
    const currentUser = JSON.parse(localStorage.getItem('userName'))
    const permissoes = JSON.parse(localStorage.getItem('permissoes')).filter(p => p.permissaoId === 2)[0]

    return { avatarText, ...toRefs(state), ...utils, currentUser, permissoes }
  },
}
</script>

<style scoped>
</style>