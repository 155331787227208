import store from '@/store'
import { ref, watch, reactive, toRefs } from '@vue/composition-api'

export default () => {
  const tableColumns = [
    { key: 'nome', sortable: true },
    { key: 'clienteNomeFantasia', sortable: true, label: 'Cliente' },
    { key: 'dataVigencia', sortable: true, label: 'Data de Vigência' },
    { key: 'dataImportacao', sortable: true, label: 'Data de Importação' },
    { key: 'ativo', sortable: false },
    { key: 'ações', sortable: false },
  ]

  const isAddRegioesSidebarActive = ref(false)

  const sortBy = ref('nome')
  const isSortDirDesc = ref(false)
  const search = ref('')
  const regioes = ref([])
  const mostrarInativos = ref(false)

  const state = reactive({
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    perPageOptions: [10, 25, 50, 100],
  })

  watch(() => regioes.value, value => {
    state.currentPage = 1
    if (value && value.length) {
      state.totalRows = value.length
    } else {
      state.totalRows = 0
    }
  })

  const refresh = () => {
    store.dispatch('regioes/findAll').then(resp => {
      if (!mostrarInativos.value) {
        regioes.value = resp.filter(item => item.ativo === true)
      } else {
        regioes.value = resp
      }
    })
  }
  
  watch(mostrarInativos, () => {
    refresh()
  })

  const onFiltered = filteredItems => {
    state.currentPage = 1
    state.totalRows = filteredItems.length
  }
  
  refresh()
  return {
    ...toRefs(state),
    regioes,
    mostrarInativos,
    // TABLE CONFIG
    isSortDirDesc,
    refresh,
    search,
    sortBy,
    tableColumns,
    //
    onFiltered,
    // SIDEBAR
    isAddRegioesSidebarActive,
    //
  }
}