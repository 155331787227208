<template>
  <div>
    <importar-fatura-sidebar
      :active.sync="addFaturaSidebarActive"
      @aoSalvar="atualizaLista"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>faturas por página</label>
          </b-col>
          <b-col 
            cols="12"
            md="3"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="addFaturaSidebarActive = true"
            >
              <span class="text-nowrap">Importar Faturas</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="tableRef"
        class="position-relative"
        :items="faturas"
        :fields="tableColumns"
        :filter="search"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="tarifaId"
        :sort-by.sync="sortBy"
        show-empty
        responsive
        stacked="md"
        empty-text="Nenhuma tarifa cadastrada"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(dataVigencia)="data">
          <span>{{ maskDate(data.item.dataVigencia) }}</span>
        </template>
        <template #cell(dataImportacao)="data">
          <span>{{ data.item.dataImportacao ? maskDateTime(data.item.dataImportacao) : '' }}</span>
        </template>
        <template #cell(dataVencimento)="data">
          <span>{{ data.item.dataVencimento ? maskDateTime(data.item.dataVencimento) : '' }}</span>
        </template>
        <template #cell(dataEmissao)="data">
          <span>{{ data.item.dataEmissao ? maskDateTime(data.item.dataEmissao) : '' }}</span>
        </template>
        <template #cell(valor)="data">
          <span>{{ data.item.valor ? maskCurrency(data.item.valor) : '' }}</span>
        </template>
        <template #cell(urlFatura)="data">
          <a
            class="d-flex justify-content-center"
            :href="data.item.urlFatura"
            download
          ><b-icon-receipt font-scale="1.5"/></a>
        </template>
        <template #cell(urlBoleto)="data">
          <a 
            class="d-flex justify-content-center"
            :href="data.item.urlBoleto"
            download
          >
            <b-icon-sim font-scale="1.5"/>
          </a>
        </template>
        <template #cell(urlZip)="data">
          <div class="d-flex justify-content-center">
            <a 
              v-for="fileZip in data.item.urlZip ? data.item.urlZip.split(';') : ''"
              :key="fileZip"
              :href="fileZip"
              download
              style="padding-right: 3px;"
            > 
              <b-icon-file-zip 
                font-scale="1.5"
              />
            </a>
          </div>

        </template>

        <template #cell(ações)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="deleteFatura(data.item.id)"
            >
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Deletar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="m-2">
        <b-row v-if="totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import store from '@/store'
import { BIconFileZip, BIconReceipt, BIconSim } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import utils from '@/helpers/utils'
import { $themeConfig } from '@themeConfig'
import FaturasComposition from '../faturas.composition'
import ImportarFaturaSidebar from '../components/importar-fatura-sidebar'

export default {
  name: 'IndexVue',
  components: { 
    ImportarFaturaSidebar,
    BIconFileZip,
    BIconReceipt,
    BIconSim,
  },
  computed: {
    ...mapGetters({
      firebaseUser: 'firebase/firebaseUser',
    }),
  },

  watch: {
    firebaseUser() {
      this.atualizaLista()
    },
  },
  beforeMount() {
    this.atualizaLista()
  },
  setup() {
    const appLoading = $themeConfig.app.appLoadingImage

    const deleteFatura = id => {
      swal.fire({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Você tem certeza que quer deletar esta Fatura?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          appLoading.show()
          store.dispatch('faturasCliente/delete', id).then(() => {
            appLoading.hide()
            swal.fire({
              icon: 'success',
              title: 'Deletado com sucesso!',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }).catch(() => {
            swal.fire({
              icon: 'erro',
              title: 'Erro ao deletar!',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    return { 
      ...FaturasComposition(),
      ...utils,
      deleteFatura,
    }
  },
  methods: {
    atualizaLista() {
      this.$store.dispatch('faturasCliente/findAll')
    },
  },
}
</script>

<style scoped>
a {
  color: #3366CC;
}
</style>