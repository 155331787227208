<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar Novo Usuário
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <h5 class="pb-1">
            Dados Gerais
          </h5>

          <validation-provider
            #default="validationContext"
            name="Nome Completo"
            rules="required"
          >
            <b-form-group
              label="Nome Completo *"
              label-for="nome"
            >
              <b-form-input
                id="nome"
                v-model="colaborador.nome"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="João da Silva"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Perfil"
            rules="required"
          >
            <b-form-group
              label="Perfil *"
              label-for="perfil"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="perfil"
                v-model="colaborador.perfilId"
                :options="perfilOptions"
                :reduce="val=>val.value"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="CPF"
          >
            <b-form-group
              label="CPF"
              label-for="cpf"
            >
              <b-form-input
                id="cpf"
                v-model="colaborador.cpf"
                v-cleave="cleaveOptions.cpf"
                :state="getValidationState(validationContext)"
                trim
                placeholder="000.000.000-00"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <h5 class="py-1">
            Dados de Login
          </h5>

          <validation-provider
            #default="validationContext"
            name="Login"
            rules="required|email"
          >
            <b-form-group
              label="Login(Email) *"
              label-for="login"
            >
              <b-form-input
                id="login"
                v-model="colaborador.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="colaborador@email.com"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Senha"
            rules="required"
          >
            <b-form-group
              label="Senha *"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="colaborador.password"
                :state="getValidationState(validationContext)"
                trim
                type="password"
                placeholder="******"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Confirme a senha"
            rules="required|confirmed:Senha"
          >
            <b-form-group
              label="Confirme a senha *"
              label-for="confirmPassword"
            >
              <b-form-input
                id="confirmPassword"
                v-model="colaborador.confirmPassword"
                :state="getValidationState(validationContext)"
                trim
                type="password"
                placeholder="******"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Usuário Fraction"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Usuário Fraction"
              label-for="login-fraction"
            >
              <b-form-input
                id="login-fraction"
                v-model="colaborador.loginFraction"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Senha Fraction"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Senha Fraction"
              label-for="password-fraction"
            >
              <b-form-input
                id="password-fraction"
                v-model="colaborador.senhaFraction"
                :state="getValidationState(validationContext)"
                trim
                type="password"
                placeholder="******"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { reactive, toRefs } from '@vue/composition-api'
import cleaveOptions from '@/libs/cleave/options'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import firebaseAlt from '@/libs/firebase/secundary-instance'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import { verifyErrorCode } from '@/libs/firebase/functions'

const colaboradoresStore = createNamespacedHelpers('colaboradores')
const perfisStore = createNamespacedHelpers('perfis')

export default {
  name: 'AddColaboradorSidebar',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  beforeMount() {
    const { empresaId } = JSON.parse(localStorage.getItem('userName'))
    this.$store.dispatch('perfis/findAllComPermissao', empresaId).then(() => {
      this.$store.dispatch('perfis/setOptions')
    })
  },
  setup(_, { emit }) {
    const { save } = colaboradoresStore.useActions(['save'])

    const blankUsuario = {
      nome: null,
      email: null,
      perfilId: null,
      cpf: null,
      password: null,
      confirmPassword: null,
      loginFraction: null,
      senhaFraction: null,
      clienteId: null,
    }

    const state = reactive({
      colaborador: JSON.parse(JSON.stringify(blankUsuario)),
      perfilOptions: perfisStore.useGetters(['perfilOptions']).perfilOptions,
    })

    const resetUsuario = () => {
      state.colaborador = JSON.parse(JSON.stringify(blankUsuario))
    }

    const appLoading = $themeConfig.app.appLoadingImage

    const error = codigoErro => {
      Vue.$toast(
        {
          component: ToastificationContent,
          props: {
            title: verifyErrorCode(codigoErro),
            icon: 'AlertCircleIcon',
            variant: 'primary',
          },
        },
        {
          timeout: 5000,
          draggable: true,
          hideProgressBar: false,
        },
      )
    }

    const onSubmit = () => {
      const userName = JSON.parse(localStorage.getItem('userName'))
      appLoading.show()
      firebaseAlt.auth().createUserWithEmailAndPassword(state.colaborador.email, state.colaborador.password)
        .then(userCredential => {
          const colaboradorToSave = {
            nome: state.colaborador.nome,
            perfilId: state.colaborador.perfilId,
            empresaId: userName.empresaId,
            cpf: state.colaborador.cpf,
            email: state.colaborador.email,
            firebaseUid: userCredential.user.uid,
            loginFraction: state.colaborador.loginFraction,
            senhaFraction: state.colaborador.senhaFraction,
            ativo: 1,
            nivel: 'USUARIO',
          }
          firebaseAlt.auth().signOut()
          save(colaboradorToSave)
          const { user } = userCredential
          user.sendEmailVerification()
        })
        .then(() => {
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Não esqueça de verificar sua caixa de spam!',
                icon: 'MailIcon',
                variant: 'warning',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Email Enviado!',
                icon: 'MailIcon',
                variant: 'success',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Colaborador inserido com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
          emit('refresh')
          emit('update:active', false)
        })
        .catch(erro => {
          error(erro.code)
        })
        .finally(() => {
          appLoading.hide()
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUsuario)

    return {
      ...toRefs(state),
      // Form Functions
      refFormObserver,
      getValidationState,
      resetForm,
      // cleave
      cleaveOptions,
      // methods
      onSubmit,
    }
  },
}
</script>

<style scoped>

</style>