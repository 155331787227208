<template>
  <b-card>
    <validation-observer
      #default="{handleSubmit}"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              name="Nome Taxa"
              :rules="{ required: true }"
            >
              <b-form-group
                label="Nome"
                label-for="nome"
              >
                <b-form-input
                  id="nome"
                  v-model="taxa.nomeTaxa"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="'empresa': Volume 4 em 4 + R$4,00"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              name="Tipo Taxa"
            >
              <b-form-group
                label="Tipo de Taxa"
                label-for="tipoTaxa"
              >
                <v-select
                  v-model="taxa.tipoTaxa"
                  :options="tipoTaxa"
                />
                <span class="undermessage-error text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="taxa.tipoTaxa === 'VOLUME'"
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              name="A partir de X volumes"
              rules="required|integer"
            >
              <b-form-group
                label="A partir de X volumes"
                label-for="acimaLimite"
              >
                <b-form-input
                  id="acimaLimite"
                  v-model="taxa.acimaLimite"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Qtd Volumes"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="taxa.tipoTaxa === 'VOLUME'"
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              name="acimaLimiteValor"
            >
              <b-form-group
                label="Acrescentar a taxa de"
                label-for="acimaLimiteValor"
              >
                <CurrencyInput
                  v-model="taxa.acimaLimiteValor"
                  name="acimaLimiteValor"
                  :validation-context="validationContext"
                  :show-symbol="true"
                  placeholder="Valor"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="taxa.tipoTaxa === 'VOLUME'"
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              name="acrescimoPorQuantidade"
              rules="required|integer"
            >
              <b-form-group
                label="A cada X volumes"
                label-for="acrescimoPorQuantidade"
              >
                <b-form-input
                  id="acrescimoPorQuantidade"
                  v-model="taxa.acrescimoPorQuantidade"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Qtd Volumes"
                  number
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            v-if="taxa.tipoTaxa === 'VOLUME'"
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              name="acrescimoPorQuantidadeValor"
            >
              <b-form-group
                label="Acrescentar a taxa de"
                label-for="acrescimoPorQuantidadeValor"
              >
                <CurrencyInput
                  id="acrescimoPorQuantidadeValor"
                  v-model="taxa.acrescimoPorQuantidadeValor"
                  name="acrescimoPorQuantidadeValor"
                  :state="getValidationState(validationContext)"
                  :validation-context="validationContext"
                  :show-symbol="true"
                  placeholder="Valor"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            v-if="taxa.tipoTaxa === 'PESO'"
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              name="A partir de X Peso"
              rules="required"
            >
              <b-form-group
                label="A partir de X Peso(kg)"
                label-for="acimaLimite"
                style="margin-bottom: 0 !important;"
              >
                <CurrencyInput
                  id="acimaLimite"
                  v-model="taxa.acimaLimiteKg"
                  name="acimaLimite"
                  :state="getValidationState(validationContext)"
                  :validation-context="validationContext"
                  style="margin-bottom: 0 !important;"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="taxa.tipoTaxa === 'PESO'"
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              name="acimaLimiteValor"
            >
              <b-form-group
                label="Acrescentar a taxa de"
                label-for="acimaLimiteValor"
              >
                <CurrencyInput
                  v-model="taxa.acimaLimiteValor"
                  name="acimaLimiteValor"
                  :validation-context="validationContext"
                  :show-symbol="true"
                  placeholder="Valor"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="taxa.tipoTaxa === 'PESO'"
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              name="acrescimoPorQuantidade"
              rules="required"
            >
              <b-form-group
                label="A cada X Peso(kg)"
                label-for="acrescimoPorQuantidade"
                style="margin-bottom: 0 !important;"
              >
                <CurrencyInput
                  id="acrescimoPorQuantidade"
                  v-model="taxa.acrescimoPorQuantidadeKg"
                  name="acrescimoPorQuantidade"
                  :state="getValidationState(validationContext)"
                  :validation-context="validationContext"
                  style="margin-bottom: 0 !important;"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="taxa.tipoTaxa === 'PESO'"
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              name="acimaLimiteValor"
            >
              <b-form-group
                label="Acrescentar a taxa de"
                label-for="acimaLimiteValor"
              >
                <CurrencyInput
                  v-model="taxa.acrescimoPorQuantidadeValor"
                  name="acimaLimiteValor"
                  :validation-context="validationContext"
                  :show-symbol="true"
                  placeholder="Valor"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="salvarTaxa()"
    >
      Salvar Mudanças
      <feather-icon
        icon="SaveIcon"
        class="ml-1"
      />
    </b-button>
    <b-button
      variant="outline-secondary"
      class="mr-sm-1 mr-0 mb-1 mb-sm-0"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="$router.go(-1)"
    >
      Cancelar Alterações
      <feather-icon
        icon="RefreshCwIcon"
        class="ml-1"
      />
    </b-button>
    <b-button
      variant="outline-secondary"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="inactiveTaxa()"
    >
      {{ taxa.ativo ? 'Inativar' : 'Ativar' }}
      <feather-icon
        icon="TrashIcon"
        class="ml-1"
      />
    </b-button>
  </b-card>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { reactive, toRefs } from '@vue/composition-api'
import cleaveOptions from '@/libs/cleave/options'
import utils from '@/helpers/utils'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'
import store from '@/store'

const taxasStore = createNamespacedHelpers('taxas')

const appLoading = $themeConfig.app.appLoadingImage

export default {
  name: 'TaxasEditar',
  data() {
    return {
      taxaId: this.$router.currentRoute.params.taxaId,
    }
  },
  beforeMount() {
    this.$store.dispatch('taxas/findById', this.taxaId)
  },
  methods: {
    inactiveTaxa() {
      this.$swal({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Você tem certeza que quer inativar essa taxa?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.taxa.ativo = !this.taxa.ativo
          this.save(this.taxa.ativo ? 'inativar' : 'ativar')
        }
      })
    },
    salvarTaxa() {
      this.$swal({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Deseja salvar as alterações?',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.save('editar')
        }
      })
    },
    save(msg) {
      appLoading.show()
      this.$store.dispatch('taxas/edit', {
        taxaId: this.taxa.id,
        taxa: this.taxa,
      }).then(resolve => {
        if (resolve.success) {
          appLoading.hide()
          this.$swal({
            icon: 'success',
            text: 'Você alterou os dados da taxa!',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() => {
            this.$router.push('/cadastro/taxas')
          })
        } else {
          this.taxa.ativo = !this.taxa.ativo
          appLoading.hide()
          this.$swal({
            icon: 'error',
            title: 'Erro...',
            text: `Ocorreu um erro ao tentar ${msg} a taxa!`,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        }
      })
    },
  },
  setup() {
    const { findById } = taxasStore.useActions(['findById'])

    const stateReactive = reactive({
      taxa: taxasStore.useGetters(['selected']).selected,
    })

    const resetTaxa = () => {
      findById(this.taxaId)
    }

    const tipoTaxa = [...store.state.taxas.tipoTaxa]

    const currentUser = JSON.parse(localStorage.getItem('userName'))
    const permissoes = JSON.parse(localStorage.getItem('permissoes')).filter(p => p.permissaoId === 2)[0]

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetTaxa)
    return {
      // REFS
      ...toRefs(stateReactive),
      // FORM FUNCS
      tipoTaxa,
      refFormObserver,
      getValidationState,
      // MISC
      avatarText,
      cleaveOptions,
      currentUser,
      permissoes,
      ...utils,
    }
  },
}
</script>

<style scoped>

</style>