import EnviosService from '@/service/EnviosService'

export default {
  namespaced: true,
  state: {
    envios: null,
    selected: null,
  },
  getters: {
    envios(state) {
      return state.envios
    },
    selected(state) {
      return state.selected
    },
  },
  mutations: {
    UPDATE_ENVIOS(state, envios) {
      state.envios = envios
    },
    UPDATE_SELECTED(state, envio) {
      state.selected = envio
    },
    SAVE_ENVIO(state, envio) {
      state.envios.push(envio)
    },
    EDIT_ENVIO(state, envio) {
      if (state.envios) {
        const index = state.envios.findIndex(item => item.envioId === envio.envioId)
        Object.assign(state.envios[index], envio)
      } else {
        state.selected = envio
      }
    },
    DELETE_ENVIO(state, envioId) {
      if (state.envios) {
        const index = state.envios.findIndex(item => item.envioId === envioId)
        state.envios.splice(index, 1)
      }
    },
  },
  actions: {
    async findAll({ commit }) {
      const envios = await EnviosService.findAll()
      commit('UPDATE_ENVIOS', envios)
    },
    async findById({ commit }, envioId) {
      const envio = await EnviosService.findById(envioId)
      commit('UPDATE_SELECTED', envio)
    },
    async save({ commit }, envio) {
      const envioToSave = envio
      const envioId = await EnviosService.save(envioToSave)
      envioToSave.envioId = envioId
      commit('SAVE_ENVIO', envioToSave)
    },
    async edit({ commit }, { envioId, envio }) {
      const edited = await EnviosService.edit(envioId, envio)
      commit('EDIT_ENVIO', edited)
    },
    async delete({ commit }, envioId) {
      await EnviosService.deleteById(envioId)
      commit('DELETE_ENVIO', envioId)
    },
  },
}