import HttpClient from './http-clients/portaljad'

const baseUrl = 'usuarios'

export default class ColaboradoresService {
  static async findAll(empresaId) {
    try {
      const { data } = await HttpClient.get([baseUrl, 'empresa', empresaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAllByCliente(clienteId) {
    try {
      const { data } = await HttpClient.get([baseUrl, 'cliente', clienteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(usuarioId) {
    try {
      const { data } = await HttpClient.get([baseUrl, usuarioId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async save(usuario) {
    try {
      const { data } = await HttpClient.post(baseUrl, usuario)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async edit(usuarioId, usuario) {
    try {
      const { data } = await HttpClient.put([baseUrl, usuarioId].join('/'), usuario)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async deleteById(usuarioId) {
    try {
      await HttpClient.delete([baseUrl, usuarioId].join('/'))
    } catch (error) {
      throw error.response.data
    }
  }

  static async findByUid(firebaseUid, senha) {
    try {
      if (!firebaseUid) {
        return undefined
      }
      const { data } = await HttpClient.post(`${baseUrl}/firebaseUid`, { firebaseUid, senha })
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}