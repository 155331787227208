<template>
  <validation-provider
    #default="validationContext"
    :name="label"
    :rules="{ required: required }"
  >
    <b-form-group
      :label="label"
      :label-for="id"
    >
      <date-range-picker
        :id="id"
        ref="picker"
        v-model="dateRangeCopy"
        :opens="opens"
        :locale-data="localeData"
        :min-date="minDate"
        :max-date="maxDate"
        :single-date-picker="singleDatePicker"
        :time-picker="timePicker"
        :time-picker24hour="timePicker24Hour"
        :show-week-numbers="showWeekNumbers"
        :show-dropdowns="showDropdowns"
        :auto-apply="autoApply"
        :linked-calendars="linkedCalendars"
        :ranges="showRanges ? ranges : false"
        @update="onApply"
      >
        <template
          v-slot:input="picker"
        >
          <b-form-input
            v-model="dateRangeInput"
            class="border-0 pl-1"
          />
          {{ picker.startDate }} - {{ picker.endDate }}
        </template>
      </date-range-picker>
      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'
// DOC: https://innologica.github.io/vue2-daterange-picker

export default {
  name: 'DateRange',
  components: { DateRangePicker },
  props: {
    dateRange: {
      type: Object,
      default: () => ({
        startDate: new Date().toISOString().split('T')[0],
        endDate: new Date().toISOString().split('T')[0],
      }),
    },
    id: {
      type: String,
      default: 'DateRange',
    },
    label: {
      type: String,
      default: 'Data inicial - Data final',
    },
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
    opens: {
      type: String,
      default: 'right',
    },
    showRanges: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    getValidationState: {
      type: Function,
      default: null,
    },
    closeOnEsc: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alwaysShowCalendars: {
      type: Boolean,
      default: true,
    },
    autoApply: {
      type: Boolean,
      default: true,
    },
    timePickerSeconds: {
      type: Boolean,
      default: false,
    },
    timePicker24Hour: {
      type: Boolean,
      default: true,
    },
    timePicker: {
      type: Boolean,
      default: false,
    },
    showDropdowns: {
      type: Boolean,
      default: false,
    },
    singleDatePicker: {
      type: Boolean,
      default: false,
    },
    linkedCalendars: {
      type: Boolean,
      default: true,
    },
    showWeekNumbers: {
      type: Boolean,
      default: false,
    },
    controlContainerClass: {
      type: String,
      default: 'form-control reportrange-ext date-picker-container',
    },
  },
  data() {
    return {
      dateRangeCopy: this.dateRange,
      dateRangeInput: '',
    }
  },
  computed: {
    objExport() {
      const startDate = moment(this.dateRangeCopy.startDate).toDate()
      const formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`
      
      const endDate = moment(this.dateRangeCopy.endDate).toDate()
      const formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`

      return {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }
    },
    ranges() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      const yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)

      const getFirstDayOfMonth = (year, month) => new Date(year, month, 1)
      const getLastDayOfMonth = (year, month) => new Date(year, month + 1, 0)

      const thisMonthStart = getFirstDayOfMonth(today.getFullYear(), today.getMonth())
      const thisMonthEnd = getLastDayOfMonth(today.getFullYear(), today.getMonth())

      return {
        'Hoje ': [today, today],
        'Ontem ': [yesterday, yesterday],
        'Este mês': [thisMonthStart, thisMonthEnd],
        'Este ano': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        'Mês passado': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
      }
    },
    localeData() {
      return {
        direction: 'ltr',
        format: 'dd/MM/yyyy',
        separator: ' - ',
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        weekLabel: 'S',
        customRangeLabel: 'Período personalizado',
        daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        firstDay: 1,
      }
    },
  },
  watch: {
    dateRangeInput() {
      if (this.dateRangeInput.length === 0) {
        this.createStringInput()
      }
      const dates = this.dateRangeInput.replace(/\s+/g, '').split('-')
      const startDate = dates[0]
      const endDate = dates[1]
      if (dates.length !== 2 || startDate.length !== 10 || endDate.length !== 10) {
        return
      }

      const endDateObj = {
        day: endDate.substr(0, 2),
        month: endDate.substr(3, 2),
        year: endDate.substr(-4),
      }
      const endDateString = `${endDateObj.month}-${endDateObj.day}-${endDateObj.year}`

      const startDateObj = {
        day: startDate.substr(0, 2),
        month: startDate.substr(3, 2),
        year: startDate.substr(-4),
      }
      const startDateString = `${startDateObj.month}-${startDateObj.day}-${startDateObj.year}`

      if (Number.isNaN(new Date(startDateString).getTime()) || Number.isNaN(new Date(endDateString).getTime())) {
        return
      }
      this.dateRangeCopy = {
        startDate: moment(startDateString).toDate(),
        endDate: moment(endDateString).toDate(),
      }
    },
    dateRangeCopy() {
      this.$emit('update:dateRange', this.objExport)
    },
  },
  created() {
    this.createStringInput()
  },
  methods: {
    createStringInput() {
      const start = moment(this.dateRange.startDate, 'YYYY-MM-DD').toDate()
      const end = moment(this.dateRange.endDate, 'YYYY-MM-DD').toDate()

      this.dateRangeInput = `${start.toLocaleDateString('pt-BR')} - ${end.toLocaleDateString('pt-BR')}`
      this.$emit('update:dateRange', this.objExport)
    },
    onApply(dateRange) {
      this.updateValues(dateRange)
      this.$emit('update:dateRange', this.objExport)
    },
    updateValues(dateRange) {
      this.dateRangeInput = `${dateRange.startDate.toLocaleDateString('pt-BR')} - ${dateRange.endDate.toLocaleDateString('pt-BR')}`
    },
  },
}
</script>

<style lang="scss">
  .vue-daterange-picker {
    width: 100%;
  }
  .vue-daterange-picker .form-control {
    padding: 0;
    border: 1px solid #d8d6de;
  }
</style>