import store from '@/store'
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import utils from '@/helpers/utils'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import ExcelComposition from '@/components/exportar/excel.composition'

export default () => {
  const pesquisaEnviosStore = createNamespacedHelpers('pesquisaEnvios')
  const clientesStore = createNamespacedHelpers('clientes')
  const departamentoStore = createNamespacedHelpers('departamentos')
  const movimentacaoEventoStore = createNamespacedHelpers('movimentacaoEvento')

  const { setCte } = movimentacaoEventoStore.useActions(['setCte'])
  const { setDataEnvio } = movimentacaoEventoStore.useActions(['setDataEnvio'])

  const reversaOptions = [
    { value: false, label: 'Envio' },
    { value: true, label: 'Reversa' },
  ]

  const state = reactive({
    dados: pesquisaEnviosStore.useGetters(['dados']).dados,
    movimentacaoIds: pesquisaEnviosStore.useGetters(['movimentacaoIds']).movimentacaoIds,
    clientes: clientesStore.useGetters(['clientes']).clientes,
    selectClientes: [],
    reversaOptions,
    departamentos: departamentoStore.useGetters(['departamentos']).departamentos,
    selectDepartamentos: [],
    allSelected: false,
    showCteTrackingSidebar: false,
    dateRange: {
      startDate: utils.dateRangeComTimeZone(),
      endDate: utils.dateRangeComTimeZone(),
    },
    filtros: {
      clienteId: null,
      clienteDepartamentoId: null,
      destinatarioNome: null,
      shippmentId: null,
      reversa: false,
      cte: null,
      dataInicial: new Date().toISOString().split('T')[0],
      dataFinal: new Date().toISOString().split('T')[0],
    },
    currentPage: 1,
    perPage: 10,
    perPageOptions: [10, 25, 50, 100],
    carregando: false,
    search: '',
    order: {},
  })

  const tableColumns = [
    { key: 'select', sortable: false },
    { key: 'shippmentId', sortable: true, label: 'Shippment Id', thStyle: { whiteSpace: 'nowrap' } },
    { key: 'cte', sortable: true, label: 'CTE', thStyle: { whiteSpace: 'nowrap' } },
    { key: 'nomeServico', sortable: true, label: 'Serviço' },
    { key: 'dataEnvio', sortable: true, label: 'Data' },
    { key: 'numeroDocumento', sortable: true, label: 'Documento', thStyle: { minWidth: '200px', whiteSpace: 'nowrap' } },
    { key: 'clienteNome', sortable: true, label: 'Cliente', thStyle: { minWidth: '300px', whiteSpace: 'nowrap' } },
    { key: 'nomeDestinatario', sortable: true, label: 'Destinatário', tdClass: 'text-nowrap' },
    { key: 'cepDestinatario', sortable: true, label: 'CEP Dest.', tdClass: 'text-nowrap', thStyle: { whiteSpace: 'nowrap' } },
    { key: 'numeroNotaFiscal', sortable: true, label: 'NF', tdClass: 'text-nowrap', thStyle: { whiteSpace: 'nowrap' } },
    { key: 'pesoTotalTaxado', sortable: true, label: 'Peso(kg)' },
    { key: 'reversa', sortable: true, label: 'Tipo' },
    { key: 'dataUltimoEvento', sortable: true, tdClass: 'text-nowrap' },
    { key: 'ultimoEvento', sortable: true, label: 'Eventos', tdClass: 'text-nowrap' },
    { key: 'quantidade', sortable: true, label: 'Qtd Vol.', thStyle: { whiteSpace: 'nowrap' } },
    { key: 'valorParaSeguro', sortable: true, label: '$VD' },
    { key: 'valorTotalTaxado', sortable: true, label: 'Valor' },
  ]

  const { appCarregando } = $themeConfig.app

  watch(() => state.carregando, value => {
    if (value) {
      appCarregando.show()
    } else {
      appCarregando.hide()
    }
  })

  watch(() => state.clientes, value => {
    value.forEach(elemento => {
      if (elemento.nomeFantasia !== 'Sem Cadastro') {
        state.selectClientes.push({ label: elemento.nomeFantasia,
          value: elemento.clienteId })
      }
    })
  })

  watch(() => state.departamentos, value => {
    value.forEach(elemento => {
      state.selectDepartamentos.push({ label: elemento.nomeDepartamento,
        value: elemento.clienteDepartamentoId })
    })
  })

  const resetDados = () => {
    state.dados.movimentacaoIds = []
    state.dados.totalQuantidade = 0
    state.dados.totalValorParaSeguro = 0
    state.dados.totalValorTotalTaxado = 0
    state.currentPage = 1
    state.dados.totalRows = 0
    state.search = ''
    state.order = {}
  }

  const tableRef = ref(null)

  const sendRequest = filtros => {
    state.carregando = true
    store.dispatch('pesquisaEnvios/pesquisa', filtros).then(() => {
      if (state.filtros.reversa && tableColumns.find(e => e.label === 'Destinatário')) {
        tableColumns.find(e => e.label === 'Destinatário').label = 'Remetente'
        tableColumns.find(e => e.label === 'CEP Dest.').label = 'CEP Reme.'
      } 
      if (!state.filtros.reversa && tableColumns.find(e => e.label === 'Remetente')) {
        tableColumns.find(e => e.label === 'Remetente').label = 'Destinatário'
        tableColumns.find(e => e.label === 'CEP Reme.').label = 'CEP Dest.'
      }
      tableRef.value.refresh()
      state.carregando = false
    }).catch(() => {
      state.carregando = false
      swal.fire({
        icon: 'warning',
        title: 'Erro ao buscar Envios',
        text: 'O Resultado teve algum erro.',
        showConfirmButton: false,
        timer: 3000,
      })
    })
  }

  const getMovIds = tipoPesquisa => {
    switch (tipoPesquisa) {
      case 'PAGINACAO': return state.dados.movimentacaoIds
      case 'ORDENACAO': return state.dados.movimentacaoIds
      case 'FILTRAGEM': return state.movimentacaoIds
      case 'ATUALIZAR': return state.movimentacaoIds
      default: return []
    }
  }

  const onSubmit = tipoPesquisa => {
    if (tipoPesquisa === 'NOVA_PESQUISA') {
      resetDados()
    }
    const filtros = {
      ...state.filtros,
      movimentacaoIds: getMovIds(tipoPesquisa),
      totalQuantidade: state.dados.totalQuantidade,
      totalValorParaSeguro: state.dados.totalValorParaSeguro,
      totalValorTotalTaxado: state.dados.totalValorTotalTaxado,
      currentPage: state.currentPage,
      perPage: state.perPage,
      totalRows: state.dados.totalRows || 0,
      filtroGeral: state.search,
      dataInicial: state.dateRange.startDate,
      dataFinal: state.dateRange.endDate,
      tipoPesquisa,
      movimentacaoIdsSelecionados: state.dados.movimentacaoIdsSelecionados,
      order: state.order,
    }
    sendRequest(filtros)
  }

  onSubmit('NOVA_PESQUISA')

  const atualizaLista = () => {
    onSubmit('ATUALIZAR')
  }

  const limpaCte = () => {
    setCte('')
  }

  const itemSelecionado = id => state.dados.movimentacaoIdsSelecionados.find(mov => mov.id === id)

  const selectAllHandler = bool => {
    store.dispatch('pesquisaEnvios/selectAll', bool)
  }

  const abrirCteTracking = data => {
    setCte(data)
    setDataEnvio(state.dateRange.startDate)
    state.showCteTrackingSidebar = true
  }

  const reexportar = () => {
    appCarregando.show()
    const objSelected = state.dados.lista.filter(obj => obj.selected).map(envio => envio.shippmentId)
    store.dispatch('movimentacoes/reexportar', objSelected)
      .then(data => {
        appCarregando.hide()
        window.open(data, '_self')
      })
  }

  const estornarAfericao = () => {
    appCarregando.show()
    const selected = state.dados.movimentacaoIdsSelecionados.filter(m => m.selecionado)
    const objSelected = selected.map(s => {
      const asd = state.dados.lista.find(l => l.movimentacaoId === s.id)
      return asd.shippmentId
    })
    store.dispatch('movimentacoes/estornarAfericao', objSelected)
      .then(() => {
        appCarregando.hide()
        onSubmit()
      }).catch(() => {
        appCarregando.hide()
      })
  }

  const confirmaEstornarObjetos = () => {
    swal.fire({
      icon: 'warning',
      title: 'Atenção!',
      text: 'Atenção! Ao estornar os envios selecionados, eles não constarão mais na tela '
        + 'de Pesquisa Envios da Unidade e do Cliente. Deseja mesmo estornar?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(async result => {
      state.allSelected = false
      if (result.value) {
        estornarAfericao()
      }
    })
  }
  const { downloadSpreadsheet } = ExcelComposition()
  const downloadExcel = () => {
    const ids = state.dados.movimentacaoIdsSelecionados.filter(mov => mov.selecionado === true).map(mov => mov.id)
    if (ids.length === 0) {
      appCarregando.hide()
      swal.fire({
        icon: 'warning',
        title: 'Sem dados para exportar',
        text: 'O Resultado da sua busca não retornou dados para exportação.',
        showConfirmButton: false,
        timer: 3000,
      })
    } else {
      store.dispatch('pesquisaEnvios/pesquisaExportar', { movimentacaoIds: ids, reversa: state.filtros.reversa })
        .then(dados => {
          if (dados.length === 0) {
            appCarregando.hide()
            swal.fire({
              icon: 'warning',
              title: 'Sem dados para exportar',
              text: 'O Resultado da sua busca não retornou dados para exportação.',
              showConfirmButton: false,
              timer: 3000,
            })
          } else {
            downloadSpreadsheet(dados, 'Envios')
          }
        })
    }
  }
  const gerarExcel = () => {
    appCarregando.show()
    setTimeout(() => {
      downloadExcel()
    }, 100)
  }

  watch(() => state.search, () => {
    state.currentPage = 1
    onSubmit('FILTRAGEM')
  })

  const changePerPage = () => {
    state.currentPage = 1
    onSubmit('PAGINACAO')
  }

  const changePage = value => {
    state.currentPage = value
    onSubmit('PAGINACAO')
  }

  const sortingChanged = ctx => {
    state.order = {
      ...ctx,
    }
    if (state.dados && state.dados.totalRows > 1) {
      onSubmit('ORDENACAO')
    }
  }

  return {
    ...toRefs(state),
    onSubmit,
    // PAGE CONFIG
    atualizaLista,
    // TABLE CONFIGS,
    tableRef,
    tableColumns,
    abrirCteTracking,
    itemSelecionado,
    selectAllHandler,
    reexportar,
    confirmaEstornarObjetos,
    estornarAfericao,
    gerarExcel,
    limpaCte,
    changePerPage,
    changePage,
    sortingChanged,
  }
}