<template>
  <component :is="departamento ? 'b-card' : 'div'">
    <b-card v-if="departamento">
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :text="avatarText(departamento.nomeDepartamento)"
            :src="departamento.urlLogo"
            variant="light-success"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{ departamento.nomeDepartamento }}
        </h4>
      </b-media>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(salvarDepartamento)"
        >
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="Nome"
                :rules="{ required: true }"
              >
                <b-form-group
                  label="Nome do Departamento"
                  label-for="nome"
                >
                  <b-form-input
                    id="nome"
                    v-model="departamento.nomeDepartamento"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Insira o nome do departamento"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="logo"
                rules="size:100"
              >
                <b-form-group
                  label="Logo do departamento"
                  label-for="logo"
                >
                  <b-form-file
                    id="logo"
                    v-model="logoFile"
                    placeholder="Selecione uma imagem"
                    :state="getValidationState(validationContext)"
                    rop-placeholder="Solte o arquivo aqui"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <template #description>
                    <small>
                      <feather-icon
                        icon="AlertTriangleIcon"
                      /> A imagem a ser importada deve possuir tamanho máximo de 100kB
                    </small>
                  </template>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              sm="2"
            >
              <validation-provider
                v-slot="validationContext"
                name="Tipo de Documento"
                :rules="{ required: false }"
              >
                <b-form-group
                  label="Tipo de Documento *"
                  label-for="tipoDocumento"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="departamento.tipoDocumento"
                    :reduce="val=>val.value"
                    :options="tipoDocumentoOptions"
                    :clearable="false"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              sm="4"
            >
              <validation-provider
                v-slot="validationContext"
                name="documento"
                :rules="{ required: false, noAlpha: true }"
              >
                <b-form-group
                  label="CPF/CNPJ do Departamento"
                  label-for="documento"
                >
                  <b-form-input
                    id="documento"
                    :key="cleaveKey+1"
                    v-model="departamento.numeroDocumento"
                    v-cleave="departamento.tipoDocumento ? cleaveOptions[departamento.tipoDocumento.toLowerCase()] : cleaveOptions.cpf"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Insira aqui seu CPF ou CNPJ"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="codigo"
                :rules="{ required: false }"
              >
                <b-form-group
                  label="Código de referência"
                  label-for="codigo"
                >
                  <b-form-input
                    id="codigo"
                    v-model="departamento.codigoReferencia"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Insira o código de referência"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              sm="12"
            >
              <b-form-checkbox
                id="todos-acessam"
                v-model="departamento.todosUsuariosAcessam"
                class="my-1"
              >
                Permitir que todos os usuários deste cliente acessem este dpto.
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              cols="12"
              sm="12"
            >
              <label 
                class="d-inline"
                for="switch-endereco"
              >
                <h5 class="d-inline">Endereço</h5>
              </label>
              <b-form-checkbox
                id="switch-endereco"
                v-model="departamento.temEndereco"
                switch
                class="d-inline ml-1 mb-2"
              />
            </b-col>
          </b-row>
          <div 
            v-if="departamento.temEndereco"
            class="mt-2"
          >
            <b-row>
              <b-col
                cols="12"
                sm="4"
              >
                <validation-provider
                  v-slot="validationContext"
                  name="Nome Endereço"
                  rules="required"
                >
                  <b-form-group
                    label="Nome Endereço"
                    label-for="nome-endereco"
                  >
                    <b-form-input
                      id="nome-endereco"
                      v-model="departamento.nomeEndereco"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Nome do endereço"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <validation-provider
                  v-slot="validationContext"
                  name="CEP"
                  rules="required|cep"
                >
                  <b-form-group
                    label="CEP *"
                    label-for="cep"
                  >
                    <b-form-input
                      id="cep"
                      v-model="departamento.cep"
                      v-cleave="cleaveOptions.cep"
                      :state="getValidationState(validationContext)"
                      placeholder="00000-000"
                      @blur="buscaEndereco()"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <validation-provider
                  v-slot="validationContext"
                  name="Logradouro"
                  rules="required"
                >
                  <b-form-group
                    label="Logradouro *"
                    label-for="logradouro"
                  >
                    <b-form-input
                      id="logradouro"
                      v-model="departamento.logradouro"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Avenida Brasil"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <validation-provider
                  v-slot="validationContext"
                  name="Número"
                  rules="noAlpha|noSpecial"
                >
                  <b-form-group
                    label="Número"
                    label-for="numero"
                  >
                    <b-form-input
                      id="numero"
                      v-model="departamento.numero"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="100"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <validation-provider
                  v-slot="validationContext"
                  name="Complemento"
                >
                  <b-form-group
                    label="Complemento"
                    label-for="complemento"
                  >
                    <b-form-input
                      id="complemento"
                      v-model="departamento.complemento"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Bloco B - Sala 210"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <validation-provider
                  v-slot="validationContext"
                  name="Bairro"
                  rules="required"
                >
                  <b-form-group
                    label="Bairro *"
                    label-for="bairro"
                  >
                    <b-form-input
                      id="bairro"
                      v-model="departamento.bairro"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Centro"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <validation-provider
                  v-slot="validationContext"
                  name="Cidade"
                  rules="required"
                >
                  <b-form-group
                    label="Cidade *"
                    label-for="cidade"
                  >
                    <b-form-input
                      id="cidade"
                      v-model="departamento.cidade"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="São Paulo"
                      readonly
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <validation-provider
                  v-slot="validationContext"
                  name="UF"
                  rules="required"
                >
                  <b-form-group
                    label="UF *"
                    label-for="uf"
                  >
                    <b-form-input
                      id="uf"
                      v-model="departamento.uf"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="SP"
                      readonly
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>
          <b-button
            type="submit"
            variant="primary"
            class="mt-3 mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Salvar Mudanças
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mt-3 mr-sm-1 mr-0 mb-1 mb-sm-0"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="retornarParaTab()"
          >
            Voltar
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </component>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import cleaveOptions from '@/libs/cleave/options'
import CepService from '@/service/CepService'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'

const departamentosStore = createNamespacedHelpers('departamentos')

const appLoading = $themeConfig.app.appLoadingImage

export default {
  name: 'DepartamentosEditar',
  beforeMount() {
    this.$store.dispatch('departamentos/findById', this.$route.params.departamentoId)
  },
  setup() {
    const { findById } = departamentosStore.useActions(['findById'])

    const state = reactive({
      departamento: departamentosStore.useGetters(['selected']).selected,
      logoFile: null,
    })

    const resetDepartamento = () => {
      findById(state.departamento.clienteDepartamentoId)
    }

    const tipoDocumentoOptions = [
      { label: 'CPF', value: 'CPF' },
      { label: 'CNPJ', value: 'CNPJ' },
    ]

    const cleaveKey = ref(0)

    function incrementCleaveKey() {
      cleaveKey.value += 1
    }

    watch(() => state.departamento, () => {
      watch(() => state.departamento.tipoDocumento, () => {
        incrementCleaveKey()
      })
    })

    const buscaEndereco = () => {
      CepService.buscarEndereco(state.departamento.cep).then(endereco => {
        if (endereco) {
          if (endereco.success) {
            const dadosEndereco = JSON.parse(endereco.body)
            dadosEndereco.cep = state.departamento.cep
            state.departamento.logradouro = dadosEndereco.logradouro
            state.departamento.numero = dadosEndereco.numero
            state.departamento.complemento = dadosEndereco.complemento
            state.departamento.bairro = dadosEndereco.bairro
            state.departamento.cidade = dadosEndereco.cidade
            state.departamento.uf = dadosEndereco.uf
          }
        }
      })
    }

    const currentUser = JSON.parse(localStorage.getItem('userName'))
    const permissoes = JSON.parse(localStorage.getItem('permissoes')).filter(p => p.permissaoId === 2)[0]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDepartamento)

    return {
      // REFS
      ...toRefs(state),
      // FORM FUNCS
      refFormObserver,
      getValidationState,
      resetForm,
      // MISC
      avatarText,
      cleaveOptions,
      tipoDocumentoOptions,
      cleaveKey,
      buscaEndereco,
      currentUser,
      permissoes,
    }
  },
  methods: {
    retornarParaTab() {
      this.$router.push({ path: `/cadastro/clientes/editar/${this.$route.params.clienteId}`, query: { tabindex: 4 } })
    },
    salvarDepartamento() {
      this.$swal({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Deseja salvar as alterações?',
        showCancelButton: true,
        confirmButtonText: 'Sim, eu desejo',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          appLoading.show()
          const departamentoId = this.departamento.clienteDepartamentoId
          const multipart = new FormData()
          multipart.append('logoFile', this.logoFile)
          multipart.append('departamentoString', JSON.stringify(
            { 
              ...this.departamento,
              numeroDocumento: this.departamento.numeroDocumento.replaceAll(/[.,()/\s-]+/g, ''),
              cep: this.departamento.cep.replaceAll(/[.,()/\s-]+/g, ''),
            },
          ))
          this.$store.dispatch('departamentos/edit', { departamentoId, multipart }).then(() => {
            appLoading.hide()
            this.$swal({
              icon: 'success',
              title: 'Parabéns!',
              text: 'Você alterou os dados do departamento!',
              showConfirmButton: false,
              timer: 2000,
            })
          }).then(() => { this.retornarParaTab() })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>