<template>
  <b-card
    no-body
    :class="mostrarDetalhes ? 'mostrar-detalhes' : 'sem-detalhes'"
    class="mb-0 p-2"
  >
    <div
      v-if="loaded && detalhes.length === 0"
      class="loading-on"
    >
      <img
        src="../../../../assets/images/logo/icon.png"
        class="rotate-vert-center loading-logo-card"
        alt="Logo"
      >
    </div>
    <div v-else>
      <b-row>
        <b-col md="9">
          <div class="titulo">
            {{ titulo }}
          </div>
          <div
            class="subtitulo"
          >
            <div>
              {{ subtitulo }}
            </div>
            <div v-if="subtitulo === 'Clientes Ativos'">
              <feather-icon
                id="alertSubtitulo"
                icon="AlertCircleIcon"
                class="iconAlert"
              />
              <b-tooltip 
                target="alertSubtitulo"
                triggers="hover"
                title="Engloba clientes que geraram etiquetas no Portal Envios e/ou que tenham entrado de alguma forma no sistema contendo valor."
              />
            </div>
          </div>
        </b-col>
        <b-col md="3">
          <feather-icon
            class="icon"
            :icon="icone"
            size="45"
            :style="{ background: bgIconeColor }"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="mostrarDetalhes"
        class="my-2"
      >
        <table class="table table-sm mb-1">
          <thead>
            <th
              v-for="(head, index) in header"
              :key="titulo + index"
              scope="col"
              class="text-nowrap"
            >
              {{ head }}
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(detail, index) in detalhes"
              :key="titulo + index"
            >
              <td
                v-for="(head, indexHeader) in header"
                :key="titulo + indexHeader"
              >
                {{ detail[indexHeader] }}
              </td>
            </tr>
          </tbody>
        </table>
        <b-col cols="10">
          <div class="mais-detalhes">
            Minimizar detalhes
          </div>
        </b-col>
        <b-col cols="2">
          <feather-icon
            class="plus-icon"
            icon="MinusIcon"
            size="15"
            @click="switchDetalhes()"
          />
        </b-col>
      </b-row>
      <b-row
        v-else
        class="my-2"
      >
        <b-col cols="10">
          <div class="mais-detalhes">
            Mais detalhes
          </div>
        </b-col>
        <b-col cols="2">
          <feather-icon
            class="plus-icon"
            icon="PlusIcon"
            size="15"
            @click="switchDetalhes()"
          />
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'CardInfoComponent',
  props: {
    header: {
      type: Array,
      required: true,
    },
    titulo: {
      type: String,
      default: '',
    },
    subtitulo: {
      type: String,
      required: true,
    },
    icone: {
      type: String,
      required: true,
    },
    bgIconeColor: {
      type: String,
      required: true,
    },
    detalhes: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const mostrarDetalhes = ref(false)
    const switchDetalhes = () => {
      mostrarDetalhes.value = !mostrarDetalhes.value
    }

    const loaded = computed(() => props.loading)

    return {
      loaded,
      mostrarDetalhes,
      switchDetalhes,
    }
  },
}
</script>

<style scoped lang="scss">
.titulo {
  font-weight: 600;
  color: #5E5873;
  font-size: 30px;
}

.loading-on {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitulo {
  display: flex;
}

.iconAlert {
  margin-left: 5px;
}

.loading-logo-card {
  height: 80px;
}

.icon {
  padding: 10px;
  color: #fff;
  border-radius: 50px;
}

.mais-detalhes {
  color: #1f6b48;
  cursor: pointer;
}

.plus-icon {
  color: #004423;
  cursor: pointer;
}

.mostrar-detalhes {
  min-height: 100%;
}

.sem-detalhes {
  height: 12em;
}

.headers,
.detailRow {
  display: inline-flex;
  justify-content: space-between;
  flex: auto;
}

.detailRow {
  padding: 0;
}
</style>