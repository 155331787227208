import OverviewService from '@/service/OverviewService'
import PesquisaService from '@/service/PesquisaService'

export default {
  namespaced: true,
  state: {
    dados: null,
    dadosRelatorio: null,
    dadosLogin: null,
    dadosTicketMedio: null,
    dadosClientesAtivos: null,
    dadosGeracaoEtiqueta: null,
    dadosFaturamentoDia: {
      totalFaturamento: 0,
      faturamentoPorDia: [],
    },
    dadosEnvioServico: {
      series: [],
      title: [],
      labels: [],
      colors: [],
    },
    loadTicketMedio: true,
    loadClientesAtivos: true,
    loadGeracaoEtiquetas: true,
    loadFaturamentoDia: true,
    loadEnvioServico: true,
  },
  getters: {
    dados(state) {
      return state.dados
    },
    dadosRelatorio(state) {
      return state.dadosRelatorio
    },
    dadosLogin(state) {
      return state.dadosLogin
    },
    dadosTicketMedio(state) {
      return state.dadosTicketMedio
    },
    dadosClientesAtivos(state) {
      return state.dadosClientesAtivos
    },
    dadosGeracaoEtiqueta(state) {
      return state.dadosGeracaoEtiqueta
    },
    dadosFaturamentoDia(state) {
      return state.dadosFaturamentoDia
    },
    dadosEnvioServico(state) {
      return state.dadosEnvioServico
    },
    loadTicketMedio(state) {
      return state.loadTicketMedio
    },
    loadClientesAtivos(state) {
      return state.loadClientesAtivos
    },
    loadGeracaoEtiquetas(state) {
      return state.loadGeracaoEtiquetas
    },
    loadFaturamentoDia(state) {
      return state.loadFaturamentoDia
    },
    loadEnvioServico(state) {
      return state.loadEnvioServico
    },
  },
  mutations: {
    SET_DADOS(state, dados) {
      state.dados = dados
    },
    SET_DADOS_RELATORIO(state, dados) {
      state.dadosRelatorio = dados
    },
    SET_DADOS_LOGIN(state, dados) {
      state.dadosLogin = dados
    },
    SET_DADOS_CARD_TICKET_MEDIO(state, dados) {
      state.dadosTicketMedio = dados
      state.loadTicketMedio = false
    },
    SET_DADOS_CARD_CLIENTES_ATIVOS(state, dados) {
      state.dadosClientesAtivos = dados
      state.loadClientesAtivos = false
    },
    SET_DADOS_CARD_GERACAO_ETIQUETAS(state, dados) {
      state.dadosGeracaoEtiqueta = dados
      state.loadGeracaoEtiquetas = false
    },
    SET_DADOS_CARD_FATURAMENTO_DIA(state, dados) {
      state.dadosFaturamentoDia = dados
      state.loadFaturamentoDia = false
    },
    SET_DADOS_CARD_ENVIOS_SERVICO(state, dados) {
      state.dadosEnvioServico = dados
      state.loadEnvioServico = false
    },
    SET_LOAD(state, dados) {
      state.loadTicketMedio = dados
      state.loadClientesAtivos = dados
      state.loadGeracaoEtiquetas = dados
      state.loadFaturamentoDia = dados
      state.loadEnvioServico = dados
    },
  },
  actions: {
    async findAll({ commit }, obj) {
      commit('SET_DADOS', null)
      const dados = await OverviewService.pesquisa(obj)
      commit('SET_DADOS', dados)
    },
    async relatorio({ commit }, obj) {
      commit('SET_DADOS_RELATORIO', null)
      if (obj) {
        const dados = await PesquisaService.relatorio(obj)
        const { tipoRelatorio } = obj
        if (tipoRelatorio.includes('LOGIN')) {
          if (dados != null && dados.length > 0) {
            const retultTratado = []
            dados.forEach(item => {
              let foundItem = retultTratado.find(r => r.id === item.id)
              if (!foundItem) {
                foundItem = {
                  id: item.id,
                  nome: item.nome,
                  documento: item.documento,
                  totalLogin: item.totalLogin,
                  email: item.email,
                  relatorioLogin: [],
                }
                retultTratado.push(foundItem)
              }
              if (tipoRelatorio === 'LOGIN_USUARIOS_UNIDADE') {
                foundItem.relatorioLogin.push({ dataHoraLogin: item.dataHoraLogin, ip: item.ip })
              } else {
                foundItem.relatorioLogin.push({ dataHoraLogin: item.dataHoraLogin, localEntrada: item.localEntrada, ip: item.ip })
              }
            })
            commit('SET_DADOS_LOGIN', dados)
            commit('SET_DADOS_RELATORIO', retultTratado)
          } else {
            commit('SET_DADOS_LOGIN', null)
            commit('SET_DADOS_RELATORIO', null)
          }
        } else {
          commit('SET_DADOS_RELATORIO', dados)
        }
      }
    },
    limparDados({ commit }) {
      commit('SET_DADOS_CARD_TICKET_MEDIO', null)
      commit('SET_DADOS_RELATORIO', null)
      commit('SET_DADOS_CARD_CLIENTES_ATIVOS', null)
      commit('SET_DADOS_CARD_GERACAO_ETIQUETAS', null)
      commit('SET_DADOS_CARD_FATURAMENTO_DIA', {
        totalFaturamento: 0,
        faturamentoPorDia: [],
      })
      commit('SET_DADOS_CARD_ENVIOS_SERVICO', { 
        series: [],
        title: [],
        labels: [],
        colors: [],
      })
      commit('SET_LOAD', true)
    },
    async findCardTickedMedio({ commit }, filtros) {
      const dados = await OverviewService.pesquisaCardTicketMedio(filtros)
      commit('SET_DADOS_CARD_TICKET_MEDIO', dados)
    },
    async findCardClientesAtivos({ commit }, filtros) {
      const dados = await OverviewService.pesquisaCardClientesAtivos(filtros)
      commit('SET_DADOS_CARD_CLIENTES_ATIVOS', dados)
    },
    async findCardGeracaoEtiquetas({ commit }, filtros) {
      const dados = await OverviewService.pesquisaCardGeracaoEtiquetas(filtros)
      commit('SET_DADOS_CARD_GERACAO_ETIQUETAS', dados)
    },
    async findCardFaturamentoDia({ commit }, filtros) {
      const dados = await OverviewService.pesquisaCardFaturamentoDia(filtros)
      commit('SET_DADOS_CARD_FATURAMENTO_DIA', dados)
    },
    async findCardEnviosServico({ commit }, filtros) {
      const dados = await OverviewService.pesquisaCardEnviosServico(filtros)
      commit('SET_DADOS_CARD_ENVIOS_SERVICO', dados)
    },
  },
}
