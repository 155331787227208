import ClienteFtpService from '@/service/ftpService'

export default {
  namespaced: true,
  state: {
    ftpExterno: {
      ativo: false,
      ftpOrigemPortal: false,
    },
    ftpPortal: {
      ativo: false,
      ftpOrigemPortal: true,
    },
  },
  getters: {
    getFtps(state) {
      return state.ftps
    },
    getFtpsExterno(state) {
      return state.ftpExterno
    },
    getFtpsPortal(state) {
      return state.ftpPortal
    },
  },
  mutations: {
    UPDATE_FTP_PORTAL(state, ftps) {
      state.ftpPortal = ftps
    },
    UPDATE_FTP_EXTERNO(state, ftps) {
      state.ftpExterno = ftps
    },
    TOGGLE_FTP_EXTERNO(state, isActive) {
      state.ftpExterno.ativo = isActive
      if (isActive) state.ftpPortal.ativo = false
    },
    TOGGLE_FTP_PORTAL(state, isActive) {
      state.ftpPortal.ativo = isActive
      if (isActive) state.ftpExterno.ativo = false
    },
  },
  actions: {
    async findByClienteId({ commit }, clienteId) {
      const clienteFtps = await ClienteFtpService.findByClienteId(clienteId)
      if (clienteFtps) {
        clienteFtps.forEach(cf => {
          if (cf.ftpOrigemPortal) {
            commit('UPDATE_FTP_PORTAL', cf)
          } else {
            commit('UPDATE_FTP_EXTERNO', cf)
          }
        })
      }
    },
    async excluirFtp({ commit }, clienteFtp) {
      const data = await ClienteFtpService.deleteFtp(clienteFtp.id)
      if (data.success) {
        commit('UPDATE_FTP_EXTERNO', { ativo: false, ftpOrigemPortal: false })
      }
    },
    resetValues({ commit }) {
      commit('UPDATE_FTP_PORTAL', { ativo: false })
      commit('UPDATE_FTP_EXTERNO', { ativo: false, ftpOrigemPortal: false })
    },
    toggleFtpExterno({ commit }, isActive) {
      commit('TOGGLE_FTP_EXTERNO', isActive)
    },
    toggleFtpPortal({ commit }, isActive) {
      commit('TOGGLE_FTP_PORTAL', isActive)
    },
  },
}