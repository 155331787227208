import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default () => {
  const colaboradoresStore = createNamespacedHelpers('colaboradores')
  // TABLE CONFIG
  const state = reactive({
    colaboradores: colaboradoresStore.useGetters(['colaboradores']).colaboradores,
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    perPageOptions: [10, 25, 50, 100],
  })

  watch(() => state.colaboradores, value => {
    if (value && value.length) {
      state.totalRows = value.length
    } else {
      state.totalRows = 0
    }
  })

  const search = ref('')
  const sortBy = ref('nome')
  const isSortDirDesc = ref(false)
  const tableColumns = [
    { key: 'nome', sortable: true },
    { key: 'email', sortable: true },
    { key: 'cpf', sortable: true },
    { key: 'ativo', sortable: true },
    { key: 'ações', sortable: false },
  ]

  const onFiltered = filteredItems => {
    state.currentPage = 1
    state.totalRows = filteredItems.length
  }

  const colaboradorAddSidebarActive = ref(false)

  return {
    ...toRefs(state),
    // TABLE CONFIG
    isSortDirDesc,
    search,
    sortBy,
    tableColumns,
    // PAGE CONFIG
    colaboradorAddSidebarActive,
    //
    onFiltered,
  }
}