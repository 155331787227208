<template>
  <div>
    <b-form-row 
      class="align-items-center mb-2"
    >
      <b-col
        md="4"
      > 
        <validation-provider
          #default="validationContext"
          name="Url"
          :rules="{ required: data.ativo }"
        >
          <b-form-group
            label="Caminho FTP (url)"
            label-for="clienteFtpUrl"
          >
            <b-form-input
              id="Url"
              v-model="data.url"
              :state="getValidationState(validationContext)"
              :disabled="data.ftpOrigemPortal"
              type="text"
              trim
              placeholder="Url FTP"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
      <b-col
        md="2"
      > 
        <validation-provider
          #default="validationContext"
          name="portaFtp"
          :rules="{ required: data.ativo }"
        >
          <b-form-group
            label="Porta FTP"
            label-for="portaFtp"
          >
            <b-form-input
              id="portaFtp"
              v-model="data.porta"
              :state="getValidationState(validationContext)"
              :disabled="data.ftpOrigemPortal"
              type="text"
              trim
              placeholder="Porta FTP"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
      <b-col
        md="2"
      > 
        <validation-provider
          #default="validationContext"
          name="clienteFtpLogIn"
          :rules="{ required: data.ativo }"
        >
          <b-form-group
            label="Login FTP"
            label-for="clienteFtpLogIn"
          >
            <b-form-input
              id="clienteFtpLogIn"
              v-model="data.login"
              :state="getValidationState(validationContext)"
              :disabled="data.ftpOrigemPortal"
              type="text"
              trim
              placeholder="Login FTP"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
      <b-col
        md="2"
      > 
        <validation-provider
          #default="validationContext"
          name="clienteFtpSenha"
          :rules="{ required: data.ativo }"
        >
          <b-form-group
            label="Senha FTP"
            label-for="clienteFtpSenha"
          >
            <b-form-input
              id="clienteFtpSenha"
              v-model="data.senha"
              :state="getValidationState(validationContext)"
              :disabled="data.ftpOrigemPortal"
              type="text"
              trim
              placeholder="Senha FTP"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
      <b-col
        md="2"
      >
        <validation-provider
          #default="validationContext"
          name="protocoloFtp"
          :rules="{ required: data.ativo }"
        >
          <b-form-group
            label="Protocolo Ftp"
            label-for="protocoloFtp"
          >
            <v-select
              id="protocoloFtp"
              v-model="data.protocoloFtp"
              :state="getValidationState(validationContext)"
              :disabled="data.ftpOrigemPortal"
              :reduce="val=>val.value"
              :class="validationContext.errors[0] ? 'border-danger' : ''"
              :options="protocoloFtp"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
      <b-col
        md="2"
      > 
        <validation-provider
          #default="validationContext"
          name="clienteArquivoInicial"
        >
          <b-form-group
            label="Nome inicial arquivos(opcional)"
            label-for="clienteArquivoInicial"
          >
            <b-form-input
              id="clienteArquivoInicial"
              v-model="data.inicioArquivo"
              :state="getValidationState(validationContext)"
              :disabled="data.ftpOrigemPortal"
              type="text"
              trim
              placeholder="EX: SCC4_"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
      <b-col
        md="1"
        class="pt-1"
      >
        <b-form-checkbox 
          v-model="data.ativo"
          name="check-button"
          size
          switch
          :disabled="ativarBotao"
        >
          <label class="pl-1 label-switch">Ativar</label>
        </b-form-checkbox>
      </b-col>
      <b-col 
        v-if="!data.ftpOrigemPortal"
        md="1"
        class="d-flex justify-content-center"
      >
        <feather-icon
          :id="`alert-delete-${index}`"
          icon="TrashIcon"
          :class="data.id ? '' : 'disable-trash'"
          cursor="pointer"
          size="22"
          @click="excluirFtp"
        />
        <b-tooltip
          v-if="data.id"
          :target="`alert-delete-${index}`"
          triggers="hover"
          title="Excluir dados do FTP."
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  name: 'FtpData',
  props: {
    data: {
      required: true,
      type: Object,
    },
    unico: {
      required: true,
      type: Boolean,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      protocoloFtp: [
        { label: 'SFTP', value: 'SFTP' },
        { label: 'FTP', value: 'FTP' },
      ],
      desativado: true,
    }
  },
  computed: {
    ativarBotao() {
      if (this.data.url && this.data.porta && this.data.login && this.data.senha && this.data.protocoloFtp) {
        return false
      } return true
    },
  },
  methods: {
    excluirFtp() {
      if (this.data.id) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja excluir os dados?',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim',
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$store.dispatch('clienteFtp/excluirFtp', this.data)
          }
        })
      }
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()
    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
<style scoped>
.disable-trash {
  opacity: 20%;
  cursor: default;
}
.label-switch {
  padding-top: 2px;
  margin-bottom: 0px;
}
</style>