<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetFormMovimento"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Inserir uma Importação
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          ref="formMovimento"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name="Período"
            rules="required"
          >
            <FormDatepicker
              id="periodo"
              ref="refdate"
              v-model="movimento.dataSelecionada"
              label="Período"
              :date-disabled-list="datasJaSelecionadas"
              :get-validation-state="getValidationState"
              :required="true"
              :max="maxDate"
              @update="(novaData) => movimento.dataSelecionada = novaData"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Arquivo"
            rules="required|size:4000"
          >
            <b-form-group
              label="Arquivo"
              label-for="arquivoImportacao"
            >
              <b-form-file
                ref="fileInput"
                v-model="arquivoImportacao"
                placeholder="Selecione um arquivo"
                drop-placeholder="Solte o arquivo aqui"
                accept=".xlsx,.zip"
                :state="getValidationState(validationContext)"
                @change="previewFiles"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <p
                v-if="errors.length"
                class="erroColuna my-1"
              >
                Erro: {{ errors[0] }}<span v-if="errors[1]"> , e mais {{ errors.length - 1 }}</span>.
              </p>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="errors.length > 0"
            >
              Importar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import formValidation from '@core/comp-functions/forms/form-validation'
import _ from 'lodash'
import { reactive, ref, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import FormDatepicker from '@/components/form/FormDatepicker.vue'
import { $themeConfig } from '@themeConfig'
import XLSX from 'xlsx'
import JSZip from 'jszip'

export default {
  name: 'ImportarMovimentoSidebar',
  components: { FormDatepicker },
  emits: ['atualizaLista'],
  props:
    {
      active: {
        type: Boolean,
        required: true,
      },
      datasJaSelecionadas: {
        type: Array,
        default: Array,
        required: false,
      },
    },
  data() {
    return {
      errors: [],
    }
  },
  methods: {
    // previewFiles(event) {
    //   const appLoading = $themeConfig.app.appLoadingImage
    //   appLoading.show()
    //   // eslint-disable-next-line prefer-destructuring
    //   this.arquivoImportacao = event.target.files[0]
    //   const fileReader = new FileReader()
    //   fileReader.readAsArrayBuffer(this.arquivoImportacao)
    //   fileReader.onload = e => {
    //     const bufferArray = e.target.result
    //     const wb = XLSX.read(bufferArray, { type: 'buffer' })
    //     const wsname = wb.SheetNames[0]
    //     const ws = wb.Sheets[wsname]
    //     this.validateMissingHeaders(ws)
    //     appLoading.hide()
    //   }
    // },
    previewFiles(event) {
      const appLoading = $themeConfig.app.appLoadingImage
      appLoading.show()
      // eslint-disable-next-line prefer-destructuring
      this.arquivoImportacao = event.target.files[0]
      const fileExtension = this.arquivoImportacao.name.split('.').pop().toLowerCase()

      if (fileExtension === 'zip') {
        this.handleZipFile(this.arquivoImportacao, appLoading)
      } else if (['xlsx', 'xls'].includes(fileExtension)) {
        this.handleExcelFile(this.arquivoImportacao, appLoading)
      } else {
        this.errors.push('Formato de arquivo não suportado.')
        appLoading.hide()
      }
    },
    handleZipFile(file, appLoading) {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)
      fileReader.onload = async e => {
        const zip = new JSZip()
        const zipContent = await zip.loadAsync(e.target.result)
        // eslint-disable-next-line no-restricted-syntax
        for (const fileName in zipContent.files) {
          if (zipContent.files[fileName].name.endsWith('.xlsx')) {
            // eslint-disable-next-line no-await-in-loop
            const content = await zipContent.files[fileName].async('arraybuffer')
            this.processExcelFile(content)
          }
        }
        appLoading.hide()
      }
    },
    handleExcelFile(file, appLoading) {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)
      fileReader.onload = e => {
        this.processExcelFile(e.target.result)
        appLoading.hide()
      }
    },
    processExcelFile(bufferArray) {
      const wb = XLSX.read(bufferArray, { type: 'buffer' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      this.validateMissingHeaders(ws)
    },
    resetFormMovimento() {
      this.errors = []
      this.$refs.formMovimento.reset()
      this.$refs.refdate.clear()
    },
    validateMissingHeaders(sheet) {
      const HEADERS_MAP = new Map()
      HEADERS_MAP.set(0, 'Codigo')
      HEADERS_MAP.set(1, 'Nota Fiscal')
      HEADERS_MAP.set(2, 'Pedido')
      HEADERS_MAP.set(3, 'Cliente')
      HEADERS_MAP.set(4, 'CNPJ/CPF Remetente')
      HEADERS_MAP.set(5, 'Cep Remetente')
      HEADERS_MAP.set(6, 'Destino')
      HEADERS_MAP.set(7, 'Cidade')
      HEADERS_MAP.set(8, 'Bairro')
      HEADERS_MAP.set(9, 'UF')
      HEADERS_MAP.set(10, 'Destinatario')
      HEADERS_MAP.set(11, 'CNPJ/CPF Destinatario')
      HEADERS_MAP.set(12, 'Cep Destinatario')
      HEADERS_MAP.set(13, 'TP')
      HEADERS_MAP.set(14, 'Status')
      HEADERS_MAP.set(15, 'Dt Emissao')
      HEADERS_MAP.set(16, 'Dt Evento')
      HEADERS_MAP.set(17, 'Previsao')
      HEADERS_MAP.set(18, 'Descricao')
      HEADERS_MAP.set(19, 'Recebedor')
      HEADERS_MAP.set(20, 'Doc. Recebedor')
      HEADERS_MAP.set(21, 'Tratativa')
      HEADERS_MAP.set(22, 'CTe Inicial')
      HEADERS_MAP.set(23, 'Valor Declarado')
      HEADERS_MAP.set(24, 'Codigo do Operador')
      HEADERS_MAP.set(25, 'Peso')
      HEADERS_MAP.set(26, 'Volume')
      HEADERS_MAP.set(27, 'Modalidade')
      HEADERS_MAP.set(28, 'Dt Entrega')
      HEADERS_MAP.set(29, 'Hist. ultimo status')
      HEADERS_MAP.set(30, 'Hist. ultima data')
      HEADERS_MAP.set(31, 'Hist. ultimo ponto')
      HEADERS_MAP.set(32, 'Hist. ultima uf')
      HEADERS_MAP.set(33, 'Hist. ultimo operador')
      HEADERS_MAP.set(34, 'Hist. ultimo oper matric')
      HEADERS_MAP.set(35, 'Hist. ultima descricao')
      HEADERS_MAP.set(36, 'Tem reversa')
      HEADERS_MAP.set(37, 'Cte gerado reversa')
      HEADERS_MAP.set(38, 'Tipo Entrega')
      HEADERS_MAP.set(39, 'ShipmentId')
      HEADERS_MAP.set(40, 'Rota')
      HEADERS_MAP.set(41, 'Pudo Destino')
      HEADERS_MAP.set(42, 'IdEmbarcador')

      this.errors = []
      HEADERS_MAP.forEach((value, index) => {
        const cell = sheet[XLSX.utils.encode_cell({ c: index, r: 1 })]
        let header = ''
        const errorMessage = `Coluna ${value} não encontrada na célula A${index + 1}`
        if (cell && cell.t) {
          header = XLSX.utils.format_cell(cell)
          if (value !== header) {
            this.errors.push(errorMessage)
          }
        } else {
          this.errors.push(errorMessage)
        }
      })
    },
  },
  setup(props, { emit }) {
    const servicosStore = createNamespacedHelpers('servicos')
    const movimentosStore = createNamespacedHelpers('movimentos')
    const servicosOptions = ref(servicosStore.useGetters(['servicosOptions']).servicosOptions)

    const { save } = movimentosStore.useActions(['save'])

    const user = JSON.parse(localStorage.getItem('userName'))
     
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    const blankMovimento = {
      usuarioId: user.usuarioId,
      usuarioNome: user.nome,
      dataSelecionada: null,
      quantidadeObjetos: null,
      metodoImportacao: 'MANUAL',
    }

    const state = reactive({
      maxDate: new Date(today),
      movimento: blankMovimento,
      arquivoImportacao: null,
    })

    const createMultipart = () => {
      const multipart = new FormData()
      multipart.append('file', state.arquivoImportacao)
      multipart.append('movimento', JSON.stringify(state.movimento))
      return multipart
    }

    const appLoading = $themeConfig.app.appLoadingImage

    const tratamentoMensagemErro = mensagem => {
      let resultado = mensagem
      resultado = mensagem.replace('java.io.IOException:', '')
      resultado = resultado.split('.')
      return resultado
    }

    const success = message => {
      appLoading.hide()
      const total = message.quantidadeTotalImportada
      const importados = message.quantidadeModificada
      if (total === importados) {
        // temp
        if (message.valoresDuplicados.includes('TEMP')) {
          swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: `Importados ${total} movimentos com sucesso! <br> ${message.valoresDuplicados}`,
            showConfirmButton: true,
          })
        } else {
        // temp
          swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: `Importados ${total} movimentos com sucesso!`,
            showConfirmButton: true,
          })
        }
      } else {
        let mensagemDuplicada = 'ShipmentId duplicado: <br>'
        if (message.valoresDuplicados.split(',').length > 1) {
          mensagemDuplicada = 'ShipmentId duplicados: <br>'
        }
        swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          html: `Importados ${importados} de ${total} movimentos<br> ${mensagemDuplicada}${message.valoresDuplicados}`,
          showConfirmButton: true,
        })
      }
    }

    const error = mensagem => {
      appLoading.hide()
      if (mensagem.includes('java.io.IOException:')) {
        const resultado = tratamentoMensagemErro(mensagem)
        swal.fire({
          icon: 'error',
          title: 'Erro!',
          html: `${resultado[1] ? resultado[1] : resultado}`,
          showConfirmButton: true,
        })
      } else {
        swal.fire({
          icon: 'error',
          title: 'Erro!',
          html: `${mensagem}`,
          showConfirmButton: true,
        })
      }
    }

    const onSubmit = () => {
      appLoading.show()
      const multipart = createMultipart()
      save(multipart)
        .then(message => {
          emit('update:active', false)
          emit('atualizaLista')
          success(message)
        })
        .catch(msg => {
          error(msg)
        })
    }

    const resetMovimento = () => {
      state.movimento = _.cloneDeep(blankMovimento)
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetMovimento)

    return {
      ...toRefs(state),
      servicosOptions,
      // FORM CONFIGS
      refFormObserver,
      getValidationState,
      resetForm,
      // METHODS
      onSubmit,
      resetMovimento,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.erroColuna {
  color:red;
}
</style>