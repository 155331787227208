import HttpClient from './http-clients/portaljad'

const baseUrl = 'menu-unidade'

export default class MenuService {
  static async findAllUnidade() {
    try {
      const { data } = await HttpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}
