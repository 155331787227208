<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-media
            class="my-2"
          >
            <template #aside>
              <div v-if="cliente.urlLogo">
                <img
                  :src="cliente.urlLogo"
                  alt="logo"
                  class="logoCliente"
                >
              </div>
              <b-avatar
                v-else
                ref="previewEl"
                :text="avatarText(cliente.nomeFantasia)"
                variant="light-success"
                size="90px"
                rounded
              />
            </template>
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ cliente.nomeFantasia }}
                </h4>
                <span class="card-text">{{ cliente.email }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  v-if="permissoes.editar"
                  :to="{ name: 'clientes-editar', params: { ...cliente.clienteId } }"
                  variant="primary"
                >
                  Editar
                </b-button>
              </div>
            </div>
          </b-media></div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <!--Faturamento e Informações Marginais -->
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Razão Social</span>
            </th>
            <td class="pb-50">
              {{ cliente.razaoSocial }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Nome Fantasia</span>
            </th>
            <td class="pb-50">
              {{ cliente.nomeFantasia }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Documento</span>
            </th>
            <td class="pb-50">
              {{ maskDocumento(cliente.numeroDocumento) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Ativo</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ cliente.ativo ? 'Sim' : 'Não' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Estado</span>
            </th>
            <td class="pb-50">
              {{ cliente.uf }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Telefone</span>
            </th>
            <td>
              {{ maskPhone(cliente.telefone) }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import utils from '@/helpers/utils'

export default {
  props: {
    cliente: {
      type: Object,
      required: true,
    },
  },
  methods: {
    maskDocumento(value) {
      if (this.cliente.tipoDocumento === 'CPF') {
        return utils.maskCpf(value)
      }
      return utils.maskCnpj(value)
    },
  },
  setup() {
    const permissoes = JSON.parse(localStorage.getItem('permissoes')).filter(p => p.permissaoId === 1)[0]
    return { avatarText, ...utils, permissoes }
  },
}
</script>

<style>
.logoCliente {
  max-width: 150px;
  max-height: 150px;
}
</style>