<template>
  <div>
    <b-card
      no-body
      class="mt-2 mb-0 p-2"
    >
      <b-row
        align-h="between"
        align-v="center"
        class="mr-1"
      >
        <b-col md="6">
          <b-media class="mb-2">
            <template #aside>
              <b-avatar
                ref="previewEl"
                :text="usuarioSelecionado.email.slice(0, 2)"
                variant="light-success"
                size="90px"
                rounded
              />
            </template>
            <h4 class="mb-2">
              {{ usuarioSelecionado.email }}
            </h4>
            <b-button
              variant="primary"
              @click="$emit('editarUsuario')"
            >
              Editar
            </b-button>
          </b-media>
        </b-col>
        <b-col
          md="4"
          class="resumo-container rounded py-5 px-2"
          align-self="stretch"
        >
          <div
            class="resumo"
            style="margin:auto;"
          >
            <b-row>
              <b-col class="chave">
                E-mail
              </b-col>
              <b-col>
                {{ usuarioSelecionado.email }}
              </b-col>
            </b-row>
            <b-row>
              <b-col class="chave">
                Nível
              </b-col>
              <b-col>
                {{ usuarioSelecionado['nivel'] }}
              </b-col>
            </b-row>
          </div>          
        </b-col>
      </b-row>
    </b-card>

    <b-card
      no-body
      class="mt-3"
    >
      <b-table
        v-if="usuarioSelecionado.departamentos.length>0"
        :items="usuarioSelecionado.departamentos"
        :fields="tableColumns"
        responsive
        stacked="sm"
      />
      <hr class="mb-0 mt-3">
      <b-form class="pb-2">
        <b-button
          variant="primary"
          class="my-1 mb-sm-0 mr-0 mr-sm-1 m-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$emit('fechar')"
        >
          Voltar
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'DetalhesUsuario',
  props: {
    usuarioSelecionado: {
      type: undefined,
      required: true,
    },
  },
  setup() {
    const tableColumns = [
      { key: 'departamentoId', sortable: true, label: 'JAD CODE' },
      { key: 'codigoReferencia', sortable: true, label: 'COD.REFERÊNCIA' },
      { key: 'nomeDepartamento', sortable: false, label: 'DEPARTAMENTO' },
    ]
    
    return {
      // TABLE
      tableColumns,
    }
  },
}
</script>

<style scoped>
.resumo-container {
  display:flex;
  background-color: #FAFAFA;
  border: 1px solid #BEC6E2;
  color:black;
}

.resumo {
  margin: auto 0;
  flex: 1;
}

.chave {
  font-weight: bold;
}
</style>