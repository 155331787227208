<template>
  <div>
    <importar-taxas-sidebar
      :active.sync="addTaxaSidebarActive"
      @aoSalvar="atualizaLista"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>taxas por página</label>
          </b-col>
          <b-col 
            cols="12"
            md="3"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="addTaxaSidebarActive = true"
            >
              <span class="text-nowrap">Cadastrar Taxa</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="tableRef"
        class="position-relative"
        :items="taxas"
        :fields="tableColumns"
        :filter="search"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="tarifaId"
        :sort-by.sync="sortBy"
        show-empty
        stacked="md"
        empty-text="Nenhuma taxa cadastrada"
        :sort-desc.sync="isSortDirDesc"
        empty-filtered-text="Nenhum resultado encontrado com os filtros informados."
        @filtered="onFiltered"
      >
        <template #cell(dataCriacao)="data">
          <span>{{ data.item.dataCriacao ? maskDateTime(data.item.dataCriacao) : '' }}</span>
        </template>
        <template #cell(ativo)="data">
          <b-badge
            v-if="data.item.ativo"
            variant="success"
          >
            SIM
          </b-badge>
          <b-badge
            v-else
            variant="light-primary"
          >
            NÃO
          </b-badge>
        </template>
        <template #cell(ações)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            
            <b-dropdown-item :to="{ name: 'taxas-editar', params: { taxaId: data.item.id} }">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="m-2">
        <b-row v-if="totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import utils from '@/helpers/utils'
import ImportarTaxasSidebar from '../components/importar-taxas-sidebar'
import taxasComposition from '../taxas.composition'

export default {
  name: 'IndexVue',
  components: { ImportarTaxasSidebar },
  computed: {
    ...mapGetters({
      firebaseUser: 'firebase/firebaseUser',
    }),
  },

  watch: {
    firebaseUser() {
      this.atualizaLista()
    },
  },
  beforeMount() {
    this.atualizaLista()
  },
  setup() {
    return {
      ...taxasComposition(),
      ...utils,
    }
  },
  methods: {
    atualizaLista() {
      this.$store.dispatch('taxas/findAll')
    },
  },
}
</script>

<style scoped>

</style>