import TaxasService from '@/service/TaxasService'

export default {
  namespaced: true,
  state: {
    taxas: [],
    selected: {},
    taxasOptions: [],
    tipoTaxa: [
      'VOLUME',
      'PESO',
    ],
  },
  getters: {
    taxas(state) {
      return state.taxas
    },
    selected(state) {
      return state.selected
    },
    taxasOptions(state) {
      return state.taxasOptions
    },
  },
  mutations: {
    UPDATE_TAXAS(state, taxas) {
      state.taxas = taxas
    },
    UPDATE_SELECTED(state, selected) {
      state.selected = selected
    },
    SAVE_TAXAS(state, taxa) {
      state.taxas.push(taxa)
    },
    EDIT_TAXA(state, taxa) {
      const index = state.taxas.findIndex(t => t.id === taxa.id)
      Object.assign(state.taxas[index], taxa)
    },
    DELETE_TAXA(state, taxaId) {
      const index = state.taxas.findIndex(t => t.id === taxaId)
      state.taxas.splice(index, 1)
    },
    UPDATE_TAXA_OPTIONS(state, taxasOptions) {
      state.taxasOptions = taxasOptions
    },
  },
  actions: {
    async findAll({ commit }) {
      const taxas = await TaxasService.findAll()
      commit('UPDATE_TAXAS', taxas)
      return taxas
    },
    async findById({ commit }, taxaId) {
      const taxa = await TaxasService.findById(taxaId)
      commit('UPDATE_SELECTED', taxa)
    },
    async save(_, taxa) {
      const data = await TaxasService.save(taxa)
      return data
    },
    async edit({ commit }, { taxaId, taxa }) {
      const data = await TaxasService.edit(taxaId, taxa)
      if (data.success) {
        commit('EDIT_TAXA', data.body)
      }
      return data
    },
    async deleteById({ commit }, taxaId) {
      await TaxasService.deleteById(taxaId)
      commit('DELETE_TAXA', taxaId)
    },
    setOptions({ getters, commit }) {
      const taxasAtivas = getters.taxas.filter(t => t.ativo)
      const options = taxasAtivas.map(taxa => ({
        label: taxa.nomeTaxa,
        text: taxa.nomeTaxa,
        value: taxa.id,
        tipoTaxa: taxa.tipoTaxa,
      }))
      commit('UPDATE_TAXA_OPTIONS', options)
    },
  },
}