import HttpClient from './http-clients/portaljad'

const baseUrl = 'monitor-jadlog'

export default class MonitorJadlogService {
  static async findAll() {
    try {
      const { data } = await HttpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findForExcel(filtros) {
    try {
      const { data } = await HttpClient.post(baseUrl, filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}
