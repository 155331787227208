<template>
  <component :is="colaborador ? 'b-card' : 'div'">
    <b-card v-if="colaborador">
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :text="avatarText(colaborador.nome)"
            variant="light-success"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{ colaborador.nome }}
        </h4>
        <h5 class="mb-1">
          {{ colaborador.login }}
        </h5>
      </b-media>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="Nome"
                :rules="{ required: true }"
              >
                <b-form-group
                  label="Nome"
                  label-for="nome"
                >
                  <b-form-input
                    id="nome"
                    v-model="colaborador.nome"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="João da Silva"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="CPF"
              >
                <b-form-group
                  label="CPF"
                  label-for="cpf"
                >
                  <b-form-input
                    id="cpf"
                    v-model.lazy="colaborador.cpf"
                    v-cleave.lazy="cleaveOptions.cpf"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="000.000.000-00"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="Perfil"
                :rules="{ required: true }"
              >
                <b-form-group
                  label="Perfil"
                  label-for="perfil"
                >
                  <v-select
                    v-model="colaborador.perfilId"
                    :reduce="val=>val.value"
                    :options="perfilOptions"
                    :clearable="false"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="Email"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="colaborador.email"
                    disabled
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="colaborador@dominio.com"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="Usuário Fraction"
                :rules="{ required: false }"
              >
                <b-form-group
                  label="Usuário Fraction"
                  label-for="login-fraction"
                >
                  <b-form-input
                    id="login-fraction"
                    v-model="colaborador.loginFraction"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="Senha Fraction"
                :rules="{ required: false }"
              >
                <b-form-group
                  label="Senha Fraction"
                  label-for="senha-fraction"
                >
                  <b-button
                    v-if="!novaSenha"
                    variant="outline-primary"
                    @click="ajustarNovaSenha()"
                  >
                    <span class="mr-25 align-middle">Alterar Senha</span>
                  </b-button>
                  <b-form-input
                    v-if="novaSenha"
                    id="senha-fraction"
                    v-model="senhaFraction"
                    :state="getValidationState(validationContext)"
                    trim
                    type="password"
                    @focus="limpaSenha"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-button
        variant="primary"
        class="mb-1 mt-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="salvarColaborador()"
      >
        Salvar Mudanças
        <feather-icon
          icon="SaveIcon"
          class="ml-1"
        />
      </b-button>
      <b-button
        variant="outline-secondary"
        class="mr-sm-1 mr-0 mb-1 mt-1 mb-sm-0"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$router.go(-1)"
      >
        Cancelar Alterações
        <feather-icon
          icon="RefreshCwIcon"
          class="ml-1"
        />
      </b-button>
      <b-button
        v-if="colaborador.firebaseUid != currentUser.firebaseUid && permissoes.excluir"
        variant="outline-secondary"
        class="mt-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="deleteUser(colaborador)"
      >
        Deletar
        <feather-icon
          icon="TrashIcon"
          class="ml-1"
        />
      </b-button>
    </b-card>
  </component>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { reactive, toRefs, watch } from '@vue/composition-api'
import cleaveOptions from '@/libs/cleave/options'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'

const colaboradoresStore = createNamespacedHelpers('colaboradores')
const perfisStore = createNamespacedHelpers('perfis')

const appLoading = $themeConfig.app.appLoadingImage

export default {
  name: 'ColaboradoresEditar',
  beforeMount() {
    const { empresaId } = JSON.parse(localStorage.getItem('userName'))
    this.$store.dispatch('perfis/findAllComPermissao', empresaId).then(() => {
      this.$store.dispatch('perfis/setOptions')
    })
    this.$store.dispatch('colaboradores/findById', this.$route.params.usuarioId)
  },
  methods: {
    deleteUser(colaborador) {
      this.$swal({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Você tem certeza que quer deletar esse usuário?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não, calma aí.',
        confirmButtonText: 'Sim, eu tenho!',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          appLoading.show()
          this.$store.dispatch('colaboradores/delete', colaborador.usuarioId).then(() => {
            appLoading.hide()
            this.$swal({
              icon: 'success',
              html:
                '<h1>Deletado com sucesso!</h1><br/><span style="font-size: 8px">Espero que você saiba o que está fazendo...</span>',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }).then(() => {
            this.$router.push('/cadastro/usuarios')
          })
        }
      })
    },
    salvarColaborador() {
      this.$swal({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Deseja salvar as alterações?',
        showCancelButton: true,
        confirmButtonText: 'Sim, eu desejo',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const colaboradorToSave = {
            ...this.colaborador,
            senhaFraction: (this.senhaFraction && this.senhaFraction !== this.colaborador.senhaFraction ? this.senhaFraction : null),
          }
          this.$store.dispatch('colaboradores/edit', {
            usuarioId: this.colaborador.usuarioId,
            colaborador: colaboradorToSave,
          }).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Parabéns!',
              text: 'Você alterou os dados do usuário!',
              showConfirmButton: false,
              timer: 2000,
            })
          }).then(() => {
            this.$router.push('/cadastro/usuarios')
          })
        }
      })
    },
  },
  setup() {
    const { findById } = colaboradoresStore.useActions(['findById'])

    const state = reactive({
      colaborador: colaboradoresStore.useGetters(['selected']).selected,
      perfilOptions: perfisStore.useGetters(['perfilOptions']).perfilOptions,
      senhaFraction: null,
      novaSenha: false,
    })

    watch(() => state.colaborador, value => {
      state.senhaFraction = value.senhaFraction
    })

    const resetColaborador = () => {
      findById(state.colaborador.usuarioId)
    }

    const currentUser = JSON.parse(localStorage.getItem('userName'))
    const permissoes = JSON.parse(localStorage.getItem('permissoes')).filter(p => p.permissaoId === 2)[0]

    const limpaSenha = () => {
      state.senhaFraction = null
    }

    const ajustarNovaSenha = () => {
      swal.fire({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Deseja alterar a senha do usuário Fraction?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, eu desejo',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          state.novaSenha = true
          state.senhaFraction = null
        } else {
          state.novaSenha = false
        }
      })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetColaborador)
    return {
      // REFS
      ...toRefs(state),
      // FORM FUNCS
      refFormObserver,
      getValidationState,
      // MISC
      avatarText,
      cleaveOptions,
      currentUser,
      permissoes,
      limpaSenha,
      ajustarNovaSenha,
    }
  },
}
</script>

<style scoped>

</style>