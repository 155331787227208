import UsuariosService from '@/service/UsuariosService'
import adapter from '@/adapters/UsuarioAdapter'

export default {
  namespaced: true,
  state: {
    usuarios: null,
    selected: null,
  },
  getters: {
    usuarios(state) {
      return state.usuarios
    },
    selected(state) {
      return state.selected
    },
  },
  mutations: {
    UPDATE_USUARIOS(state, usuarios) {
      state.usuarios = usuarios
    },
    UPDATE_SELECTED(state, usuario) {
      state.selected = usuario
    },
    SAVE_USUARIO(state, usuario) {
      state.usuarios.push(usuario)
    },
    EDIT_USUARIO(state, usuario) {
      if (state.usuarios) {
        const index = state.usuarios.findIndex(item => item.usuarioId === usuario.usuarioId)
        Object.assign(state.usuarios[index], usuario)
      } else {
        state.selected = usuario
      }
    },
    DELETE_USUARIO(state, usuarioId) {
      if (state.usuarios) {
        const index = state.usuarios.findIndex(item => item.usuarioId === usuarioId)
        state.usuarios.splice(index, 1)
      }
    },
  },
  actions: {
    async findAll({ commit }, empresaId) {
      const usuarios = await UsuariosService.findAll(empresaId)
      commit('UPDATE_USUARIOS', usuarios)
      return usuarios
    },
    async findAllByCliente({ commit }, clienteId) {
      const usuarios = await UsuariosService.findAllByCliente(clienteId)
      commit('UPDATE_USUARIOS', usuarios)
      return usuarios
    },
    async findById({ commit }, usuarioId) {
      const usuario = await UsuariosService.findById(usuarioId)
      commit('UPDATE_SELECTED', usuario)
      return usuario
    },
    async save({ commit }, usuario) {
      const usuarioToSave = adapter.toApi(usuario)
      const usuarioId = await UsuariosService.save(usuarioToSave)
      usuarioToSave.usuarioId = usuarioId
      commit('SAVE_USUARIO', usuarioToSave)
    },
    async edit({ commit }, { usuarioId, usuario }) {
      const edited = await UsuariosService.edit(usuarioId, adapter.toApi(usuario))
      commit('EDIT_USUARIO', edited)
    },
    async acessoPrincipal(_, dados) {
      await UsuariosService.acessoPrincipal(dados)
    },
    async delete({ commit }, usuarioId) {
      await UsuariosService.deleteById(usuarioId)
      commit('DELETE_USUARIO', usuarioId)
    },
  },
}