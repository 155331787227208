export default {
  phone: {
    delimiters: ['(', ') ', '-'],
    blocks: [0, 2, 5, 4],
  },
  cnpj: {
    delimiters: ['.', '.', '/', '-'],
    blocks: [2, 3, 3, 4, 2],
  },
  cpf: {
    delimiters: ['.', '.', '-'],
    blocks: [3, 3, 3, 2],
  },
  cep: {
    delimiters: ['-'],
    blocks: [5, 3],
  },
  decimal: {
    numeral: true,
    numeralDecimalMark: ',',
    delimiter: '.',
  },
  contaCorrente: {
    delimiters: ['-'],
    blocks: [6, 1],
  },
}