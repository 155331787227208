<template>
  <div>
    <add-colaborador-sidebar :active.sync="colaboradorAddSidebarActive"/>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>usuários por página</label>
          </b-col>
          <b-col 
            cols="12"
            md="3"
            class="d-flex justify-content-end"
          >
            <b-button
              v-if="permissoes.editar"
              variant="primary"
              @click="colaboradorAddSidebarActive = true"
            >
              <span class="text-nowrap">Adicionar Usuário</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="tableRef"
        class="position-relative"
        :items="colaboradores"
        :fields="tableColumns"
        :filter="search"
        primary-key="usuarioId"
        :sort-by.sync="sortBy"
        show-empty
        stacked="md"
        empty-text="Nenhum registro encontrado"
        empty-filtered-text="Busca sem resultados"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page="currentPage"
        @filtered="onFiltered"
      >
        <template #cell(cpf)="data">
          <span>{{ data.item.cpf ? maskCpf(data.item.cpf) : '' }}</span>
        </template>
        <template #cell(ativo)="data">
          <b-badge
            v-if="data.item.ativo"
            variant="success"
          >
            SIM
          </b-badge>
          <b-badge
            v-else
            variant="light-primary"
          >
            NÃO
          </b-badge>
        </template>
        <template 
          v-if="permissoes.editar || permissoes.visualizar || permissoes.excluir"
          #cell(ações)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item 
              v-if="permissoes.visualizar"
              :to="{ name: 'usuarios-visualizar', params: { usuarioId: data.item.usuarioId} }"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">Detalhes</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="permissoes.editar"
              :to="{ name: 'usuarios-editar', params: {usuarioId: data.item.usuarioId}}"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="data.item.firebaseUid != firebaseUser.uid && permissoes.excluir" 
              @click="deleteUser(data.item)"
            >
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Deletar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template 
          v-else
          #cell(ações)=""
        >
          <span title="Nenhuma ação permitida">
            <feather-icon icon="SlashIcon"/>
          </span>
        </template>
      </b-table>
      <div class="m-2">
        <b-row v-if="totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { $themeConfig } from '@themeConfig'
import ColaboradoresComposition from '../colaboradores.composition'
import AddColaboradorSidebar from '../components/add-colaborador-sidebar'

export default {
  name: 'IndexVue',
  components: { AddColaboradorSidebar },
  data() {
    return {
      appLoading: $themeConfig.app.appLoadingImage,
    }
  },
  computed: {
    ...mapGetters({
      firebaseUser: 'firebase/firebaseUser',
    }),
  },

  watch: {
    firebaseUser() {
      const { empresaId } = JSON.parse(localStorage.getItem('userName'))
      this.$store.dispatch('colaboradores/findAll', empresaId)
    },
  },
  beforeMount() {
    const { empresaId } = JSON.parse(localStorage.getItem('userName'))
    this.$store.dispatch('colaboradores/findAll', empresaId)
  },
  methods: {
    maskCpf(raw) {
      const rawParts = [raw.substring(0, 3), raw.substring(3, 6), raw.substring(6, 9), raw.substring(9, 11)]
      return `${rawParts[0]}.${rawParts[1]}.${rawParts[2]}-${rawParts[3]}`
    },
    deleteUser(colaborador) {
      this.$swal({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Você tem certeza que quer deletar esse usuário?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não, calma aí.',
        confirmButtonText: 'Sim, eu tenho!',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.appLoading.show()
          this.$store.dispatch('colaboradores/delete', colaborador.usuarioId).then(() => {
            this.appLoading.hide()
            this.$swal({
              icon: 'success',
              title: 'Deletado com sucesso!',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    },
  },
  setup() {
    const permissoes = JSON.parse(localStorage.getItem('permissoes')).filter(p => p.permissaoId === 2)[0]
    return { 
      ...ColaboradoresComposition(),
      permissoes,
    }
  },
}
</script>

<style scoped>

</style>