<template>
  <div>
    <b-card 
      no-body 
      class="mb-2"
    >
      <div class="m-2">
        <validation-observer>
          <b-form @submit.prevent>
            <b-row class="responsive">
              <b-col class="d-flex b-situation">
                <button-tracking
                  class="p-1"
                  nome="ETIQUETA"
                  :data="dataEtiqueta.length > 0 ? `${maskDate(dataEtiqueta)} - ${categoriesEtiqueta[categoriesEtiqueta.length -1]}` : '--/--/-- 00:00'"
                  :tempo="tempoEtiqueta.length > 0 ? tempoEtiqueta[tempoEtiqueta.length -1] : 0"
                  :selected="selected == 'GERACAOETIQUETA'"
                  @onClickButton="selectCategory('GERACAOETIQUETA')"
                />
                <button-tracking
                  class="p-1"
                  nome="FRETE"
                  :data="dataFrete.length > 0 ? `${maskDate(dataFrete)} - ${categoriesFrete[categoriesFrete.length -1]}` : '--/--/-- 00:00'"
                  :tempo="tempoFrete.length > 0 ? tempoFrete[tempoFrete.length -1] : 0"
                  :selected="selected == 'COTACAOFRETE'"
                  @onClickButton="selectCategory('COTACAOFRETE')"
                />
                <button-tracking
                  class="p-1"
                  nome="TRACKING"
                  :data="dataTracking.length > 0 ? `${maskDate(dataTracking)} - ${categoriesEtiqueta[categoriesEtiqueta.length -1]}` : '--/--/-- 00:00'"
                  :tempo="tempoTracking.length > 0 ? tempoTracking[tempoTracking.length -1] : 0"
                  :selected="selected == 'TRACKING'"
                  @onClickButton="selectCategory('TRACKING')"
                />
              </b-col>
              <b-col class="row buttons-calendar">
                <b-col class="col-6 d-flex align-items-center b-calendar">
                  <template>
                    <validation-provider
                      #default="validationContext"
                      name="Data"
                      rules="required"
                    >
                      <b-form-group
                        label="Escolha a data para Exportação"
                        label-for="dataPesquisa"
                        class="mb-0"
                      >
                        <b-form-datepicker
                          id="datepicker" 
                          v-model="dataBusca"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          placeholder="Data para Busca"
                          :state="null"
                          :max="dataCalendario"
                          locale="pt-BR"
                        />
                        <span class="undermessage-error">
                          {{ validationContext.errors[0] }}
                        </span>
                      </b-form-group>
                    </validation-provider>
                  </template>
                </b-col>
                <b-col class="col-6 d-flex align-items-center">
                  <div class="d-flex justify-content-end">
                    <b-button
                      type="button"
                      variant="primary"
                      class="mb-sm-0 mr-0 mr-sm-1"
                      @click="onSubmit"
                    >
                      Exportar Excel
                      <feather-icon 
                        class="ml-1 d-md-none d-xl-inline d-inline" 
                        icon="SearchIcon"
                      />
                    </b-button>
                  </div>
                </b-col>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
    <b-card
      v-if="selected"
      class="mb-2 d-flex align-items-center"
    >
      <span class="titulo-legenda">{{ dictionaryTipoMovimentacao(selected) }}</span>
    </b-card>
    <b-card
      no-body 
      class="mb-0"
    >
      <template v-if="selected">
        <div>
          <grafico-tracking
            :categories="categories"
            :series="series"
          />
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import store from '@/store'
import { $themeConfig } from '@themeConfig'
import ExcelComposition from '@/components/exportar/excel.composition'
import utils from '@/helpers/utils'
import ButtonTracking from './components/button-tracking/index.vue'
import GraficoTracking from './components/grafico-tracking/index.vue'

export default {
  name: 'MonitorJadlog',
  components: { 
    ButtonTracking,
    GraficoTracking,
  },
  setup() {
    const { appCarregando } = $themeConfig.app
    const monitorJadlogStore = createNamespacedHelpers('monitorJadlog')
    const { downloadSpreadsheetWithHeader } = ExcelComposition()

    const state = reactive({
      dataBusca: new Date().toISOString().split('T')[0],
      dataCalendario: new Date().toISOString().split('T')[0],
      dados: monitorJadlogStore.useGetters(['data']).data,
      dadosExcel: monitorJadlogStore.useGetters(['exportacao']).exportacao,
      selected: null,

      dataFrete: [],
      tempoFrete: [],
      categoriesFrete: [],

      dataTracking: [],
      tempoTracking: [],
      categoriesTracking: [],
      
      dataEtiqueta: [],
      tempoEtiqueta: [],
      categoriesEtiqueta: [],

      categories: [],
      series: [],
    })

    const dictionaryTipoMovimentacao = select => {
      const dictionary = {
        GERACAOETIQUETA: 'Monitor Geração Etiqueta',
        COTACAOFRETE: 'Monitor Cotação Frete',
        TRACKING: 'Monitor Tracking',
        DEFAULT: '',
      }
      return dictionary[select] ? dictionary[select] : dictionary.DEFAULT
    }

    watch(() => state.dados, () => {
      state.dados.forEach(dado => {
        const tempoFloat = parseFloat(dado.tempoResposta)
        const dateTime = new Date(dado.data)
        const data = dado.data.split('T')[0]
        const hora = `${dateTime.getHours()}:${dateTime.getMinutes().toString().padStart(2, '0')}`
        if (dado.monitorJadlogEnum === 'GERACAOETIQUETA') {
          state.dataEtiqueta = data
          state.categoriesEtiqueta.push(hora)
          state.tempoEtiqueta.push(tempoFloat)
        }
        if (dado.monitorJadlogEnum === 'COTACAOFRETE') {
          state.dataFrete = data
          state.categoriesFrete.push(hora)
          state.tempoFrete.push(tempoFloat)
        }
        if (dado.monitorJadlogEnum === 'TRACKING') {
          state.dataTracking = data
          state.categoriesTracking.push(hora)
          state.tempoTracking.push(tempoFloat)
        }
      })
      state.selected = 'GERACAOETIQUETA'
    })

    watch(() => state.selected, value => {
      if (value === 'COTACAOFRETE') {
        state.categories = state.categoriesFrete
        state.series = [{
          name: 'Cotação Frete',
          data: state.tempoFrete,
        }]
      }
      if (value === 'TRACKING') {
        state.categories = state.categoriesTracking
        state.series = [{
          name: 'Tracking',
          data: state.tempoTracking,
        }]
      }
      if (value === 'GERACAOETIQUETA') {
        state.categories = state.categoriesEtiqueta
        state.series = [{
          name: 'Geração Etiqueta',
          data: state.tempoEtiqueta,
        }]
      }
    })

    const downloadExcel = filtros => {
      const dadosEsportacao = state.dadosExcel
      const dados = dadosEsportacao.map(d => ({
        data: `${d.data.split('T')[1].substring(0, 5)}`,
        tempoResposta: `${d.tempoResposta}s`,
      }))
      const nomeRelatorio = dictionaryTipoMovimentacao(filtros.monitorJadlogEnum)
      const nomeArquivo = dictionaryTipoMovimentacao(filtros.monitorJadlogEnum).replace(/ /g, '_')

      const heading = [['Horário do Monitoramento', 'Tempo Requisição em segundos']]
      downloadSpreadsheetWithHeader(nomeRelatorio, heading, filtros.dataBusca, null, dados, nomeArquivo)
    }

    const gerarExcel = filtros => {
      setTimeout(() => {
        downloadExcel(filtros)
      }, 100)
    }

    const onSubmit = () => {
      appCarregando.show()
      const filtros = {
        dataBusca: state.dataBusca,
        monitorJadlogEnum: state.selected,
      }
      store.dispatch('monitorJadlog/findForExcel', filtros).then(() => gerarExcel(filtros))
    }

    const request = () => {
      appCarregando.show()
      store.dispatch('monitorJadlog/findAll').then(() => appCarregando.hide())
    }
    request()

    const selectCategory = cat => {
      state.selected = cat
    }

    return {
      ...toRefs(state),
      ...utils,
      dictionaryTipoMovimentacao,
      selectCategory,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
.b-form-datepicker {
  height: 2.714rem !important;
  label {
    align-self: center !important;
  }
}
.titulo-legenda {
  font-size: 2rem
}
.b-calendar {
  justify-content: flex-end;
}
.responsive {
  @media (max-width: 1600px) {
    flex-direction: column;
    align-items: center;
    .b-situation {
      width: 100% !important;
      justify-content: center;
    }
    .buttons-calendar {
      padding-top: 2rem;
      width: 100%;

    }
  }

  @media (max-width: 1024px) {
    .b-situation {
      flex-wrap: wrap;
    }
  }
}
</style>