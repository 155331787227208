<template>
  <div>
    <template v-if="perfil">
      <b-card>
        <b-row>

          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <div class="d-flex justify-content-start">
              <b-avatar
                ref="previewEl"
                :text="avatarText(perfil.nome)"
                :variant="`light-success`"
                size="90px"
                rounded
              />
              <div class="d-flex flex-column ml-1">
                <h4 class="mb-1">
                  {{ perfil.nome }}
                </h4>
                <div class="d-flex flex-wrap">
                  <b-button
                    v-if="permissoes.editar"
                    variant="primary"
                    :to="{ name: 'perfis-editar', params: { ...perfil.perfilId } }"
                  >
                    Editar
                  </b-button>
                  <b-button
                    v-if="permissoes.excluir"
                    variant="outline-danger"
                    class="ml-1"
                    @click="deletePerfil(perfil)"
                  >
                    Deletar
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header>
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">Permissões do perfil</span>
            </b-card-title>
          </b-card-header>
          <b-table
            striped
            responsive
            class="mb-0"
            :items="perfil.permissoes"
            :fields="tableColumns"
            show-empty
            empty-text="Nenhuma permissão cadastrada"
          >
            <template #cell(descricao)="data">
              {{ data.value }}
            </template>
            <template #cell()="data">
              <b-form-checkbox
                disabled
                :checked="data.value"
              />
            </template>
          </b-table>
        </b-card>
      </b-card>
    </template>
  </div>
</template>

<script>
import { watch, reactive, toRefs } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'

const appLoading = $themeConfig.app.appLoadingImage

export default {
  name: 'IndexVue',
  beforeMount() {
    this.$store.dispatch('perfis/findById', this.$router.currentRoute.params.perfilId)
  },
  methods: {
    deletePerfil(perfil) {
      this.$swal({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Você tem certeza que quer deletar esse perfil?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não, calma aí.',
        confirmButtonText: 'Sim, eu tenho!',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          appLoading.show()
          this.$store.dispatch('perfis/delete', perfil.perfilId).then(() => {
            appLoading.hide()
            this.$swal({
              icon: 'success',
              html:
                '<h1>Deletado com sucesso!</h1><br/><span style="font-size: 8px">Espero que você saiba o que está fazendo...</span>',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }).then(() => {
            this.$router.push('/cadastro/perfis')
          })
        }
      })
    },
  },
  setup() {
    const perfisStore = createNamespacedHelpers('perfis')
    const permissoesStore = createNamespacedHelpers('permissoes')
    const { findAll } = permissoesStore.useActions(['findAll'])
    const state = reactive({
      perfil: perfisStore.useGetters(['perfil']).perfil,
    })

    watch(() => state.perfil, perfil => {
      findAll().then(value => {
        const permissoesDoPerfil = perfil.permissoes
        state.perfil.permissoes = []
        value.forEach(permissao => {
          const permissaoDoPerfil = permissoesDoPerfil.find(p => p.permissaoId === permissao.permissaoId)
          state.perfil.permissoes.push({
            perfilPermissaoId: permissaoDoPerfil ? permissaoDoPerfil.perfilPermissaoId : null,
            perfilId: permissaoDoPerfil ? permissaoDoPerfil.perfilId : perfil.perfilId,
            descricao: permissao.descricao,
            permissaoId: permissaoDoPerfil ? permissaoDoPerfil.permissaoId : permissao.permissaoId,
            visualizar: permissaoDoPerfil ? permissaoDoPerfil.visualizar : permissao.visivel,
            editar: permissaoDoPerfil ? permissaoDoPerfil.editar : permissao.editavel,
            excluir: permissaoDoPerfil ? permissaoDoPerfil.excluir : permissao.excluivel,
          })
        })
      })
    })

    const tableColumns = [
      { key: 'descricao', sortable: true },
      { key: 'visualizar', sortable: false },
      { key: 'editar', sortable: false },
      { key: 'excluir', sortable: false },
    ]

    const permissoes = JSON.parse(localStorage.getItem('permissoes')).filter(p => p.permissaoId === 3)[0]

    return {
      ...toRefs(state),
      // MISC
      avatarText,
      tableColumns,
      permissoes,
    }
  },
}
</script>

<style scoped>

</style>