import { reactive, ref, toRefs, watch } from '@vue/composition-api'

import { createNamespacedHelpers } from 'vuex-composition-helpers'
import formValidation from '@core/comp-functions/forms/form-validation'
import cleaveOptions from '@/libs/cleave/options'

const usuariosStore = createNamespacedHelpers('users')
const departamentosStore = createNamespacedHelpers('departamentos')

export default () => {
  const { findById } = usuariosStore.useActions(['findById', 'edit'])

  const state = reactive({
    usuario: usuariosStore.useGetters(['selected']).selected,
    departamentos: departamentosStore.useGetters(['departamentos']).departamentos,
    departamentosOptions: departamentosStore.useGetters(['departamentosOptions']).departamentosOptions,
  })

  const resetUsuario = () => {
    findById(state.usuario.usuarioId)
  }

  const cleaveKey = ref(0)

  function incrementCleaveKey() {
    cleaveKey.value += 1
  }

  watch(() => state.usuario, () => {
    incrementCleaveKey()
  })

  const {
    refFormObserver,
    getValidationState,
    resetForm,
  } = formValidation(resetUsuario)

  return {
    ...toRefs(state),
    // FORM FUNCS
    refFormObserver,
    getValidationState,
    resetForm,
    // MISC
    cleaveKey,
    cleaveOptions,
  }
}