<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetFormTaxas"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar Taxa
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          ref="formTaxas"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Nome"
            rules="max:150|required"
          >
            <b-form-group
              label="Nome"
              label-for="nomeTaxa"
            >
              <b-form-input
                id="nomeTaxa"
                v-model="taxas.nomeTaxa"
                :state="getValidationState(validationContext)"
                trim
                placeholder="'empresa': Volume 4 em 4 + R$4,00"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Tipo de Taxa"
            rules="required"
          >
            <b-form-group
              label="Tipo de Taxa"
              label-for="tipoTaxa"
            >
              <v-select
                v-model="taxas.tipoTaxa"
                :options="tipoTaxa"
              />
              <span class="undermessage-error text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <div v-if="taxas.tipoTaxa === 'VOLUME'">
            <validation-provider
              #default="validationContext"
              name="A partir de X volumes"
              :rules="taxas.acimaLimiteValor ? 'integer|required' : 'integer'"
            >
              <b-form-group
                label="A partir de X volumes"
                label-for="acimaLimite"
              >
                <b-form-input
                  id="acimaLimite"
                  v-model="taxas.acimaLimite"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Qtd Volumes"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="acimaLimiteValor"
              :rules="taxas.acimaLimite ? 'required' : ''"
            >
              <b-form-group
                label="Acrescentar a taxa de"
                label-for="acimaLimiteValor"
              >
                <CurrencyInput
                  v-model="taxas.acimaLimiteValor"
                  name="acimaLimiteValor"
                  :state="getValidationState(validationContext)"
                  :validation-context="validationContext"
                  :show-symbol="true"
                  placeholder="Valor"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="acrescimoPorQuantidade"
              :rules="taxas.acrescimoPorQuantidadeValor ? 'integer|required' : 'integer'"
            >
              <b-form-group
                label="A cada X volumes"
                label-for="acrescimoPorQuantidade"
              >
                <b-form-input
                  id="acrescimoPorQuantidade"
                  v-model="taxas.acrescimoPorQuantidade"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Qtd Volumes"
                  number
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="acrescimoPorQuantidadeValor"
              :rules="taxas.acrescimoPorQuantidade ? 'required' : ''"
            >
              <b-form-group
                label="Acrescentar o valor"
                label-for="acrescimoPorQuantidadeValor"
              >
                <CurrencyInput
                  v-model="taxas.acrescimoPorQuantidadeValor"
                  name="acrescimoPorQuantidadeValor"
                  :validation-context="validationContext"
                  :show-symbol="true"
                  placeholder="Valor"
                >
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </CurrencyInput>
              </b-form-group>
            </validation-provider>
          </div>

          <div v-if="taxas.tipoTaxa === 'PESO'">
            <validation-provider
              #default="validationContext"
              name="A partir de X Peso(kg)"
              :rules="taxas.acimaLimiteValor ? 'required' : ''"
            >
              <b-form-group
                label="A partir de X Peso(kg)"
                label-for="acimaLimite"
                style="margin-bottom: 0 !important;"
              >
                <CurrencyInput
                  id="acimaLimite"
                  v-model="taxas.acimaLimiteKg"
                  name="acimaLimite"
                  :state="getValidationState(validationContext)"
                  :validation-context="validationContext"
                  style="margin-bottom: 0 !important;"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="acimaLimiteValor"
              :rules="taxas.acimaLimite ? 'required' : ''"
            >
              <b-form-group
                label="Acrescentar a taxa de"
                label-for="acimaLimiteValor"
              >
                <CurrencyInput
                  v-model="taxas.acimaLimiteValor"
                  name="acimaLimiteValor"
                  :validation-context="validationContext"
                  :show-symbol="true"
                  placeholder="Valor"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="acrescimoPorQuantidade"
              :rules="taxas.acrescimoPorQuantidadeValor ? 'required' : ''"
            >
              <b-form-group
                label="A cada X Peso(kg)"
                label-for="acrescimoPorQuantidade"
              >
                <CurrencyInput
                  id="acrescimoPorQuantidadeValor"
                  v-model="taxas.acrescimoPorQuantidadeKg"
                  name="acrescimoPorQuantidadeValor"
                  :state="getValidationState(validationContext)"
                  :validation-context="validationContext"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="acrescimoPorQuantidadeValor"
              :rules="taxas.acrescimoPorQuantidade ? 'required' : ''"
            >
              <b-form-group
                label="Acrescentar a taxa de"
                label-for="acrescimoPorQuantidadeValor"
              >
                <CurrencyInput
                  id="acrescimoPorQuantidadeValor"
                  v-model="taxas.acrescimoPorQuantidadeValor"
                  name="acrescimoPorQuantidadeValor"
                  :state="getValidationState(validationContext)"
                  :validation-context="validationContext"
                  :show-symbol="true"
                  placeholder="Valor"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>

          <validation-provider
            #default="validationContext"
            name="Observação"
          >
            <b-form-group
              label="Observação"
              label-for="observacao"
            >
              <b-textarea
                id="observacao"
                v-model="taxas.observacao"
                placeholder="Observação sobre a taxa"
              >
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-textarea>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Importar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import formValidation from '@core/comp-functions/forms/form-validation'
import _ from 'lodash'
import { reactive, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'
import store from '@/store'
import CurrencyInput from '@/components/form/CurrencyInput.vue'

export default {
  name: 'ImportarTaxaSidebar',
  comments: { CurrencyInput },
  emits: ['aoSalvar'],
  props:
    {
      active: {
        type: Boolean,
        required:
          true,
      }
      ,
    },
  setup(props, { emit }) {
    const taxasStore = createNamespacedHelpers('taxas')
    const { save } = taxasStore.useActions(['save'])
    const appLoading = $themeConfig.app.appLoadingImage

    const blankTaxas = {
      nomeTaxa: null,
      tipoTaxa: null,
      acimaLimite: null,
      acimaLimiteKg: null,
      acimaLimiteValor: null,
      acrescimoPorQuantidade: null,
      acrescimoPorQuantidadeKg: null,
      acrescimoPorQuantidadeValor: null,
      observacao: null,
    }

    const stateReactive = reactive({
      taxas: _.cloneDeep(blankTaxas),
    })

    const tipoTaxa = [...store.state.taxas.tipoTaxa]

    const onSubmit = () => {
      appLoading.show()
      try {
        save(stateReactive.taxas).then(response => {
          appLoading.hide()
          emit('update:active', false)
          emit('aoSalvar')
          if (!response.success) {
            swal.fire({
              icon: 'info',
              title: 'Erro!',
              html: `<h1>${response.body}</h1>`,
              showConfirmButton: true,
            })
          } else {
            swal.fire({
              icon: 'success',
              text: 'Taxa salva com sucesso!',
              showConfirmButton: true,
            })
          }
        })
      } catch (error) {
        appLoading.hide()
        swal.fire({
          icon: 'error',
          title: 'Erro!',
          html: `${error}`,
          showConfirmButton: true,
        })
      }
    }

    const resetTaxas = () => {
      stateReactive.taxas = _.cloneDeep(blankTaxas)
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetTaxas)

    return {
      ...toRefs(stateReactive),
      refFormObserver,
      tipoTaxa,
      getValidationState,
      resetForm,
      onSubmit,
      resetTaxas,
    }
  },
  methods: {
    resetFormTaxas() {
      this.resetTaxas()
      this.$refs.formTaxas.reset()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-group {
  margin-bottom: 1rem;
}
</style>