<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetFormFatura"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Importar Fatura
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          ref="formFaturas"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name="Clientes"
            rules="required"
          >
            <b-form-group
              class="mb-0"
              label="Clientes"
              label-for="clienteId"
            >
              <v-select
                v-model="fatura.clienteId"
                placeholder="Selecione o Cliente"
                :options="clientesOptions"
                :reduce="val=>val.value"
              />
              <span class="undermessage-error text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Arquivo da Fatura"
            rules="required"
          >
            <b-form-group
              label="Arquivo da Fatura"
              label-for="arquivoFatura"
            >
              <b-form-file
                ref="fileInput"
                v-model="arquivoFatura"
                placeholder="Selecione o arquivo da fatura"
                drop-placeholder="Solte a arquivo aqui"
                accept=".xls, .xlsx"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Arquivo da Boleto"
            rules="required"
          >
            <b-form-group
              label="Arquivo da Boleto"
              label-for="arquivoBoleto"
            >
              <b-form-file
                ref="fileInput"
                v-model="arquivoBoleto"
                placeholder="Selecione o boleto da fatura"
                drop-placeholder="Solte a arquivo aqui"
                accept=".pdf"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-if="arquivoBoleto"
            #default="validationContext"
            name="Data de emissão"
            rules="required"
          >
            <b-form-group
              label="Data de emissão"
              label-for="dataEmissao"
            >
              <b-form-datepicker
                id="dataEmissao"
                v-model="fatura.dataEmissao"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="pt-BR"
                placeholder="Selecione a data de Emissão"
                start-weekday="0"
                class="mb-1, form-control"
                :disabled="fatura.dataEmissao !== null"
                :state="getValidationState ? getValidationState(validationContext) : null"
                @update="(novaData) => fatura.dataEmissao = novaData"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <validation-provider
            v-if="arquivoBoleto"
            #default="validationContext"
            name="Data de vencimento"
            rules="required"
          >
            <b-form-group
              label="Data de vencimento"
              label-for="dataVencimento"
            >
              <b-form-datepicker
                id="dataVencimento"
                v-model="fatura.dataVencimento"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="pt-BR"
                :disabled="fatura.valor !== null"
                placeholder="Selecione a data de Vencimento"
                start-weekday="0"
                class="mb-1, form-control"
                :state="getValidationState ? getValidationState(validationContext) : null"
                @onChange="resetBoletoInfo()"
                @update="(novaData) => fatura.dataVencimento = novaData"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <validation-provider
            v-if="arquivoBoleto"
            #default="validationContext"
            name="valorBoleto"
            rules="required"
          >
            <b-form-group
              label="Valor Boleto"
              label-for="valorBoleto"
              class="resetar"
            >
              <CurrencyInput
                v-model="fatura.valor"
                name="valorBoleto"
                class="resetar"
                :state="getValidationState(validationContext)"
                :validation-context="validationContext"
                :show-symbol="true"
                :disabled="fatura.valor !== null"
                placeholder="Valor"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Arquivo da Zip"
          >
            <b-form-group
              label="Arquivo da Zip"
              label-for="arquivosZip"
            >
              <b-form-file
                ref="fileInput"
                v-model="arquivosZip"
                :multiple="true"
                placeholder="Selecione o arquivo .zip"
                drop-placeholder="Solte a arquivo aqui"
                accept=".zip"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Importar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import formValidation from '@core/comp-functions/forms/form-validation'
import _ from 'lodash'
import * as pdfjsLib from 'pdfjs-dist/webpack'
import { reactive, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'ImportarFaturaSidebar',
  emits: ['aoSalvar'],
  props:
    {
      active: {
        type: Boolean,
        required:
          true,
      }
      ,
    },
  beforeMount() {
    this.$store.dispatch('clientes/findAll').then(() => {
      this.$store.dispatch('clientes/setOptions')
    })
  },
  setup(props, { emit }) {
    const faturasStore = createNamespacedHelpers('faturasCliente')
    const clienteStores = createNamespacedHelpers('clientes')

    const { save } = faturasStore.useActions(['save'])

    const blankFatura = {
      clienteId: null,
      dataEmissao: null,
      dataVencimento: null,
      valor: null,
    }

    const state = reactive({
      fatura: _.cloneDeep(blankFatura),
      arquivoFatura: null,
      arquivoBoleto: null,
      arquivosZip: [],
      clientesOptions: clienteStores.useGetters(['clientesOptions']).clientesOptions,
      arquivoImportacao: null,
    })

    const createMultipart = () => {
      const multipart = new FormData()
      multipart.append('fileFatura', state.arquivoFatura)
      multipart.append('fileBoleto', state.arquivoBoleto)
      state.arquivosZip.forEach(a => {
        multipart.append('filesZip', a)
      })
      multipart.append('fatura', JSON.stringify(state.fatura))
      return multipart
    }

    const { appCarregando } = $themeConfig.app

    const onSubmit = () => {
      appCarregando.show()
      const multipart = createMultipart()
      save(multipart)
        .then(response => {
          appCarregando.hide()
          emit('update:active', false)
          emit('aoSalvar')
          if (!response.success) {
            swal.fire({
              icon: 'info',
              html: `<h1>${response.body}</h1>`,
              width: 400,
              showConfirmButton: true,
            })
          } else {
            swal.fire({
              icon: 'success',
              text: 'Fatura salva com sucesso!',
              width: 400,
              showConfirmButton: true,
            })
          }
        }).catch(() => {
          appCarregando.hide()
          swal.fire({
            icon: 'error',
            html: 'Erro ao importar arquivo!',
            width: 400,
            showConfirmButton: true,
          })
        })
    }

    const readFileAsArrayBuffer = file => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = event => {
        resolve(event.target.result)
      }
      reader.onerror = error => {
        reject(new Error('Erro ao ler:', error))
      }
      reader.readAsArrayBuffer(file)
    })

    const handlePdfUpload = async file => {
      if (file) {
        try {
          const pdfData = await readFileAsArrayBuffer(file)
          const typedArray = new Uint8Array(pdfData)
          const pdf = await pdfjsLib.getDocument({ data: typedArray }).promise
          const page = await pdf.getPage(1)

          const content = await page.getTextContent()
          const strings = content.items.map(item => item.str)

          const text = strings.join(' ')
          const valor = text.match(/R\$\s*([\d.,]+)/)
          const vencimentoBoleto = text.match(/Vencimento:\s*([\d/]+)/)
          const emissaoBoleto = text.match(/DM S\s*([\d/]+)/)
          if (vencimentoBoleto) {
            const [day, month, year] = vencimentoBoleto[1].split('/')
            state.fatura.dataVencimento = `${year}-${month}-${day}`
          } else {
            state.fatura.dataVencimento = null
          } 
          if (emissaoBoleto) {
            const [day, month, year] = emissaoBoleto[1].split('/')
            state.fatura.dataEmissao = `${year}-${month}-${day}`
          } else {
            state.fatura.dataEmissao = null
          }
          if (valor) {
            state.fatura.valor = parseFloat(valor[1].replace('.', '').replace(',', '.'))
          } else {
            state.fatura.valor = null
          }
        } catch (error) {
          swal.fire({
            icon: 'error',
            html: 'Erro ao importar arquivo!',
            width: 400,
            showConfirmButton: true,
          })
        }
      }
    }

    watch(() => state.arquivoBoleto, pdf => {
      handlePdfUpload(pdf)
    })

    const resetBoletoInfo = () => {
      state.fatura.dataEmissao = null
      state.fatura.dataVencimento = null
      state.fatura.valor = null
    }

    const resetFatura = () => {
      state.fatura = _.cloneDeep(blankFatura)
      state.arquivoFatura = null
      state.arquivoBoleto = null
      state.arquivosZip = []
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetFatura)
    watch(() => props.active, value => {
      if (value) {
        resetForm()
        resetFatura()
      }
    })

    return {
      ...toRefs(state),
      // FORM CONFIGS
      refFormObserver,
      getValidationState,
      // extractPdfValues,
      resetForm,
      // METHODS
      onSubmit,
      resetFatura,
      resetBoletoInfo,
    }
  },
  methods: {
    resetFormFatura() {
      this.resetFatura()
    },
  },
}
</script>

<style lang="scss">
  .resetar {
    margin-bottom: 0 !important;
  }
</style>
