import LogsService from '@/service/LogsService'

export default {
  namespaced: true,
  state: {
    logs: [],
  },
  getters: {
    logs(state) {
      return state.logs
    },
  },
  mutations: {
    UPDATE_LOGS(state, logs) {
      state.logs = logs
    },
  },
  actions: {
    async findAllByLocal({ commit }, local) {
      const logs = await LogsService.findAllByLocal(local)
      commit('UPDATE_LOGS', logs)
    },
    resetLogs({ commit }) {
      commit('UPDATE_LOGS', [])
    },
    async saveLogLoginCliente(_, saveLogLogin) {
      await LogsService.saveLogLoginCliente(saveLogLogin)
    },
    async saveLogLoinUnidade(_, saveLogLogin) {
      await LogsService.saveLogLoinUnidade(saveLogLogin)
    },
  },
}