<template>
  <div>
    <template v-if="imposto">
      <b-card>
        <b-row>

          <b-col
            cols="21"
            xl="4"
            class="d-flex justify-content-between flex-column"
          >
            <div class="d-flex justify-content-start">
              <div class="d-flex flex-column ml-1">
                <h5 class="mb-3">
                  Tipo Imposto - {{ imposto.tipoImposto }}
                </h5>
                <div class="d-flex flex-wrap">
                  <b-button
                    variant="outline-danger"
                    @click="direcionar()"
                  >
                    Voltar
                  </b-button>
                  <b-button
                    v-if="imposto.ativo"
                    class="ml-1"
                    variant="outline-danger"
                    @click="desativarImposto(imposto.tipoImposto)"
                  >
                    Desativar
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            xl="8"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="ArrowUpIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Arquivo Importado</span>
                </th>
                <td class="pb-50">
                  <a
                    :href="`${ imposto.urlArquivoImportacao}`"
                    target="_blank"
                  >
                    {{ imposto.nomeArquivoImportacao }}
                  </a>
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="CalendarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Data de Impostação</span>
                </th>
                <td class="pb-50">
                  {{ imposto.dataImportacao }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="CalendarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Data de Vigência</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ imposto.dataVigencia }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import { reactive, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default {
  name: 'IndexVue',
  beforeMount() {
    this.$store.dispatch('impostos/findById', this.$route.params.impostoId)
  },
  setup() {
    const impostosStore = createNamespacedHelpers('impostos')
    const state = reactive({
      imposto: impostosStore.useGetters(['selected']).selected,
      search: '',
    })

    const direcionar = () => {
      router.push('/cadastro/impostos')
    }

    const desativarImposto = tipoImposto => {
      swal.fire({
        title: 'Atenção!',
        icon: 'warning',
        html: 'Você tem certeza que deseja desabilitar este imposto?',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('impostos/desativarImposto', tipoImposto).then(() => {
            swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Imposto desabilitado com sucesso!',
              showConfirmButton: true,
            }).then(() => {
              router.push({ name: 'impostos-cadastro' })
            })
          })
        }
      })
    }

    return { 
      ...toRefs(state),
      desativarImposto,
      direcionar,
    }
  },
}
</script>

<style lang="css" scoped>

</style>
