import HttpClient from './http-clients/portaljad'

const baseUrl = 'pesquisa-envios'

export default class PesquisaService {
  static async pesquisa(filtros) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'unidade'].join('/'), filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async pesquisaExportar(filtros) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'unidade-exportar'].join('/'), filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
  
  static async overview(filtros) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'overview'].join('/'), filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async relatorio(filtros) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'relatorio-unidade'].join('/'), filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async curvaAbc(filtros) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'curva-abc'].join('/'), filtros)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}