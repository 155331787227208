<template lang="">
  <div>
    <b-table
      ref="tableRef"
      show-empty
      :items="dados"
      :fields="headTable"
      sticky-header
    >
      <template #cell(dataHoraLogin)="data">
        <div>
          {{ data.item.dataHoraLogin ? maskDateHour(data.item.dataHoraLogin) : '' }}
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>

import utils from '@/helpers/utils'

export default {
  name: 'LoginModal',
  props: {
    dados: {
      type: undefined,
      required: true,
    },
    relatorio: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { relatorio } = props
    const tableHeadUnidade = [
      { key: 'dataHoraLogin', label: 'Data Hora Login' },
      { key: 'ip', label: 'IP' },
    ]
    const tableHeadCliente = [
      { key: 'dataHoraLogin', label: 'Data Hora Login' },
      { key: 'localEntrada', label: 'Local Entrada' },
      { key: 'ip', label: 'IP' },
    ]

    const headTable = relatorio === 'UNIDADE' ? tableHeadUnidade : tableHeadCliente

    return {
      headTable,
      ...utils,
    }
  },
}
</script>
<style lang="">
  
</style>