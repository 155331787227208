export default class ClienteAdapter {
  static toApi(cliente) {
    return {
      ...cliente,
      numeroDocumento: cliente.numeroDocumento.replaceAll(/[.,()/\s-]+/g, ''),
      telefone: cliente.telefone.replaceAll(/[.,()/\s-]+/g, ''),
      cep: cliente.cep.replaceAll(/[.,()/\s-]+/g, ''),
      ativo: true,
    }
  }

  static toStore(cliente) {
    return {
      ...cliente,
    }
  }
}