import HttpClient from '@/service/http-clients/portaljad'

const baseUrl = 'servicos'

export default class ServicoService {
  static async findAll() {
    try {
      const { data } = await HttpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(id) {
    try {
      const { data } = await HttpClient.get([baseUrl, id].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}
