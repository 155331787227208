import HttpClient from './http-clients/portaljad'

const baseUrl = 'taxas'

export default class TaxasService {
  static async save(taxa) {
    try {
      const { data } = await HttpClient.post(baseUrl, taxa)
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAll() {
    try {
      const { data } = await HttpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(taxaId) {
    try {
      const { data } = await HttpClient.get([baseUrl, taxaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async edit(taxaId, taxa) {
    try {
      const { data } = await HttpClient.put([baseUrl, taxaId].join('/'), taxa)
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async deleteById(taxaId) {
    try {
      await HttpClient.delete([baseUrl, taxaId].join('/'))
      return true
    } catch (error) {
      throw error.response.data
    }
  }
}