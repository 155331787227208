<template>
  <div v-if="!isLoading">
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col>
            <div class="d-flex align-items-center justify-content-start text-center">
              <b class="title">On-line / Off-line</b>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b class="subtitle">Liberar geração de etiquetas</b>
            <div
              v-if="config.online"
              class="m-2"
            >
              <p>Essa Ação fará com que as etiquetas impressas na Web do Clientes <b>não constem ShippmentID</b></p>
              <b-row align-v="center">
                <b-col md="3">
                  <b-button
                    variant="primary"
                    class="mb-2 mb-md-0"
                    type="button"
                    @click="confirmarOffline = true"
                  >
                    Ativar Modo Off-line
                  </b-button>
                </b-col>
                <b-col
                  v-if="confirmarOffline"
                  md="4"
                  align-self="stretch"
                >
                  <p class="text-red mb-0">
                    Deseja realmente gerar etiquetas sem o ShippmentID?
                  </p>
                </b-col>
                <b-col
                  v-if="confirmarOffline"
                  md="5"
                >
                  <b-button
                    variant="primary"
                    class="mr-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="button"
                    @click="switchOnline"
                  >
                    Sim
                  </b-button>
                  <b-button
                    variant="secondary"
                    class="mr-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="button"
                    @click="confirmarOffline = false"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div
              v-else
              class="m-2"
            >
              <p>Essa Ação fará com que as etiquetas impressas na Web do Clientes <b>constem ShippmentID</b></p>
              <b-row align-v="center">
                <b-col cols="3">
                  <b-button
                    variant="danger"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="button"
                    @click="confirmarOnline = true"
                  >
                    Ativar Modo On-line
                  </b-button>
                </b-col>
                <b-col
                  v-if="confirmarOnline"
                  cols="4"
                  align-self="stretch"
                >
                  <p class="text-red mb-0">
                    Deseja realmente gerar etiquetas com o ShippmentID?
                  </p>
                </b-col>
                <b-col
                  v-if="confirmarOnline"
                  cols="5"
                >
                  <b-button
                    variant="primary"
                    class="mr-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="button"
                    @click="switchOnline"
                  >
                    Sim
                  </b-button>
                  <b-button
                    variant="secondary"
                    class="mr-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="button"
                    @click="confirmarOnline = false"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col>
            <div class="d-flex align-items-center justify-content-start">
              <b class="title">Download Movimentações Autamaticamente</b>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b class="subtitle">Liberar importação de movimentos automático</b>
            <b-col
              md="1"
              class="py-1"
            >
              <b-form-checkbox 
                v-model="ativarimportacaoMovimento"
                name="check-button"
                size
                switch
              />
            </b-col>
            <div
              v-if="ativarimportacaoMovimento"
              class="m-2"
            >
              <p> Os dados deste relatório são atualizados diariamente nos seguintes horários.</p>
              <p class="mb-3">
                06h34, 08h34, 10h34, 12h34, 14h34, 15h34, 16h34, 19h34, 20h34, 23h34
              </p>
              <b-row align-v="center">
                <b-col
                  md="2"
                >
                  <b-form-group
                    label="Login Fraction"
                    label-for="loginFraction"
                  >
                    <b-form-input
                      id="loginFraction"
                      v-model="config.loginFraction"
                      type="text"
                      trim
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="2"
                >
                  <b-form-group
                    label="Senha Fraction"
                    label-for="senhaFraction"
                  >
                    <b-form-input
                      id="senhaFraction"
                      v-model="config.senhaFraction"
                      type="password"
                      trim
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="2"
                >
                  <span
                    id="tooltip-target-login-valido"
                    :class="config.loginValido == null ? '' : config.loginValido ? 'text-success' : 'text-danger'"
                  >
                    <feather-icon
                      :icon="config.loginValido == null ? 'ThumbsUpIcon' : config.loginValido ? 'ThumbsUpIcon' : 'ThumbsDownIcon'"
                      size="30"
                    />
                  </span>
                  <b-tooltip 
                    target="tooltip-target-login-valido"
                    triggers="hover"
                  >
                    <span>
                      {{ config.loginValido == null ? 'Login é checado a cada loop para importação de arquivos de movimento' : config.loginValido ? 'Login Realizado' : 'Erro ao logar, dados incorretos ou problema no fraction' }}
                    </span>
                  </b-tooltip>
                </b-col>
                <template v-if="config.loginValido != null"/>
              </b-row>
            </div>
            <b-col
              md="12"
            >
              <b-button
                variant="primary"
                class="my-1 mb-sm-0"
                :disabled="disableSalvar()"
                @click="salvarDados"
              >
                Salvar Dados
              </b-button>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col>
            <div class="d-flex align-items-center justify-content-start">
              <b class="title">Envio com declaração de conteúdo</b>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b class="subtitle">Permitir clientes gerar envios com declaração de conteúdo</b>
            <b-col
              md="1"
              class="py-1"
            >
              <b-form-checkbox 
                v-model="config.permitirEnvioDeclaracaoConteudo"
                name="check-button"
                size
                switch
                @change="salvarPermitirDecConteudo()"
              />
            </b-col>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import store from '@/store'
import { ref, computed, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'ConfiguracoesGerais',
  setup() {
    const { appCarregando } = $themeConfig.app
    const empresaStore = createNamespacedHelpers('empresa')
    const confirmarOffline = ref(false)
    const confirmarOnline = ref(false)
    const ativarimportacaoMovimento = ref(false)
    const isLoading = ref(true)

    const state = reactive({
      config: empresaStore.useGetters(['configUnidade']).configUnidade,
      oldData: null,
    })

    const empresaId = computed(() => {
      const user = JSON.parse(window.localStorage.getItem('userName'))
      return user ? user.empresaId : null
    })

    onBeforeMount(() => {
      if (empresaId.value) {
        store.dispatch('empresa/configuracoesUnidade', empresaId.value).then(() => {
          ativarimportacaoMovimento.value = state.config.loginFraction != null
          state.oldData = { ...state.config }
          isLoading.value = false
        })
      }
    })

    const salvarPermitirDecConteudo = () => {
      const msg = state.config.permitirEnvioDeclaracaoConteudo ? 'Ativa' : 'Desativa'
      swal.fire({
        title: 'Atenção!',
        icon: 'warning',
        html: `Você tem certeza que deseja ${msg}r a geração de etiquetas com declaração de conteúdo dos clientes?`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          isLoading.value = true
          appCarregando.show()
          store.dispatch('empresa/salvarEnvioDecConteudo', empresaId.value).then(() => {
            store.dispatch('empresa/configuracoesUnidade', empresaId.value).then(() => {
              isLoading.value = false
              appCarregando.hide()
              swal.fire({
                icon: 'success',
                html: `Etiquetas com declaração ${msg}das com sucesso`,
                width: 400,
                showConfirmButton: true,
              })
            }).catch(() => {
              appCarregando.hide()
              swal.fire({
                icon: 'error',
                text: 'Erro ao alterado modo!',
                width: 400,
                showConfirmButton: true,
              })
            })
          })
        } else {
          state.config.permitirEnvioDeclaracaoConteudo = !state.config.permitirEnvioDeclaracaoConteudo
        }
      })
    }

    const switchOnline = () => {
      confirmarOffline.value = false
      confirmarOnline.value = false
      appCarregando.show()
      store.dispatch('empresa/switchOnline', empresaId.value).then(() => {
        store.dispatch('empresa/configuracoesUnidade', empresaId.value).then(() => {
          appCarregando.hide()
          swal.fire({
            icon: 'success',
            text: 'Modo alterado com sucesso!',
            width: 400,
            showConfirmButton: true,
          })
        }).catch(() => {
          appCarregando.hide()
          swal.fire({
            icon: 'error',
            text: 'Erro ao alterado modo!',
            width: 400,
            showConfirmButton: true,
          })
        })
      })
    }

    const salvarDados = () => {
      let value
      if (!ativarimportacaoMovimento.value) {
        state.config.loginFraction = null
        state.config.senhaFraction = null
        state.config.loginValido = null
        value = true
      } else {
        value = (state.config.senhaFraction !== state.oldData.senhaFraction)
        || (state.config.loginFraction !== state.oldData.loginFraction)
      }

      if (value) {
        appCarregando.show()
        store.dispatch('empresa/salvarConfiguracoesUnidade', empresaId.value).then(() => {
          appCarregando.hide()
          swal.fire({
            icon: 'success',
            text: 'Login salvo com sucesso!',
            width: 400,
            showConfirmButton: true,
          })
          state.oldData = { ...state.config }
        }).catch(() => {
          appCarregando.hide()
          swal.fire({
            icon: 'error',
            html: 'Erro ao salvar Login!',
            width: 400,
            showConfirmButton: true,
          })
        })
      }
    }

    const disableSalvar = () => {
      if (!ativarimportacaoMovimento.value) {
        return false
      }
      if (state.config.loginFraction != null
      && state.config.loginFraction !== ''
      && state.config.senhaFraction != null
      && state.config.senhaFraction !== '') {
        if (state.config.loginFraction !== state.oldData.loginFraction || state.config.senhaFraction !== state.oldData.senhaFraction) {
          return false
        }
      }
      return true
    }

    return {
      ...toRefs(state),
      confirmarOffline,
      confirmarOnline,
      ativarimportacaoMovimento,
      salvarPermitirDecConteudo,
      isLoading,
      empresaId,
      switchOnline,
      salvarDados,
      disableSalvar,
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 1.7rem;
    margin-bottom: 1rem;
}
.subtitle {
    font-size: 1.3rem;
}

.text-red {
    color: red;
}
</style>