<template lang="">
  <div>
    <b-card>
      <b-row class="mb-2">
        <b-col
          md="8"
          class="d-flex justify-content-center justify-content-md-start"
        >
          <div class="text-center">
            <h2>Prazo cotação de frete por serviço</h2>
          </div>
        </b-col>
        <b-col
          md="4"
          class="d-flex justify-content-center justify-content-md-end"
        >
          <b-button
            type="submit"
            variant="primary"
            @click="onSubmit"
          >
            Salvar Mudanças
            <feather-icon
              icon="SaveIcon"
              class="ml-1"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-table
        :items="tableData"
        :fields="tableColumns"
        stacked="sm"
      >
        <template #cell(adicionalTempoEntrega)="data">
          <b-input
            v-model="data.item.adicionalTempoEntrega"
            type="number"
            trim
            placeholder="Dias"
            @input="filterInput(data)"
          />
        </template>
        <template #cell(servico)="data">
          <p>{{ serviceDictionary(data.item.servico) }}</p>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import store from '@/store'
import { reactive, toRefs, onBeforeMount, watch, onUnmounted } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'
import utils from '@/helpers/utils'
import InfoLegenda from '@/@core/utils/infoLegenda'

const { appCarregando } = $themeConfig.app
const servicosStore = createNamespacedHelpers('servicos')
const servicoFreteStore = createNamespacedHelpers('servicoFrete')

export default {
  name: 'ConfiguracoesFrete',
  methods: {
    filterInput(data) {
      const value = data.item.adicionalTempoEntrega.toString().replace(/[^0-9]/g, '')
      // eslint-disable-next-line no-param-reassign
      data.item.adicionalTempoEntrega = value ? parseInt(value, 10) : ''
    },
  },
  setup() {
    const { empresaId } = JSON.parse(localStorage.getItem('userName'))
    const state = reactive({
      loading: true,
      servicos: servicosStore.useGetters(['servicos']).servicos,
      servicoFrete: servicoFreteStore.useGetters(['servicoFrete']).servicoFrete,
      tableData: [],
    })

    onBeforeMount(() => {
      appCarregando.show()
      store.dispatch('infoSideBar/setLegenda', InfoLegenda.confFrete)
      store.dispatch('servicoFrete/findAll', empresaId).then(() => {
        store.dispatch('servicos/findAll').then(() => {
          appCarregando.hide()
          state.loading = false
        })
      })
    })

    onUnmounted(() => {
      store.dispatch('infoSideBar/resetLegenda')
    })

    const getServicoSelected = id => {
      const servico = state.servicoFrete.find(s => s.servicoId === id)
      return servico
    }

    watch(() => state.servicos, value => {
      value.forEach(s => {
        const servico = getServicoSelected(s.id)
        state.tableData.push({
          empresaId,
          ativo: servico ? servico.ativo : false,
          servico: s.servico,
          adicionalTempoEntrega: servico ? servico.adicionalTempoEntrega : null,
        })
      })
    })

    const onSubmit = (() => {
      appCarregando.show()
      try {
        const servicoFrete = state.tableData.filter(e => e.adicionalTempoEntrega !== null && e.adicionalTempoEntrega !== '')
        if (servicoFrete.length === 0) {
          servicoFrete.push({
            empresaId,
          })
        }
        store.dispatch('servicoFrete/save', servicoFrete).then(() => {
          appCarregando.hide()
          swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'O servico foi salvo!',
            showConfirmButton: true,
          })
        }).catch(e => {
          appCarregando.hide()
          swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: `${e}`,
            showConfirmButton: true,
          })
        })
      } catch (e) {
        appCarregando.hide()
        swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: `${e}`,
          showConfirmButton: true,
        })
      }
    })

    const tableColumns = [
      { key: 'servico', label: 'Nome do Serviço', thStyle: { width: '16rem' } },
      { key: 'adicionalTempoEntrega', label: 'Adicionar dias a mais no prazo de entrega' },
    ]

    return {
      ...toRefs(state),
      ...utils,
      tableColumns,
      onSubmit,
    }
  },
}

</script>
<style lang="">
  
</style>