<template>
  <b-card style="height:auto">
    <span>Informações de contrato</span>
  </b-card>
</template>

<script>
export default {
  name: 'ClienteContrato',
}
</script>

<style scoped>

</style>