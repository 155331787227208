import HttpClient from './http-clients/portaljad'

const baseUrl = 'envios'

export default class EnviosService {
  static async findAll() {
    try {
      const { data } = await HttpClient.get(baseUrl)
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(envioId) {
    try {
      const { data } = await HttpClient.get([baseUrl, envioId].join('/'))
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async save(envio) {
    try {
      const { data } = await HttpClient.post(baseUrl, envio)
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async edit(envioId, envio) {
    try {
      const { data } = await HttpClient.put([baseUrl, envioId].join('/'), envio)
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async deleteById(envioId) {
    try {
      await HttpClient.delete([baseUrl, envioId].join('/'))
    } catch (error) {
      throw error.response.data
    }
  }
}