<template lang="">
  <div
    v-if="showInfo"
    class="info"
    @mouseover="hoover = true"
  >
    <label class="infoLabel">Informações</label>
    <feather-icon
      icon="InfoIcon"
      size="21"
      class="text-danger stroke-current"
    />
    <div
      v-if="hoover"
      class="popUpInfoBox tail"
    >
      <div class="popUpHeader">
        <label> Legenda </label>
        <feather-icon
          icon="XIcon"
          size="21"
          cursor="pointer"
          class="text-danger stroke-current iconPopUp"
          @click="hoover = false"
        />
      </div>
      <div class="popUpInfo">
        <div
          v-for="(item, index) in infoValue"
          :key="index"
          class="d-flex flex-column align-items-center "
        >
          <div class="col-12 legendaHeader">
            <label class="">
              {{ item.text }}
            </label>
          </div>
          <div
            class="d-flex col-11 flex-column align-content-start"
          >
            <div
              v-for="(box, ind) in item.info"
              :key="ind"
              class="legendaText"
            >
              <label>{{ box.value ? box.value + ' -' : '' }} {{ box.text }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, computed, toRefs, watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default {
  name: 'InfoSideBar',
  setup() {
    const { skin } = useAppConfig()
    const infoSideBarStore = createNamespacedHelpers('infoSideBar')
    const isDark = computed(() => skin.value === 'dark')

    const state = reactive({
      infoValue: infoSideBarStore.useGetters(['legendas']).legendas,
      hoover: false,
      showInfo: false,
    })

    watch(() => state.infoValue, () => {
      if (state.infoValue && state.infoValue.length > 0) {
        state.hoover = false
        state.showInfo = true
      } else {
        state.hoover = false
        state.showInfo = false
      }
    })

    return {
      ...toRefs(state),
      skin,
      isDark,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/base/components/info-side-bar.scss';

</style>