<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar Novo Perfil
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Nome"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Nome"
              label-for="nome"
            >
              <b-form-input
                id="nome"
                v-model="perfil.nome"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Administrador"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import _ from 'lodash'
import { reactive, toRefs } from '@vue/composition-api'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'IndexVue',
  props: {
    active: {
      required: true,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const currentUser = JSON.parse(localStorage.getItem('userName'))
    
    const blankPerfil = {
      nome: null,
      ativo: 1,
      empresaId: currentUser.empresaId,
    }

    const state = reactive({
      perfil: _.cloneDeep(blankPerfil),
    })

    const resetPerfil = () => {
      state.perfil = _.cloneDeep(blankPerfil)
    }

    const appLoading = $themeConfig.app.appLoadingImage

    const onSubmit = () => {
      appLoading.show()
      store.dispatch('perfis/save', state.perfil)
        .then(() => {
          appLoading.hide()
          emit('refresh')
          emit('update:active', false)
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Perfil inserido com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPerfil)

    return {
      ...toRefs(state),
      onSubmit,
      // FORM CONFIG
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style scoped>

</style>