import store from '@/store'
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default () => {
  const usuariosStore = createNamespacedHelpers('usuarios')
  const state = reactive({
    usuarios: usuariosStore.useGetters(['usuarios']).usuarios,
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    perPageOptions: [10, 25, 50, 100],
  })

  watch(() => state.usuarios, value => {
    if (value && value.length) {
      state.totalRows = value.length
    } else {
      state.totalRows = 0
    }
  })

  const tableColumns = [
    { key: 'acessoLoginCliente', sortable: false, label: 'Acesso rápido' },
    { key: 'email', sortable: true, label: 'Login' },
    { key: 'nivel', label: 'Nível', sortable: true },
    { key: 'acessar', sortable: false },
    { key: 'acoes', label: 'Ações', sortable: false },
  ]

  const search = ref('')
  const sortBy = ref('login')
  const isSortDirDesc = ref(false)
  const users = ref([])
  const userAddSidebarActive = ref(false)
  const clienteId = window.location.href.split('/').pop()

  const refresh = () => {
    search.value = ''
    state.currentPage = 1
    store.dispatch('usuarios/findAllByCliente', clienteId)
      .then(response => {
        users.value = response
      })
  }

  const acessoPrincipalUnico = userId => {
    state.usuarios.forEach(u => {
      if (u.usuarioId !== userId) {
        // eslint-disable-next-line no-param-reassign
        u.acessoLoginCliente = false
      }
    })
  }

  const deletar = user => {
    store.dispatch('usuarios/delete', user.userId).then(() => refresh())
  }

  const onFiltered = filteredItems => {
    state.currentPage = 1
    state.totalRows = filteredItems.length
  }

  refresh()
  
  return {
    ...toRefs(state),
    clienteId,
    users,
    tableColumns,
    search,
    sortBy,
    isSortDirDesc,
    userAddSidebarActive,
    // funções
    refresh,
    deletar,
    onFiltered,
    acessoPrincipalUnico,
  }
}
