<template>
  <validation-provider
    #default="validationContext"
    :name="label"
    :rules="{ required: required }"
  >
    <b-form-group
      :label="label"
      :label-for="id"
    >
      <b-form-datepicker
        :id="id"
        v-model="date"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        locale="pt-BR"
        :placeholder="placeholder"
        start-weekday="0"
        class="mb-1, form-control"
        :state="getValidationState ? getValidationState(validationContext) : null"
        :date-info-fn="dateDisabled"
        :min="min"
        :max="max"
        @input="$emit('update', date)"
      />
      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
export default {
  name: 'FormDatepicker',
  props: {
    id: {
      type: String,
      default: 'FormDatepicker',
    },
    label: {
      type: String,
      default: '',
    },
    defaultDate: {
      type: String,
      default: null,
    },
    getValidationState: {
      type: Function,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '00/00/0000',
    },
    min: {
      type: [Date, String],
      default: null,
    },
    max: {
      type: [Date, String],
      default: null,
    },
    dateDisabledList: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      date: null,
    }
  },
  created() {
    this.date = this.$props.defaultDate
  },
  methods: {
    clear() {
      this.date = null
    },
    dateDisabled() {
    // dateDisabled(ymd, date) {
      // const ano = date.getFullYear()
      // const mes = date.getMonth() + 1
      // const anoMes = `${ano}-${mes < 10 ? `0${mes}` : mes}`
      // const dia = date.getDate().toString()
      // const diaString = `${dia < 10 ? `0${dia}` : dia}`
      // if (this.dateDisabledList[anoMes]) {
      //   return this.dateDisabledList[anoMes] && this.dateDisabledList[anoMes].includes(diaString) ? 'selected-date-calendar' : ''
      // }
      return ''
    },
  },
}
</script>

<style>
.b-calendar-grid-caption {
  text-transform: capitalize !important;
}
.selected-date-calendar {
  border-radius: 50% !important;
  border: 0 !important;
  background-color: #ffe4ca;
}
</style>
