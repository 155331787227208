import HttpClient from './http-clients/portaljad'

const baseUrl = '/overview'

export default class OverviewEnviosService {
  static async pesquisa(filtros) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'filter'].join('/'), filtros)
      if (data.body) {
        return data.body
      } return null
    } catch (error) {
      throw error.response.data
    }
  }

  static async pesquisaCardTicketMedio(filtros) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'ticket-medio'].join('/'), filtros)
      if (data.body) {
        return data.body
      } return null
    } catch (error) {
      throw error.response.data
    }
  }

  static async pesquisaCardClientesAtivos(filtros) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'clientes-ativos'].join('/'), filtros)
      if (data.body) {
        return data.body
      } return null
    } catch (error) {
      throw error.response.data
    }
  }

  static async pesquisaCardGeracaoEtiquetas(filtros) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'geracao-etiquetas'].join('/'), filtros)
      if (data.body) {
        return data.body
      } return null
    } catch (error) {
      throw error.response.data
    }
  }

  static async pesquisaCardFaturamentoDia(filtros) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'faturamento-dia'].join('/'), filtros)
      if (data.body) {
        return data.body
      } return null
    } catch (error) {
      throw error.response.data
    }
  }

  static async pesquisaCardEnviosServico(filtros) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'envios-servico'].join('/'), filtros)
      if (data.body) {
        return data.body
      } return null
    } catch (error) {
      throw error.response.data
    }
  }
}