import HttpClient from './http-clients/portaljad'

const baseUrl = 'departamento'

export default class DepartamentoService {
  static async findAll() {
    try {
      const { data } = await HttpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAllByClienteId(clienteId) {
    try {
      const { data } = await HttpClient.get([baseUrl, 'departamentos', clienteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(clienteDepartamentoId) {
    try {
      if (!clienteDepartamentoId) {
        return undefined
      }
      const { data } = await HttpClient.get([baseUrl, clienteDepartamentoId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async save(multipart) {
    try {
      const { data } = await HttpClient.post(baseUrl, multipart, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async edit(clienteDepartamentoId, multipart) {
    try {
      const { data } = await HttpClient.put([baseUrl, clienteDepartamentoId].join('/'), multipart, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async delete(clienteDepartamentoId) {
    try {
      const { data } = await HttpClient.delete([baseUrl, clienteDepartamentoId].join('/'))
      return data
    } catch (error) {
      throw error.response.data
    }
  }
}
