import HttpClient from './http-clients/portaljad'

const baseUrl = 'impostos'

export default class ImpostosService {
  static async findAll() {
    try {
      const { data } = await HttpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findById(nome) {
    try {
      const { data } = await HttpClient.get([baseUrl, nome].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findValorByNome(impostoId) {
    try {
      const { data } = await HttpClient.get([`${baseUrl}/valor`, impostoId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async uploadMultipart(multipartForm) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'with-sheet'].join('/'), multipartForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async desativarImposto(tipoImposto) {
    try {
      await HttpClient.put([baseUrl, 'desativar', tipoImposto].join('/'))
      return true
    } catch (error) {
      throw error.response.data
    }
  }
}