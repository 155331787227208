import MovimentacaoEventoService from '@/service/MovimentacaoEventoService'

export default {
  namespaced: true,
  state: {
    cte: '',
    dataEnvio: '',
    eventos: [],
    selected: {},
  },
  getters: {
    getEventos(state) {
      return state.eventos
    },
    getCte(state) {
      return state.cte
    },
    getDataEnvio(state) {
      return state.dataEnvio
    },
    getSelected(state) {
      return state.selected
    },
  },
  mutations: {
    SET_EVENTOS(state, eventos) {
      state.eventos = eventos
    },
    SET_CTE(state, cte) {
      state.cte = cte
    },
    SET_ENVIO(state, dataEnvio) {
      state.dataEnvio = dataEnvio
    },
    UPDATE_SELECTED(state, eventos) {
      state.selected = eventos
    },
  },
  actions: {
    async findByCte({ commit }, cte) {
      const eventos = await MovimentacaoEventoService.findByCte(cte)
      commit('SET_EVENTOS', eventos)
      return eventos
    },
    async findByFilter({ commit }, filter) {
      const eventos = await MovimentacaoEventoService.findByFilter(filter)
      commit('SET_EVENTOS', eventos)
      return eventos
    },
    async setCte({ commit }, cte) {
      commit('SET_CTE', cte)
    },
    async setDataEnvio({ commit }, dataEnvio) {
      commit('SET_ENVIO', dataEnvio)
    },
    async setSelected({ commit }, eventos) {
      commit('UPDATE_SELECTED', eventos)
    },
  },
}