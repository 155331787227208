<template>
  <div>
    <b-card 
      no-body 
      class="mb-0"
    >
      <div class="m-2">
        <validation-observer #default="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <b-row align-v="center">
              <b-col cols="6">
                <validation-provider 
                  #default="validationContext"
                  v-model="dateRange"
                  name="date-range"
                >
                  <date-range :date-range.sync="dateRange" />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <div>
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mb-sm-0 mr-0 mr-sm-1"
                  >
                    Buscar
                    <feather-icon 
                      class="ml-1 d-md-none d-xl-inline d-inline" 
                      icon="SearchIcon"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
    <b-row class="my-2">
      <b-col sm="4">
        <card-info
          :header="cardsHeaders.TickedMedio"
          :detalhes="detalhesCardTaxa ? detalhesCardTaxa.valorTabela : []"
          :titulo="detalhesCardTaxa ? detalhesCardTaxa.titulo : ''"
          subtitulo="Ticket Médio"
          :loading="loadTicketMedio"
          icone="DollarSignIcon"
          bg-icone-color="#004423"
        />
      </b-col>
      <b-col sm="4">
        <card-info
          :header="cardsHeaders.ClientesAtivos"
          :detalhes="detalhesCardClientesAtivos ? detalhesCardClientesAtivos.valorTabela : []"
          :titulo="detalhesCardClientesAtivos ? detalhesCardClientesAtivos.titulo : ''"
          :loading="loadClientesAtivos"
          subtitulo="Clientes Ativos"
          icone="UsersIcon"
          bg-icone-color="#367F5A"
        />
      </b-col>
      <b-col sm="4">
        <card-info
          :header="cardsHeaders.GeracaodeEtiquetas"
          :detalhes="detalhesCardGeracaoEtiquetas ? detalhesCardGeracaoEtiquetas.valorTabela : []"
          :titulo="detalhesCardGeracaoEtiquetas ? detalhesCardGeracaoEtiquetas.titulo : ''"
          :loading="loadGeracaoEtiquetas"
          subtitulo="Geração de Etiquetas|Cliente"
          icone="BoxIcon"
          bg-icone-color="#31394F"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="7">
        <graficoLinha
          :faturamentos="dadosFaturamentoDia.totalFaturamento"
          :faturamentos-por-dia="dadosFaturamentoDia.faturamentoPorDia"
          :loading="loadFaturamentoDia"
        />
      </b-col>
      <b-col md="5">
        <graficoRosca
          :series="dadosEnvioServico.series"
          :labels="dadosEnvioServico.labels"
          :loading="loadEnvioServico"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { onBeforeMount, onBeforeUnmount, reactive, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import store from '@/store'

import DateRange from '@/components/form/DateRange.vue'
import utils from '@/helpers/utils'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import moment from 'moment'
import GraficoLinha from './components/graficoLinha.vue'
import GraficoRosca from './components/graficoRosca.vue'
import CardInfo from './components/cardInfo.vue'

export default {
  name: 'Overview',
  components: { DateRange, CardInfo, GraficoLinha, GraficoRosca },
  setup() {
    const overviewStore = createNamespacedHelpers('overview')
    const { limparDados } = overviewStore.useActions(['limparDados'])

    const state = reactive({
      dateRange: {
        startDate: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        endDate: utils.dateRangeComTimeZone(),
      },
      detalhesCardTaxa: overviewStore.useGetters(['dadosTicketMedio']).dadosTicketMedio,
      detalhesCardClientesAtivos: overviewStore.useGetters(['dadosClientesAtivos']).dadosClientesAtivos,
      detalhesCardGeracaoEtiquetas: overviewStore.useGetters(['dadosGeracaoEtiqueta']).dadosGeracaoEtiqueta,
      dadosFaturamentoDia: overviewStore.useGetters(['dadosFaturamentoDia']).dadosFaturamentoDia,
      dadosEnvioServico: overviewStore.useGetters(['dadosEnvioServico']).dadosEnvioServico,
      loadTicketMedio: overviewStore.useGetters(['loadTicketMedio']).loadTicketMedio,
      loadClientesAtivos: overviewStore.useGetters(['loadClientesAtivos']).loadClientesAtivos,
      loadGeracaoEtiquetas: overviewStore.useGetters(['loadGeracaoEtiquetas']).loadGeracaoEtiquetas,
      loadFaturamentoDia: overviewStore.useGetters(['loadFaturamentoDia']).loadFaturamentoDia,
      loadEnvioServico: overviewStore.useGetters(['loadEnvioServico']).loadEnvioServico,
    })

    const sendRequest = filtros => {
      store.dispatch('overview/findCardTickedMedio', filtros)
      store.dispatch('overview/findCardClientesAtivos', filtros)
      store.dispatch('overview/findCardGeracaoEtiquetas', filtros)

      store.dispatch('overview/findCardFaturamentoDia', filtros)
      store.dispatch('overview/findCardEnviosServico', filtros)
    }

    const cardsHeaders = {
      TickedMedio: ['Serviço', 'Ticket Médio'],
      ClientesAtivos: ['Cliente', 'Qtd.', 'Ticket Médio'],
      GeracaodeEtiquetas: ['Cliente', 'Etq. Geradas', 'Etq. Reversa'],
    }

    const onSubmit = () => {
      limparDados()
      const filtros = {
        dataInicial: state.dateRange.startDate,
        dataFinal: state.dateRange.endDate,
        reversaCliente: state.reversaCliente,
      }
      sendRequest(filtros)
    }

    onBeforeUnmount(() => {
      limparDados()
    })

    const totalGeracaoEtiquetas = dados => {
      const etqGeradas = dados.filter(item => item.etiquetasGeradas !== null
      || item.etiquetasGeradas !== 0
      || item.etiquetasGeradasReversa !== null
      || item.etiquetasGeradasReversa !== 0).length
      return etqGeradas
    }

    onBeforeMount(() => {
      onSubmit()
    })
    const { getValidationState } = formValidation()

    return {
      ...toRefs(state),
      cardsHeaders,
      onSubmit,
      getValidationState,
      totalGeracaoEtiquetas,
    }
  },
}
</script>

<style scoped></style>