import HttpClient from './http-clients/portaljad'

const baseUrl = 'movimentacoes'

export default class MovimentoService {
  static async findAllVolumes(jadCode) {
    try {
      const { data } = await HttpClient.get([baseUrl, jadCode].join('/volumes/'))
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAllObjetosAferidos(usuarioId) {
    try {
      const { data } = await HttpClient.get([baseUrl, usuarioId].join('/objetos-aferidos/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAllObjetosAferidosByFilter(filter) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'objetos-aferidos'].join('/'), filter)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAllObjetosAferidosExportacao(movIds) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'objetos-aferidos-exportacao'].join('/'), movIds)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async editAfericao(movimentacaoId, volume) {
    try {
      const { data } = await HttpClient.put([baseUrl, 'edit-afericao', movimentacaoId].join('/'), volume)
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async editVolume(movimentacaoId, volume) {
    try {
      const { data } = await HttpClient.put([baseUrl, 'edit-volume', movimentacaoId].join('/'), volume)
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async exportar(movimentacoes) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'exportar'].join('/'), movimentacoes, { responseType: 'arraybuffer' })
      if (data.byteLength > 0) {
        const blobPdf = new Blob([data], { type: 'text/csv' })
        const url = URL.createObjectURL(blobPdf)
        return url
      }
      return null
    } catch (error) {
      return null
    }
  }

  static async reexportar(movimentacoes) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'reexportar'].join('/'), movimentacoes, { responseType: 'arraybuffer' })
      if (data.byteLength > 0) {
        const blobPdf = new Blob([data], { type: 'text/csv' })
        const url = URL.createObjectURL(blobPdf)
        return url
      }
      return null
    } catch (error) {
      return null
    }
  }

  static async estornarAfericao(movimentacoes) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'estornar-afericao'].join('/'), movimentacoes)
      return data
    } catch (error) {
      throw error.response.data
    }
  }

  static async gerarEtiqueta(volume) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'gerar-etiqueta'].join('/'), volume, { responseType: 'arraybuffer' })
      if (data.byteLength > 0) {
        const blobPdf = new Blob([data], { type: 'application/pdf' })
        const url = URL.createObjectURL(blobPdf)
        return url
      }
      return null
    } catch (error) {
      return null
    }
  }
}