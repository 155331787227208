import store from '@/store'
import { ref, watch, reactive, toRefs } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'

export default () => {
  const tableColumns = [
    { key: 'nomeFantasia', sortable: true, label: 'Nome' },
    { key: 'razaoSocial', sortable: true },
    { key: 'numeroDocumento', sortable: true, label: 'CNPJ/CPF', thStyle: { width: '14.6rem' } },
    { key: 'email', sortable: true },
    { key: 'telefone', sortable: true, thStyle: { width: '12.8rem' } },
    { key: 'validação', sortable: false, tdClass: 'text-center' },
    { key: 'acesso', sortable: false, tdClass: 'text-center' },
    { key: 'ações', sortable: false },
  ]

  const { appCarregando } = $themeConfig.app
  const mostrarInativos = ref(false)
  const search = ref('')
  const sortBy = ref('nomeFantasia')
  const isSortDirDesc = ref(false)
  const clientes = ref([])
  const clienteAddSidebarActive = ref(false)

  const state = reactive({
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    perPageOptions: [10, 25, 50, 100],
  })

  watch(() => clientes.value, value => {
    if (value && value.length) {
      state.totalRows = value.length
    } else {
      state.totalRows = 0
    }
  })

  const refresh = () => {
    appCarregando.show()
    state.currentPage = 1
    search.value = ''
    store.dispatch('clientes/findAll')
      .then(response => {
        appCarregando.hide()
        if (mostrarInativos.value) {
          clientes.value = response
        } else {
          clientes.value = response.filter(item => item.ativo)
        }
      })
  }

  watch(mostrarInativos, () => {
    refresh()
  })

  const deletar = cliente => {
    store.dispatch('clientes/delete', cliente.clienteId).then(() => refresh())
  }

  const onFiltered = filteredItems => {
    state.currentPage = 1
    state.totalRows = filteredItems.length
  }

  refresh()
  return {
    ...toRefs(state),
    mostrarInativos,
    clientes,
    tableColumns,
    search,
    sortBy,
    isSortDirDesc,
    clienteAddSidebarActive,
    // funções
    refresh,
    deletar,
    onFiltered,
  }
}
