import store from '@/store'
import { ref, reactive, toRefs, watch } from '@vue/composition-api'

export default () => {
  const { empresaId } = JSON.parse(localStorage.getItem('userName'))
  // TABLE CONFIG
  const search = ref('')
  const sortBy = ref('nome')
  const isSortDirDesc = ref(false)
  const tableColumns = [
    { key: 'nome', sortable: true },
    { key: 'ações', sortable: false },
  ]
  const perfis = ref([])

  const state = reactive({
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    perPageOptions: [10, 25, 50, 100],
  })

  watch(() => perfis.value, value => {
    state.currentPage = 1
    if (value && value.length) {
      state.totalRows = value.length
    } else {
      state.totalRows = 0
    }
  })

  // PAGE CONFIG
  const perfilAddSidebarActive = ref(false)

  const refresh = () => {
    store.dispatch('perfis/findAll', empresaId)
      .then(response => {
        perfis.value = response
      })
  }

  const onFiltered = filteredItems => {
    state.currentPage = 1
    state.totalRows = filteredItems.length
  }
  
  refresh()
  return {
    ...toRefs(state),
    // TABLE CONFIG
    perfis,
    search,
    sortBy,
    isSortDirDesc,
    tableColumns,
    //
    onFiltered,
    // PAGE CONFIG
    perfilAddSidebarActive,
    refresh,
  }
}