<template>
  <div>
    <template v-if="cliente">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <!--INFO-->
          <cliente-info :cliente="cliente"/>
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <!--PLANO-->
          <cliente-contrato/>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <!-- TIMELINE-->
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <!--PERMISSOES-->
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { reactive, toRefs } from '@vue/composition-api'
import store from '@/store'
import ClienteInfo from '../components/cards/info'
import ClienteContrato from '../components/cards/contrato'

export default {
  name: 'VisualizarClientes',
  components: { ClienteInfo, ClienteContrato },

  setup(props, { root }) {
    const route = root.$route
    const { clienteId } = route.params
    const state = reactive({
      cliente: null,
    })

    const refresh = () => {
      store.dispatch('clientes/findById', clienteId).then(() => {
        const [cliente] = Array.isArray(store.getters['clientes/selected'])
          ? store.getters['clientes/selected']
          : [store.getters['clientes/selected']]
        state.cliente = cliente
      }).catch(() => {
        state.cliente = undefined
      })
    }
    refresh()
    return { ...toRefs(state) }
  },
}
</script>

<style scoped>

</style>
