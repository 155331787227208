import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import clientes from './clientes'
import departamentos from './departamentos'
import firebase from './firebase'
import perfis from './perfis'
import permissoes from './permissoes'
import servicos from './servicos'
import movimentacoes from './movimentacoes'
import movimentos from './movimentos'
import tarifas from './tarifas'
import taxas from './taxas'
import colaboradores from './colaboradores'
import usuarios from './usuarios'
import envios from './envios'
import regioes from './regioes'
import regioesJadlog from './regioes-jadlog'
import empresa from './empresa'
import pesquisaEnvios from './pesquisa-envios'
import overview from './overview'
import infoSideBar from './info-side-bar'
import movimentacaoEvento from './movimentacao-evento'
import monitorJadlog from './monitor-jadlog'
import curvaAbc from './curva-abc'
import faturasCliente from './faturas-cliente'
import impostos from './impostos'
import clienteFtp from './cliente-ftp'
import logs from './logs'
import faturasScc4 from './faturas-scc4'
import clienteEmailConfig from './cliente-email-config'
import servicoFrete from './servico-frete'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    regioes,
    appConfig,
    verticalMenu,
    clientes,
    departamentos,
    firebase,
    perfis,
    permissoes,
    servicos,
    movimentacoes,
    movimentos,
    tarifas,
    colaboradores,
    usuarios,
    envios,
    regioesJadlog,
    empresa,
    pesquisaEnvios,
    overview,
    taxas,
    infoSideBar,
    movimentacaoEvento,
    monitorJadlog,
    curvaAbc,
    faturasCliente,
    impostos,
    logs,
    clienteFtp,
    faturasScc4,
    clienteEmailConfig,
    servicoFrete,
  },
  strict: process.env.DEV,
})
