<template>
  <div>
    <b-card>
      <b-form-row>
        <b-col
          md="12"
        >      
          <validation-provider
            #default="validationContext"
            name="Token API"
            :rules="{ required: true, min: 30 }"
          >
            <b-form-group
              label="Token API"
              label-for="tokenAPI"
            >
              <b-form-input
                id="token-api"
                v-model="cliente.tokenApi"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Token API"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="Unidade de Origem"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Unidade de Origem"
              label-for="unidadeOrigem"
            >
              <b-form-input
                id="unidade-origem"
                v-model="cliente.unidadeOrigem"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Unidade de Origem"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="CFOP-NFE"
            :rules="{ required: false }"
          >
            <b-form-group
              label="CFOP-NFE"
              label-for="cfopNfe"
            >
              <b-form-input
                id="cfop-nfe"
                v-model="cliente.cfopNfe"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder="cfop-nfe"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="Tipo de Frete"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Cód. Tipo de Frete"
              label-for="tipoFrete"
            >
              <v-select
                id="tipo-frete"
                v-model="cliente.tipoFrete"
                :options="tipoFreteOptions"
                :state="getValidationState(validationContext)"
                :reduce="val=>val.value"
                :clearable="false"
              />
              <span
                v-if="validationContext.errors[0]"
                class="undermessage-error text-danger"
              >
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="Tipo da Coleta"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Tipo da Coleta"
              label-for="tipoColeta"
            >
              <v-select 
                id="tipo-coleta"
                v-model="cliente.tipoColeta"
                :options="coletaOptions"
                :state="getValidationState(validationContext)"
                :reduce="val=>val.value"
                :clearable="false"
              />
              <span
                v-if="validationContext.errors[0]"
                class="undermessage-error text-danger"
              >
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="Conta Corrente"
            :rules="{ required: true, regex: /^\d{6}-\d$/ }"
            mode="eager"
          >
            <b-form-group
              label="Conta Corrente"
              label-for="contaCorrente"
            >
              <b-form-input
                id="contaCorrente"
                v-model="cliente.contaCorrente"
                v-cleave="cleaveOptions['contaCorrente']"
                :state="getValidationState(validationContext)"
                trim
                maxlength="8"
                placeholder="Conta Corrente"
              />
              <b-form-invalid-feedback>
                {{ validationContext && validationContext.errors ? 
                  (validationContext.errors.length > 0 && validationContext.errors[0].includes('válido') 
                    ? 'O valor deve estar no formato 123456-7' : validationContext.errors[0]) : '' }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="Nº contrato"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Nº contrato"
              label-for="numeroContrato"
            >
              <b-form-input
                id="numeroContrato"
                v-model="cliente.numeroContrato"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Numero Contrato"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="Conta Corrente Reversa"
            :rules="{ required: true, regex: /^\d{6}-\d$/ }"
            mode="eager"
          >
            <b-form-group
              label="Conta Corrente Reversa"
              label-for="contaCorrenteReversa"
            >
              <b-form-input
                id="contaCorrenteReversa"
                v-model="cliente.contaCorrenteReversa"
                v-cleave="cleaveOptions['contaCorrente']"
                :state="getValidationState(validationContext)"
                trim
                maxlength="8"
                placeholder="Conta Corrente Reversa"
              />
              <b-form-invalid-feedback>
                {{ validationContext && validationContext.errors ? 
                  (validationContext.errors.length > 0 && validationContext.errors[0].includes('válido') 
                    ? 'O valor deve estar no formato 123456-7' : validationContext.errors[0]) : '' }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="Usa Smart Label"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Usa Smart Label"
              label-for="usaSmartLabel"
            >
              <v-select 
                id="usaSmartLabel"
                v-model="cliente.tipoEtiqueta"
                :options="tipoEtiquetaOptions"
                :state="getValidationState(validationContext)"
                :reduce="val=>val.value"
                :clearable="false"
              />
              <span
                v-if="validationContext.errors[0]"
                class="undermessage-error text-danger"
              >
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="Enviar Peso"
            :rules="{}"
          >
            <b-form-group
              label="Enviar Peso"
              label-for="nao-enviar-peso"
            >
              <v-select 
                id="nao-enviar-peso"
                v-model="cliente.naoEnviarPeso"
                :options="booleanInverso"
                :state="getValidationState(validationContext)"
                :reduce="val=>val.value"
                :clearable="false"
              />
              <span
                v-if="validationContext.errors[0]"
                class="undermessage-error text-danger"
              >
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="Enviar Dimensões"
            :rules="{}"
          >
            <b-form-group
              label="Enviar Dimensões"
              label-for="nao-enviar-dimensoes"
            >
              <v-select 
                id="nao-enviar-dimensoes"
                v-model="cliente.naoEnviarDimensoes"
                :options="booleanInverso"
                :state="getValidationState(validationContext)"
                :reduce="val=>val.value"
                :clearable="false"
              />
              <span
                v-if="validationContext.errors[0]"
                class="undermessage-error text-danger"
              >
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="Cotação de frete via API"
            :rules="{}"
          >
            <b-form-group
              label="Cotação de frete via API"
              label-for="cotacaoFreteViaAPI"
              class="mb-0"
            >
              <v-select 
                id="cotacao-frete-via-api"
                v-model="cliente.freteApi"
                :options="trueFalseOptions"
                :state="getValidationState(validationContext)"
                :reduce="val=>val.value"
                :clearable="false"
              />
              <span
                v-if="validationContext.errors[0]"
                class="undermessage-error text-danger"
              >
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="tratativa"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Usuário para Tratativa"
              label-for="usuarioJadlog"
            >
              <b-form-input
                id="usuarioJadlog"
                v-model="cliente.usuarioJadlog"
                :state="getValidationState(validationContext)"
                trim
                placeholder="jadlog.usuário"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="12"
          class="d-flex justify-content-start"
        >
          <b-button
            variant="primary"
            class="my-1 mb-sm-0"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="validarDadosApi"
          >
            Validar Dados
          </b-button>
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import { avatarText } from '@core/utils/filter'
import { $themeConfig } from '@themeConfig'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import utils from '@/helpers/utils'
import cleaveOptions from '@/libs/cleave/options'
import ClientesEditarComposition from '../clientes-editar.composition'

export default {
  name: 'TabDadosAPI',
  props: { 
    data: {
      required: true,
      type: undefined,
    },
  },
  data() {
    return {
      tipoFreteOptions: [
        { value: '0', label: '0 - Normal' },
        { value: '1', label: '1 - Subcontratação' },
        { value: '2', label: '2 - Redespacho' },
        { value: '3', label: '3 - Redespacho Intermediário' },
      ],
      coletaOptions: [
        { value: 's', label: 'S - Solicitação Eletrônica' },
        { value: 'k', label: 'K - Solicitação no remetente' },
      ],
      trueFalseOptions: [
        { value: true, label: 'Sim' },
        { value: false, label: 'Não' },
      ],
      booleanInverso: [
        { value: true, label: 'Não' },
        { value: false, label: 'Sim' },
      ],
      tipoEtiquetaOptions: [
        { value: 'JADCODE', label: 'Não' },
        { value: 'NORMAL', label: 'Sim - Normal' },
        { value: 'PDF', label: 'Sim - PDF' },
      ],
    }
  },
  setup() {
    const { appCarregando } = $themeConfig.app
    const { enviaEtiquetaTeste } = ClientesEditarComposition()
    const validarDadosApi = () => {
      appCarregando.show()
      enviaEtiquetaTeste().then(dados => {
        appCarregando.hide()
        if (dados.body && dados.body.includes('fora do ar')) {
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: dados.body,
                icon: 'AlertCircleIcon',
                variant: 'primary',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
        } else {
          swal.fire({
            icon: (dados.success ? 'success' : 'info'),
            title: `${dados.success ? 'Sucesso! Dados validados.' : 'Atenção!'}`,
            text: dados.body,
            showConfirmButton: true,
          })
        }
      })
    }
    return {
      ...ClientesEditarComposition(),
      avatarText,
      cleaveOptions,
      ...utils,
      validarDadosApi,
    }
  } }
</script>

<style lang="scss">
</style>
