import HttpClient from './http-clients/portaljad'

const baseUrl = 'cliente-email-config'

export default class ClienteEmailConfig {
  static async findByClienteId(clienteId) {
    try {
      const { data } = await HttpClient.get([baseUrl, clienteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}