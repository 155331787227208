import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default () => {
  const tarifasStore = createNamespacedHelpers('tarifas')
  const tableColumns = [
    { key: 'tarifaId', sortable: true },
    { key: 'nomeTarifa', sortable: true },
    { key: 'nomeServico', sortable: true, label: 'Serviço' },
    { key: 'dataVigencia', sortable: true, label: 'Data Vigência' },
    { key: 'dataImportacao', sortable: true, label: 'Data Importação' },
    { key: 'ações', sortable: false },
  ]

  const state = reactive({
    tarifas: tarifasStore.useGetters(['tarifas']).tarifas,
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    perPageOptions: [10, 25, 50, 100],
  })

  watch(() => state.tarifas, value => {
    state.currentPage = 1
    if (value && value.length) {
      state.totalRows = value.length
    } else {
      state.totalRows = 0
    }
  })

  const search = ref('')
  const sortBy = ref('nomeTarifa')
  const isSortDirDesc = ref(false)
  const addTarifaSidebarActive = ref(false)

  const onFiltered = filteredItems => {
    state.currentPage = 1
    state.totalRows = filteredItems.length
  }

  return {
    ...toRefs(state),
    // TABLE CONFIGS,
    search,
    sortBy,
    isSortDirDesc,
    tableColumns,
    // SIDEBAR
    addTarifaSidebarActive,
    onFiltered,
  }
}