export default {
  namespaced: true,
  state: {
    legendas: [],
  },
  getters: {
    legendas(state) {
      return state.legendas
    },
  },
  mutations: {
    UPDATE_LEGENDAS(state, legendas) {
      state.legendas = legendas
    },
  },
  actions: {
    resetLegenda({ commit }) {
      commit('UPDATE_LEGENDAS', [])
    },
    setLegenda({ commit }, legendas) {
      commit('UPDATE_LEGENDAS', legendas)
    },
  },
}