<template>
  <div>
    <b-card class="pb-3">
      <h5 class="mb-2">
        Detalhes da remessa
      </h5>
      <b-form-row class="align-items-center">
        <b-col
          cols="3"
        >      
          <validation-provider
            #default="validationContext"
            name="Taxa de embalagem"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Taxa de embalagem"
              label-for="taxaEmbalagem"
            >
              <v-select
                v-model="cliente.taxaEmbalagem"
                :reduce="val=>val.value"
                :options="taxaEmbalagemOptions"
                :clearable="true"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="4">
          <b-form-checkbox
            v-model="cliente.considerarPorVolumes"
            switch
            class="ml-2 mt-1"
          >
            <label class="pl-1 label-switch">Considerar somente datas sem movimento</label>
          </b-form-checkbox>
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import ClientesEditarComposition from '../clientes-editar.composition'

export default {
  name: 'TabDadosFraction',
  props: { 
    data: {
      required: true,
      type: undefined,
    },
  },
  data() {
    return {
      taxaEmbalagemOptions: [
        { label: 'ENV', value: 'ENV' },
        { label: 'C1', value: 'C1' },
        { label: 'C2', value: 'C2' },
        { label: 'C3', value: 'C3' },
        { label: 'C4', value: 'C4' },
        { label: 'C5', value: 'C5' },
        { label: 'C6', value: 'C6' },
      ],
    }
  },
  setup() {
    return {
      ...ClientesEditarComposition(),
      avatarText,
    }
  } }
</script>

<style lang="scss">
</style>
