<template>
  <div>
    <b-card>
      <h5 class="mb-2">
        Midia Social para Tela de Rastreio
      </h5>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlFacebook"
            rules="url-face"
          >
            <b-form-group
              label="Url Facebook"
              label-for="urlFacebook"
            >
              <b-form-input
                id="url-facebook"
                v-model="cliente.urlFacebook"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://www.facebook.com/"
                :disabled="desativarUrls('facebook')"
                @blur="prependHttps('urlFacebook', 'facebook')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('facebook')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlYoutube"
            rules="url-you"
          >
            <b-form-group
              label="Url YouTube"
              label-for="urlYoutube"
            >
              <b-form-input
                id="url-youtube"
                v-model="cliente.urlYoutube"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://www.youtube.com/"
                :disabled="desativarUrls('youtube')"
                @blur="prependHttps('urlYoutube', 'youtube')"
              />
  
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('youtube')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlX"
            rules="url-twitter"
          >
            <b-form-group
              label="Url X"
              label-for="urlX"
            >
              <b-form-input
                id="url-x"
                v-model="cliente.urlX"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://twitter.com/"
                :disabled="desativarUrls('twitter')"
                @blur="prependHttps('urlX', 'twitter')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('twitter')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlInstagram"
            rules="url-insta"
          >
            <b-form-group
              label="Url Instagram"
              label-for="urlInstagram"
            >
              <b-form-input
                id="url-instagram"
                v-model="cliente.urlInstagram"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://www.instagram.com/"
                :disabled="desativarUrls('instagram')"
                @blur="prependHttps('urlInstagram', 'instagram')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('instagram')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlPinterest"
            rules="url-pint"
          >
            <b-form-group
              label="Url Pinterest"
              label-for="urlPinterest"
            >
              <b-form-input
                id="url-pinterest"
                v-model="cliente.urlPinterest"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://br.pinterest.com/"
                :disabled="desativarUrls('pinterest')"
                @blur="prependHttps('urlPinterest', 'pinterest')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('pinterest')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlTiktok"
            rules="url-tik"
          >
            <b-form-group
              label="Url TikTok"
              label-for="urlTiktok"
            >
              <b-form-input
                id="url-tiktok"
                v-model="cliente.urlTiktok"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://www.tiktok.com/"
                :disabled="desativarUrls('tiktok')"
                @blur="prependHttps('urlTiktok', 'tiktok')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('tiktok')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="urlLinkedin"
            rules="url-link"
          >
            <b-form-group
              label="Url LinkedIn"
              label-for="urlLinkedin"
            >
              <b-form-input
                id="url-linkedin"
                v-model="cliente.urlLinkedin"
                trim
                :state="getValidationState(validationContext)"
                placeholder="https://www.linkedin.com/"
                :disabled="desativarUrls('linkedin')"
                @blur="prependHttps('urlLinkedin', 'linkedin')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template v-if="desativarUrls('linkedin')">
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> Somente 5 midias sociais são permitidas
                </small>
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h5 class="mb-2">
        Ativar envio de e-mail com atualização de envio
      </h5>
      <b-row>
        <b-col
          md="12"
          class="pt-1"
        >
          <b-form-checkbox 
            v-model="clieteEmailData.destinatarioAtivo"
            name="check-button"
            size
            switch
          >
            <label class="pl-1 label-switch">Enviar para o email do destinatário (deve estar cadastrado na geração da etiqueta)</label>
          </b-form-checkbox>
        </b-col>
        <b-col
          md="12"
          class="pt-1"
        >
          <b-form-checkbox 
            v-model="clieteEmailData.remetenteAtivo"
            name="check-button"
            size
            switch
          >
            <label class="pl-1 label-switch">Enviar para o email do remetente (deve estar cadastrado no cadastro do cliente)</label>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h5 class="mb-2">
        Gatilhos de notificações
      </h5>
      <b-row>
        <b-col
          md="4"
          class="pt-1"
        >
          <b-form-checkbox 
            v-model="clieteEmailData.situacaoEnviado"
            name="check-button"
            size
            switch
          >
            <label class="pl-1 label-switch">Pedido Enviado</label>
          </b-form-checkbox>
        </b-col>
        <b-col
          md="4"
          class="pt-1"
        >
          <b-form-checkbox 
            v-model="clieteEmailData.situacaoTransito"
            name="check-button"
            size
            switch
          >
            <label class="pl-1 label-switch">Pedido em Transito</label>
          </b-form-checkbox>
        </b-col>
        <b-col
          md="4"
          class="pt-1"
        >
          <b-form-checkbox 
            v-model="clieteEmailData.situacaoBloqueado"
            name="check-button"
            size
            switch
          >
            <label class="pl-1 label-switch">Pedido Bloqueado</label>
          </b-form-checkbox>
        </b-col>
        <b-col
          md="4"
          class="pt-1"
        >
          <b-form-checkbox 
            v-model="clieteEmailData.situacaoProblemaEntrega"
            name="check-button"
            size
            switch
          >
            <label class="pl-1 label-switch">Pedido com Problema na Entrega</label>
          </b-form-checkbox>
        </b-col>
        <b-col
          md="4"
          class="pt-1"
        >
          <b-form-checkbox 
            v-model="clieteEmailData.situacaoExtraviado"
            name="check-button"
            size
            switch
          >
            <label class="pl-1 label-switch">Pedido Extraviado</label>
          </b-form-checkbox>
        </b-col>
        <b-col
          md="4"
          class="pt-1"
        >
          <b-form-checkbox 
            v-model="clieteEmailData.situacaoDevolvido"
            name="check-button"
            size
            switch
          >
            <label class="pl-1 label-switch">Pedido Devolvido</label>
          </b-form-checkbox>
        </b-col>
        <b-col
          md="4"
          class="pt-1"
        >
          <b-form-checkbox 
            v-model="clieteEmailData.situacaoAguardandoRetirada"
            name="check-button"
            size
            switch
          >
            <label class="pl-1 label-switch">Pedido Aguardando Retirada</label>
          </b-form-checkbox>
        </b-col>
        <b-col
          md="4"
          class="pt-1"
        >
          <b-form-checkbox 
            v-model="clieteEmailData.situacaoEntregue"
            name="check-button"
            size
            switch
          >
            <label class="pl-1 label-switch">Pedido Entregue</label>
          </b-form-checkbox>
        </b-col>
        <b-col
          md="4"
          class="pt-1"
        >
          <b-form-checkbox 
            v-model="clieteEmailData.situacaoRotaEntrega"
            name="check-button"
            size
            switch
          >
            <label class="pl-1 label-switch">Pedido em Rota de Entrega</label>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import ClientesEditarComposition from '../clientes-editar.composition'

export default {
  name: 'ConfRastreamento',
  components: {

  },
  props: {
    data: {
      type: undefined,
      required: true,
    },
  },
  computed: {
    ...mapGetters('clienteEmailConfig', ['getClienteEmail']),

    clieteEmailData() {
      return this.getClienteEmail
    },
  },
  setup(props) {
    const state = reactive({
      urlAtivas: [],
      faceValid: false,
    })

    const contUrlAtivas = (val, local) => {
      if (val === null || val === '') {
        const index = state.urlAtivas.indexOf(local)
        if (index !== -1) {
          state.urlAtivas.splice(index, 1)
        }
      } else if (!state.urlAtivas.includes(local)) {
        state.urlAtivas.push(local)
      }
    }

    const prependHttps = (platform, midia) => {
      const value = props.data[platform]
      if (value !== null && value !== '' && !value.includes('https://')) {
        // eslint-disable-next-line no-param-reassign
        props.data[platform] = `https://${props.data[platform]}`
      }
      contUrlAtivas(props.data[platform], midia)
    }

    onMounted(() => {
      if (props.data.urlFacebook != null && props.data.urlFacebook !== '') {
        contUrlAtivas('urlFacebook', 'facebook')
      }
      if (props.data.urlInstagram != null && props.data.urlInstagram !== '') {
        contUrlAtivas('urlInstagram', 'instagram')
      }
      if (props.data.urlLinkedin != null && props.data.urlLinkedin !== '') {
        contUrlAtivas('urlLinkedin', 'linkedin')
      }
      if (props.data.urlYoutube != null && props.data.urlYoutube !== '') {
        contUrlAtivas('urlYoutube', 'youtube')
      }
      if (props.data.urlPinterest != null && props.data.urlPinterest !== '') {
        contUrlAtivas('urlPinterest', 'pinterest')
      }
      if (props.data.urlTiktok != null && props.data.urlTiktok !== '') {
        contUrlAtivas('urlTiktok', 'tiktok')
      }
      if (props.data.urlX != null && props.data.urlX !== '') {
        contUrlAtivas('urlX', 'twitter')
      }
    })

    const desativarUrls = val => {
      if (state.urlAtivas.length > 4) {
        return !state.urlAtivas.includes(val)
      } return false
    }

    return {
      ...ClientesEditarComposition(),
      ...toRefs(state),
      desativarUrls,
      prependHttps,
    }
  },
}
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
