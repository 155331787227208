import httpClient from './http-clients/portaljad'

const baseUrl = 'evento'

export default class MovimentacaoEventoService {
  static async findByCte(cte) {
    try {
      const { data } = await httpClient.get([baseUrl, cte].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
  
  static async findByFilter(filter) {
    try {
      const { data } = await httpClient.post(baseUrl, filter)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}