import FaturasClienteSerivice from '@/service/FaturasClienteSerivice'

export default {
  namespaced: true,
  state: {
    faturas: null,
  },
  getters: {
    getFaturas(state) {
      return state.faturas
    },
  },
  mutations: {
    UPDATE_FATURAS(state, faturas) {
      state.faturas = faturas
    },
    SAVE_FATURA(state, fatura) {
      state.faturas.push(fatura)
    },
    DELETE_FATURA(state, faturaId) {
      if (state.faturas) {
        state.faturas = state.faturas.filter(item => item.id !== faturaId)
      }
    },
  },
  actions: {
    async findAll({ commit }) {
      const faturas = await FaturasClienteSerivice.findAll()
      commit('UPDATE_FATURAS', faturas)
    },
    async delete({ commit }, id) {
      await FaturasClienteSerivice.delete(id)
      commit('DELETE_FATURA', id)
    },
    async save(_, multipart) {
      const data = await FaturasClienteSerivice.save(multipart)
      return data
    },
  },
}
