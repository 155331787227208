import MonitorJadlogService from '@/service/MonitorJadlog'

export default {
  namespaced: true,
  state: {
    data: {},
    exportacao: [],
  },
  getters: {
    data(state) {
      return state.data
    },
    exportacao(state) {
      return state.exportacao
    },
  },
  mutations: {
    UPDATE_DADOS(state, dados) {
      state.data = dados
    },
    UPDATE_EXPORTACAO(state, exportacao) {
      state.exportacao = exportacao
    },
  },
  actions: {
    async findAll({ commit }) {
      const dados = await MonitorJadlogService.findAll()
      commit('UPDATE_DADOS', dados)
    },
    async findForExcel({ commit }, filtros) {
      const exportacao = await MonitorJadlogService.findForExcel(filtros)
      commit('UPDATE_EXPORTACAO', exportacao)
    },
  },
}