import MovimentacoesService from '@/service/MovimentacoesService'

export default {
  namespaced: true,
  state: {
    movimentacoes: [],
    movimentacoesAferidas: [],
    etiquetas: [],
  },
  getters: {
    movimentacoes(state) {
      return state.movimentacoes
    },
    movimentacoesAferidas(state) {
      return state.movimentacoesAferidas
    },
    etiquetas(state) {
      return state.etiquetas
    },
  },
  mutations: {
    UPDATE_MOVIMENTACOES(state, movimentacoes) {
      state.movimentacoes = movimentacoes
    },
    UPDATE_MOVIMENTACOES_AFERIDAS(state, movimentacoesAferidas) {
      state.movimentacoesAferidas = movimentacoesAferidas
    },
    UPDATE_ETIQUETAS(state, etiquetas) {
      state.etiquetas = etiquetas
    },
    SAVE_MOVIMENTACAO(state, movimentacao) {
      state.movimentacoes.push(movimentacao)
    },
    SELECT_ALL_OBJETOS(state, bool) {
      state.movimentacoes = state.movimentacoes.map(mov => ({ ...mov, selected: bool }))
    },
    SELECT_ALL_OBJETOS_ID(state, { bool, id }) {
      state.movimentacoes = state.movimentacoes
        .map(mov => (
          { 
            ...mov, 
            selected: mov.movimentacaoId === id ? bool : mov.selected,
          }
        ))
    },
    SELECT_ALL_AFERIDOS(state, bool) {
      state.movimentacoesAferidas = state.movimentacoesAferidas.map(mov => ({ ...mov, selected: bool }))
    },
    SELECT_AFERIDOS_BY_ID(state, { bool, id }) {
      state.movimentacoesAferidas = state.movimentacoesAferidas
        .map(mov => (
          { 
            ...mov, 
            selected: mov.movimentacaoId === id ? bool : mov.selected,
          }
        ))
    },
  },
  actions: {
    async findAllVolumes(_, jadCode) {
      const response = await MovimentacoesService.findAllVolumes(jadCode)
      return response
    },
    async findAllObjetosAferidos({ commit }, usuarioId) {
      const response = await MovimentacoesService.findAllObjetosAferidos(usuarioId)
      commit('UPDATE_MOVIMENTACOES', response)
      return response
    },
    async editAfericao(_, { movimentacaoId, volume }) {
      const response = await MovimentacoesService.editAfericao(movimentacaoId, volume)
      return response
    },
    async findAfericao({ commit }, filtro) {
      const data = await MovimentacoesService.findAllObjetosAferidosByFilter(filtro)
      commit('UPDATE_MOVIMENTACOES_AFERIDAS', data)
    },
    async findAferiaoExportacao(_, movIds) {
      const data = await MovimentacoesService.findAllObjetosAferidosExportacao(movIds)
      return data
    },
    async editVolume(_, { movimentacaoId, volume }) {
      const response = await MovimentacoesService.editVolume(movimentacaoId, volume)
      return response
    },
    async exportar(_, movimentacoes) {
      const response = await MovimentacoesService.exportar(movimentacoes)
      return response
    },
    async reexportar(_, movimentacoes) {
      const response = await MovimentacoesService.reexportar(movimentacoes)
      return response
    },
    async estornarAfericao(_, movimentacoes) {
      const response = await MovimentacoesService.estornarAfericao(movimentacoes)
      return response
    },
    async gerarEtiqueta({ commit }, volume) {
      const response = await MovimentacoesService.gerarEtiqueta(volume)
      commit('UPDATE_ETIQUETAS', response)
      return response
    },
    selectAll({ commit }, bool) {
      commit('SELECT_ALL_OBJETOS', bool)
    },
    selectAllById({ commit }, { bool, id }) {
      commit('SELECT_ALL_OBJETOS_ID', { bool, id })
    },
    selectAllAferidos({ commit }, bool) {
      commit('SELECT_ALL_AFERIDOS', bool)
    },
    selectAferidosById({ commit }, { bool, id }) {
      commit('SELECT_AFERIDOS_BY_ID', { bool, id })
    },
  },
}
