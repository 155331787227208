<template>
  <div class="mb-5">
    <b-card
      no-body
      class="mb-2"
    >
      <validation-observer
        #default="context"
        ref="validationCep"
      >
        <b-form @submit.prevent="context.handleSubmit(onSubmit)">
          <b-card-header class="p-2">
            <b-card-title class="font-medium-2 card-title">
              <feather-icon
                icon="PackageIcon"
                size="18"
              />
              <span class="align-middle ml-50"> Aferição </span>
            </b-card-title>
          </b-card-header>
          <hr class="my-0">
          <div class="m-2">
            <validation-observer #default="{handleSubmit}">
              <b-form @submit.prevent="handleSubmit(onSubmit())">
                <b-row>
                  <b-col
                    md="3"
                  >
                    <validation-provider
                      #default="validationContext"
                      v-model="dateRange"
                      name="date-range"
                    >
                      <date-range :date-range.sync="dateRange"/>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="3"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <b-form-group
                      label="Cliente"
                      label-for="cliente"
                    >
                      <v-select
                        id="cliente"
                        v-model="filtros.clienteId"
                        :options="selectClientes"
                        :reduce="val=>val.value"
                        :clearable="true"
                        placeholder="Todos"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="3"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <b-input-group class="input-adjustment">
                      <b-form-input
                        v-model="filtros.shipmentId"
                        placeholder="Shippment Id"
                      />
                      <b-input-group-append>
                        <b-input-group-text>
                          <feather-icon
                            icon="MapPinIcon"
                            size="15"
                          />
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-col
                    md="3"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <b-input-group class="input-adjustment">
                      <b-form-input
                        v-model="filtros.cte"
                        placeholder="CTE"
                      />
                      <b-input-group-append>
                        <b-input-group-text>
                          <feather-icon
                            icon="UserIcon"
                            size="15"
                          />
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-col
                    md="3"
                    class="col-md-12 d-flex justify-content-end p-1"
                  >
                    <b-row class="flex-row-reverse">
                      <div style="display:flex; align-items: center; justify-content: center">
                        <b-button
                          variant="primary"
                          class="mb-sm-0 mr-0 mr-sm-1"
                          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                          type="submit"
                        >
                          Pesquisar
                          <feather-icon
                            class="ml-1"
                            icon="SearchIcon"
                          />
                        </b-button>
                      </div>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card
      v-show="showResultados"
      no-body
      class="mb-2"
    >
      <b-card-header>
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="TagIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Resultados
          </span>
        </b-card-title>
        <b-col
          md="3"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            class="px-3"
            @click="gerarExcel"
          >
            <span class="text-nowrap">Exportar Excel</span>
          </b-button>
        </b-col>
      </b-card-header>
      <hr class="my-0">
      <div class="m-2">
        <b-row>
          <b-col
            md="6"
            class="col-md-6 p-1"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
                debounce="1000"
              />
            </div>
          </b-col>
          <b-col
            md="6"
            class="col-md-6 d-flex align-items-center justify-content-start mb-1 mb-md-0 p-1"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>movimentações por página</label>
          </b-col>
        </b-row>
      </div>
      <template>
        <b-table
          ref="refAfericaoTable"
          responsive
          class="position-relative pb-3"
          :items="movimentacoesAferidas"
          :fields="column"
          :filter="search"
          :per-page="perPage"      
          :current-page="currentPage"
          show-empty
          stacked="md"
          empty-text="Não há aferições nessa data."
        >
          <template #head(select)="{}">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                v-model="allSelected"
                @change="selectAllHandler"
              />
            </div>
          </template>
          <template #cell(select)="data">
            <div class="d-flex justify-content-center">
              <b-checkbox 
                v-model="data.item.selected"
                @change="selecionaMov($event, data.item.movimentacaoId)"
              />
            </div>
          </template>
          <template #cell(cep)="data">
            <div>
              {{ data.item.cep ? maskCep(`${data.item.cep}`) : '' }}
            </div>
          </template>
          <template #cell(dataAfericao)="data">
            <div>
              {{ data.item.dataAfericao ? maskDateHour(`${data.item.dataAfericao}`) : '' }}
            </div>
          </template>
          <template #cell(valorNotaFiscal)="data">
            <span>
              {{ data.item.valorNotaFiscal ? maskCurrency(data.item.valorNotaFiscal) : '' }}
            </span>
          </template>
        </b-table>
        <div class="m-2">
          <b-row v-if="totalRows > 0">
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Mostrando do 
                {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
                {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                  totalRows
                }} resultados</span>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import { onBeforeMount, reactive, ref, toRefs, watch } from '@vue/composition-api'
import DateRange from '@/components/form/DateRange.vue'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import ExcelComposition from '@/components/exportar/excel.composition'
import store from '@/store'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import utils from '@/helpers/utils'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/no-extraneous-dependencies
import { format, subHours } from 'date-fns'

export default {
  name: 'Afericao',
  components: { DateRange },
  setup() {
    const { appCarregando } = $themeConfig.app
    const clientesStore = createNamespacedHelpers('clientes')
    const movimentacoesStore = createNamespacedHelpers('movimentacoes')
    const { findAfericao, findAferiaoExportacao } = movimentacoesStore.useActions(['findAfericao', 'findAferiaoExportacao'])
    const { downloadSpreadsheetWithOneHeader } = ExcelComposition()
    const refAfericaoTable = ref(null)
    const showResultados = ref(false)

    const state = reactive({
      dateRange: {
        startDate: utils.dateRangeComTimeZone(),
        endDate: utils.dateRangeComTimeZone(),
      },
      clientes: clientesStore.useGetters(['clientes']).clientes,
      movimentacoesAferidas: movimentacoesStore.useGetters(['movimentacoesAferidas']).movimentacoesAferidas,
      selectClientes: [],
      filtros: {
        shipmentId: null,
        cte: null,
        clienteId: null,
        dataInicial: new Date().toISOString().split('T')[0],
        dataFinal: new Date().toISOString().split('T')[0],
      },
      allSelected: false,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      carregando: false,
      search: '',
      order: {},
    })

    watch(() => state.clientes, value => {
      value.forEach(elemento => {
        if (elemento.nomeFantasia !== 'Sem Cadastro') {
          state.selectClientes.push({ label: elemento.nomeFantasia,
            value: elemento.clienteId })
        }
      })
    })

    onBeforeMount(() => {
      store.dispatch('clientes/findAll')
    })

    const sendRequest = filtros => {
      appCarregando.show()
      findAfericao(filtros).then(() => {
        appCarregando.hide()
        showResultados.value = true
      })
    }

    const resetDados = () => {
      state.currentPage = 1
      state.totalRows = 0
      state.search = ''
      state.order = {}
    }

    const onSubmit = () => {
      resetDados()
      const { shipmentId, cte, clienteId } = state.filtros
      const filtro = {
        shipmentId,
        cte,
        clienteId,
        dataFinal: state.dateRange.endDate,
        dataInicial: state.dateRange.startDate,
      }
      sendRequest(filtro)
    }

    const column = [
      { key: 'select', sortable: false },
      { key: 'shipmentId', label: 'shipmentId / Chave de nota', sortable: true },
      { key: 'cte', sortable: true },
      { key: 'dataAfericao', label: 'DataHora Aferição', sortable: true },
      { key: 'petoTotal', sortable: true },
      { key: 'volumes', sortable: true },
      { key: 'taxas', sortable: true },
      { key: 'valorNotaFiscal', label: 'VD', sortable: true },
    ]

    const downloadExcel = async () => {
      const dadosSelected = refAfericaoTable.value.sortedItems.filter(e => e.selected)
      if (dadosSelected.length === 0) {
        swal.fire({
          icon: 'warning',
          title: 'Sem dados para exportar',
          text: 'Selecione ao menos uma aferição para ser exportada.',
          showConfirmButton: false,
          timer: 3000,
        })
      } else {
        const movIds = dadosSelected.map(d => d.movimentacaoId)
        const data = await findAferiaoExportacao(movIds)
        const dados = data.map(d => ({
          shipmentId: d.shipmentId,
          cte: d.cte,
          cep: d.cep,
          uf: d.uf,
          cidade: d.cidade,
        }))
        dados.sort((a, b) => {
          const ufComparison = a.uf.localeCompare(b.uf)
          if (ufComparison !== 0) {
            return ufComparison
          } 
          return a.cidade.localeCompare(b.cidade)
        })
        const now = new Date()
        const gmt3Time = subHours(now, 3)

        const formattedDate = format(gmt3Time, 'ddMMyyyy - HHmmss')
        const nomeRelatorio = `DadosAferido ${formattedDate}`
        const heading = [['Shipment ID / Chave de Nota', 'CTE', 'CEP', 'UF', 'Cidade']]
        downloadSpreadsheetWithOneHeader(nomeRelatorio, heading, dados)
      }
    }

    const gerarExcel = () => {
      setTimeout(() => {
        downloadExcel()
      }, 100)
    }

    watch(() => state.movimentacoesAferidas, value => {
      if (value && value.length) {
        state.totalRows = value.length
      } else {
        state.totalRows = 0
      }
    })

    const selectAllHandler = bool => {
      store.dispatch('movimentacoes/selectAllAferidos', bool)
    }

    const selecionaMov = (bool, id) => {
      store.dispatch('movimentacoes/selectAferidosById', { bool, id })
    }

    const reset = () => {
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      reset,
    )
    return {
      ...toRefs(state),
      ...utils,
      resetForm,
      refFormObserver,
      refAfericaoTable,
      getValidationState,
      onSubmit,
      column,
      selecionaMov,
      selectAllHandler,
      gerarExcel,
      showResultados,
    }
  },
}
</script>
<style scoped>
  .form-group {
    min-width: 100%;
  }
  .input-adjustment {
    padding-top: 0.5rem;
  }
</style>
