import utils from '@/helpers/utils'
import httpClient from './http-clients/portaljad'

const baseUrl = 'cep'

export default class CepService {
  static async buscarEndereco(cep) {
    let cepNum
    const cepString = typeof cep === 'string' ? cep : cep.toString()
    if (cepString.length > 8) {
      cepNum = utils.removeMask(cep, '#####-###')
    } else if (cepString.length === 8) {
      cepNum = cep
    } else {
      return null
    }
    const { data } = await httpClient.get([baseUrl, cepNum].join('/'))
    return data
  }
}