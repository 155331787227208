import HttpClient from './http-clients/portaljad'

const baseUrl = 'cliente-ftp'

export default class ClienteFtpService {
  static async findByClienteId(clienteId) {
    try {
      const { data } = await HttpClient.get([baseUrl, clienteId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async deleteFtp(ftpId) {
    try {
      const { data } = await HttpClient.delete([baseUrl, ftpId].join('/'))
      return data
    } catch (error) {
      throw error.response.data
    }
  }
}