import PermissaoService from '@/service/PermissaoService'

export default {
  namespaced: true,
  state: {
    permissao: null,
    permissoes: null,
    permissaoOptions: [],
  },
  getters: {
    permissao(state) {
      return state.permissao
    },
    permissoes(state) {
      return state.permissoes
    },
    permissaoOptions(state) {
      return state.permissaoOptions
    },
  },
  mutations: {
    UPDATE_PERFIL(state, permissao) {
      state.permissao = permissao
    },
    UPDATE_PERFIS(state, permissoes) {
      state.permissoes = permissoes
    },
    SAVE_PERFIL(state, permissao) {
      state.permissoes.push(permissao)
    },
    EDIT_PERFIL(state, permissao) {
      if (state.permissoes) {
        const index = state.permissoes.findIndex(item => item.permissaoId === permissao.permissaoId)
        Object.assign(state.permissoes[index], permissao)
      } else {
        state.permissao = permissao
      }
    },
    UPDATE_PERFIL_OPTIONS(state, permissaoOptions) {
      state.permissaoOptions = permissaoOptions
    },
  },
  actions: {
    async findById({ commit }, permissaoId) {
      const permissao = await PermissaoService.findById(permissaoId)
      commit('UPDATE_PERFIL', permissao)
      return permissao
    },
    async findAll({ commit }) {
      const permissoes = await PermissaoService.findAll()
      commit('UPDATE_PERFIS', permissoes)
      return permissoes
    },
    async save({ commit }, permissao) {
      const permissaoId = await PermissaoService.save(permissao)
      const permissaoToSave = { ...permissao }
      permissaoToSave.permissaoId = permissaoId
      commit('SAVE_PERFIL', permissao)
    },
    async edit({ commit }, { permissaoId, permissao }) {
      const edited = await PermissaoService.edit(permissaoId, permissao)
      commit('EDIT_PERFIL', edited)
    },
    setOptions({ commit, getters }) {
      const options = getters.permissoes.map(permissao => ({
        value: permissao.permissaoId,
        label: permissao.nome,
      }))
      commit('UPDATE_PERFIL_OPTIONS', options)
    },
  },
}