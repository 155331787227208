import ClienteService from '@/service/ClienteService'
import adapter from '@/adapters/ClienteAdapter'

export default {
  namespaced: true,
  state: {
    clientes: undefined,
    selected: undefined,
    clientesOptions: [],
  },
  getters: {
    clientes(state) {
      return state.clientes
    },
    selected(state) {
      return state.selected
    },
    clientesOptions(state) {
      return state.clientesOptions
    },
  },
  mutations: {
    UPDATE_CLIENTES(state, clientes) {
      state.clientes = clientes
    },
    UPDATE_SELECTED(state, cliente) {
      state.selected = cliente
    },
    SAVE_CLIENTE(state, cliente) {
      state.clientes.push(cliente)
    },
    EDIT_CLIENTE(state, cliente) {
      if (state.clientes) {
        const index = state.clientes.findIndex(cli => cli.clienteId === cliente.clienteId)
        Object.assign(state.clientes[index], cliente)
      } else {
        state.selected = cliente
      }
    },
    DELETE_CLIENTE(state, clienteId) {
      if (state.clientes) {
        const index = state.clientes.findIndex(cli => cli.clienteId === clienteId)
        state.clientes.splice(index, 1)
      }
    },
    UPDATE_CLIENTES_OPTIONS(state, clientesOptions) {
      state.clientesOptions = clientesOptions
    },
  },
  actions: {
    async findAll({ commit }) {
      const clientes = await ClienteService.findAll()
      commit('UPDATE_CLIENTES', clientes.map(cliente => adapter.toStore(cliente)))
      return clientes
    },
    async findById({ commit }, clienteId) {
      const cliente = await ClienteService.findById(clienteId)
      commit('UPDATE_SELECTED', adapter.toStore(cliente))
    },
    async save(_, multipart) {
      const data = await ClienteService.save(multipart)
      return data
    },
    async edit({ commit }, { clienteId, multipart }) {
      const data = await ClienteService.editWithMultipart(clienteId, multipart)
      if (data.success) {
        commit('EDIT_CLIENTE', data.body)
      }
      return data
    },
    async switchAtivo({ commit }, cliente) {
      const obj = {
        ...adapter.toApi(cliente),
        ativo: !cliente.ativo,
      }
      const data = await ClienteService.switchAtivo(cliente.clienteId)
      if (data.success) {
        commit('EDIT_CLIENTE', obj)
      }
    },
    async delete({ commit }, clienteId) {
      await ClienteService.delete(clienteId)
      commit('DELETE_CLIENTE', clienteId)
    },
    async setSelected({ commit }, selected) {
      commit('UPDATE_SELECTED', selected)
    },
    setOptions({ commit, getters }) {
      const options = getters.clientes.map(cliente => ({
        value: cliente.clienteId,
        label: cliente.nomeFantasia,
      }))
      commit('UPDATE_CLIENTES_OPTIONS', options)
    },
  },
}
