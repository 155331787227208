import HttpClient from '@/service/http-clients/portaljad'

const baseUrl = 'servico_frete'

export default class ServicoFreteService {
  static async findAll(empresaId) {
    try {
      const { data } = await HttpClient.get([baseUrl, empresaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async save(servicoFrete) {
    try {
      const { data } = await HttpClient.post(baseUrl, servicoFrete)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}
