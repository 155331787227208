import HttpClient from './http-clients/portaljad'

const baseUrl = 'regioes'

export default class RegioesService {
  static async findAll() {
    try {
      const { data } = await HttpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async uploadMultipart(multipartForm) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'with-sheet'].join('/'), multipartForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return data
    } catch (error) {
      throw error.response.data
    }
  }
}