import { reactive, ref, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default () => {
  const faturasStore = createNamespacedHelpers('faturasCliente')
  const tableColumns = [
    { key: 'clienteNome', label: 'Nome Cliente', sortable: true },
    { key: 'dataImportacao', sortable: true },
    { key: 'dataEmissao', sortable: true },
    { key: 'dataVencimento', sortable: true },
    { key: 'urlFatura', label: 'Fatura', sortable: true },
    { key: 'urlBoleto', label: 'Boleto', sortable: true },
    { key: 'urlZip', label: 'Zip', sortable: true },
    { key: 'valor', sortable: true },
    { key: 'ações' },
  ]

  const state = reactive({
    faturas: faturasStore.useGetters(['getFaturas']).getFaturas,
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    perPageOptions: [10, 25, 50, 100],
  })

  const search = ref('')
  const sortBy = ref('faturaId')
  const isSortDirDesc = ref(false)
  const addFaturaSidebarActive = ref(false)

  return {
    ...toRefs(state),
    // TABLE CONFIGS,
    search,
    sortBy,
    isSortDirDesc,
    tableColumns,
    // SIDEBAR
    addFaturaSidebarActive,
  }
}