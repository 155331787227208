import RegioesJadlogService from '@/service/RegiaoJadlogService'

export default {
  namespaced: true,
  state: {
    regioes: [],
  },
  getters: {
    regioes(state) {
      return state.regioes
    },
  },
  mutations: {
    UPDATE_REGIOES(state, regioes) {
      state.regioes = regioes
    },
  },
  actions: {
    async findAll({ commit }) {
      const regioes = await RegioesJadlogService.findAll()
      commit('UPDATE_REGIOES', regioes)
    },
  },
}