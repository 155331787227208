import store from '@/store'
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default () => {
  const movimentosStore = createNamespacedHelpers('movimentos')

  const tableColumns = [
    { key: 'usuarioNome', sortable: true, label: 'Importado por' },
    { key: 'dataImportacao', sortable: true, label: 'Data' },
    { key: 'dataSelecionada', sortable: true, label: 'Período das Postagens' },
    { key: 'quantidadeObjetos', sortable: true, label: 'Objetos' },
    { key: 'metodoImportacao', sortable: true, label: 'Método' },
    { key: 'nomeArquivoImportado', sortable: false, label: 'Arquivo' },
    { key: 'remover', label: 'remover', sortable: false },
  ]

  const state = reactive({
    movimentos: movimentosStore.useGetters(['movimentos']).movimentos,
    datasJaSelecionadas: [],
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    perPageOptions: [10, 25, 50, 100],
  })

  watch(() => state.movimentos, value => {
    state.currentPage = 1
    if (value && value.length) {
      state.totalRows = value.length
    } else {
      state.totalRows = 0
    }
  })

  const search = ref('')
  const sortBy = ref('dataImportacao')
  const isSortDirDesc = ref(false)
  const addMovimentoSidebarActive = ref(false)
  const tableRef = ref(null)

  const atualizaLista = () => {
    store.dispatch('movimentos/findAll').then(() => {
      const datasSelecionadas = []
      state.movimentos.forEach(movimento => {
        const data = movimento.dataSelecionada.split('T')[0]
        datasSelecionadas.push(data)
      })
      const datasSemDuplicadas = [...new Set(datasSelecionadas)]
      const datasFiltradas = []
      datasSemDuplicadas.reduce((acc, date) => {
        const anoMes = date.slice(0, 7)
        const dia = date.slice(8, 10)
        if (!datasFiltradas[anoMes]) {
          datasFiltradas[anoMes] = []
        }
        datasFiltradas[anoMes].push(dia)
        return acc
      }, {})
      state.datasJaSelecionadas = datasFiltradas
    })
  }

  const onFiltered = filteredItems => {
    state.currentPage = 1
    state.totalRows = filteredItems.length
  }

  atualizaLista()

  return {
    ...toRefs(state),
    atualizaLista,
    // TABLE CONFIGS,
    search,
    sortBy,
    isSortDirDesc,
    tableColumns,
    tableRef,
    //
    onFiltered,
    // SIDEBAR
    addMovimentoSidebarActive,
  }
}
