<template>
  <div>
    <b-card
      no-body
      class="mt-2 mb-0 p-2"
    >
      <b-row
        align-v="center"
        class="mr-1"
      >
        <b-col>
          <b-media class="mb-2 align-items-center">
            <template #aside>
              <b-avatar
                ref="previewEl"
                :text="usuarioSelecionado.email.slice(0, 2)"
                variant="light-success"
                size="90px"
                rounded
              />
            </template>
            <h4 class="mb-2">
              {{ usuarioSelecionado.email }}
            </h4>
          </b-media>
        </b-col>
      </b-row>
      <validation-observer
        #default="{ validateWithInfo, handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="validaCampos(validateWithInfo), handleSubmit(onSubmit)"
        >
          <b-row>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="E-mail"
                rules="required|email"
              >
                <b-form-group
                  label="E-mail"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="usuarioEditado.email"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="usuario@dominio.com"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="Senha"
                vid="senha"
              >
                <b-form-group
                  label="Senha"
                  label-for="senha"
                >
                  <b-form-input
                    id="senha"
                    v-model="novaSenha"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="*******"
                    type="password"
                    disabled
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="Repetir Senha"
                rules="confirmed:senha"
              >
                <b-form-group
                  label="Repetir Senha"
                  label-for="repetirSenha"
                >
                  <b-form-input
                    id="repetirSenha"
                    v-model="confirmarSenha"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="*******"
                    type="password"
                    disabled
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <!-- Nível -->
              <validation-provider
                v-slot="validationContext"
                name="Nível"
                rules="required"
              >
                <b-form-group
                  label="Nível"
                  label-for="select-nivel"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    id="select-nivel"
                    v-model="usuarioEditado.nivel"
                    name="asdas"
                    :state="getValidationState(validationContext)"
                    :reduce="val=>val.value"
                    :options="nivelOptions"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-alert
                show
                variant="danger"
              >
                *Obs.: Caso queira alterar a senha, peça para o usuário acessar o link e redefinir a senha: https://cliente.dev.portaljad.com.br/auth/forgot-password
              </b-alert>
              <b-button
                variant="primary"
                class="my-1 mb-sm-0 mx-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                Salvar
              </b-button>
              <b-button
                class="my-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="$emit('fechar')"
              >
                Voltar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import utils from '@/helpers/utils'
import UsuariosEditarComposition from '../editar/usuarios-editar.composition'

export default {
  name: 'EditarUsuario',
  props: {
    usuarioSelecionado: {
      type: undefined,
      required: true,
    },
  },
  data() {
    return {
      usuarioEditado: undefined,
      novaSenha: '',
      confirmarSenha: '',
    }
  },
  watch: {
    novaSenha() {
      if (this.novaSenha === '') {
        this.confirmarSenha = ''
      }
    },
  },
  created() {
    this.usuarioEditado = this.usuarioSelecionado
    this.usuarioEditado.departamentos = this.usuarioSelecionado.departamentos
      .map(item => ({
        label: item.nomeDepartamento,
        value: item.departamentoId,
      }))
  },
  methods: {
    validaCampos(validateWithInfo) {
      validateWithInfo().then(validate => {
        if (!validate.isValid) {
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Há erros no formulário. Verifique!',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
        }
      })
    },
    alertaSalvar() {
      return this.$swal({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Deseja salvar as alterações?',
        showCancelButton: true,
        confirmButtonText: 'Sim, eu desejo',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    },
    salvarUsuario() {
      if (this.novaSenha) {
        this.usuarioEditado.novaSenha = this.novaSenha
      }
      const userId = this.usuarioEditado.usuarioId
      const departamentosList = []
      this.usuarioEditado.departamentos.forEach(dep => {
        this.departamentos.forEach(depto => {
          if (dep.value === depto.clienteDepartamentoId) {
            departamentosList.push({
              departamentoId: depto.clienteDepartamentoId,
              nomeDepartamento: depto.nomeDepartamento,
              codigoReferencia: depto.codigoReferencia,
              usuarioId: userId,
            })
          }
        })
      })
      const user = {
        ...this.usuarioEditado,
        departamentos: departamentosList,
      }
      this.$store.dispatch('usuarios/edit', {
        usuarioId: userId,
        usuario: user,
      })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Parabéns!',
            text: 'Você alterou os dados do usuário!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(erro => {
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Houve um erro, por favor tente novamente. ${erro}`,
                icon: 'AlertCircleIcon',
                variant: 'primary',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
        })
    },
    onSubmit() {
      this.alertaSalvar()
        .then(result => {
          if (result.value) {
            this.salvarUsuario()
          }
        })
    },
  },
  setup() {
    const nivelOptions = [
      { label: 'Administrador', value: 'ADMINISTRADOR' },
      { label: 'Usuário', value: 'USUARIO' },
      { label: 'Master', value: 'MASTER' },
    ]

    return {
      nivelOptions,
      ...UsuariosEditarComposition(),
      // Methods
      ...utils,
    }
  },
}
</script>

<style scoped>
.resumo-container {
  display:flex;
  background-color: #FAFAFA;
  border: 1px solid #BEC6E2;
  color:black;
}

.resumo {
  margin: auto 0;
  flex: 1;
}

.alert {
  padding: 0.71rem 1rem;
  border: 1px solid transparent;
}
</style>