<template lang="">
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Conta por página</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="tableRef"
        responsive
        class="position-relative"
        :items="dados"
        :fields="resultsTableColumns"
        show-empty
        stacked="md"
        :empty-text="carregando ? 'Carregando...' : 'Nenhum resultado encontrado.'"
        :filter="search"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="tarifaId"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        empty-filtered-text="Nenhum resultado encontrado com os filtros informados."
        @filtered="onFiltered"
      >
        <template #cell(situacaoPag)="data">
          <div>
            <b-img
              :src="retornoIconeSituacao(data.item)"
              class="icons-grid"
            />
          </div>
        </template>
        <template #cell(vencimento)="data">
          <div>
            {{ maskDate(data.item.vencimento) }}
          </div>
        </template>
        <template #cell(situacao)="data">
          <div>
            {{ ajusteSituacao(data.item ) }}
          </div>
        </template>
        <template #cell(valor)="data">
          <div>
            {{ data.item.valor ? maskCurrency(Number(data.item.valor)) : '' }}
          </div>
        </template>
        <template #cell(linkNFEPS)="data">
          <a 
            class="d-flex justify-content-center"
            :href="data.item.linkNFEPS"
            download
            _blank
          >
            <b-img
              fluid
              :src="filetypePdf"
              class="icons-grid"
            />
          </a>
        </template>
        <template #cell(linkFaturaBase64)="data">
          <a 
            class="d-flex justify-content-center"
            _blank
            @click="leituraBase64(data.item.linkFaturaBase64)"
          >
            <b-img
              fluid
              :src="filetypePdf"
              class="icons-grid"
            />
          </a>
        </template>
        <template 
          #cell(linkFaturaLocacaoBase64)="data"
        >
          <a
            v-if="data.item.linkFaturaLocacaoBase64"
            class="d-flex justify-content-center"
            _blank
            @click="leituraBase64(data.item.linkFaturaLocacaoBase64)"
          >
            <b-img
              fluid
              :src="filetypePdf"
              class="icons-grid"
            />
          </a>
        </template>
        <template #cell(boletos)="data">
          <div
            v-for="(item, index) in data.item.boletos"
            :key="index"
          >
            <a
              class="d-flex justify-content-center"
              :href="item.linkBoleto"
              download
              _blank
            >
              <b-img
                fluid
                :src="upc"
                class="icons-grid"
              />
            </a>
          </div>
        </template>
      </b-table>
      <div class="m-2">
        <b-row v-if="totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import { reactive, toRefs, watch, computed, ref } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import useAppConfig from '@core/app-config/useAppConfig'
import filetypePdf from '@icons/file-icons/filetypePdf.svg'
import calendar from '@icons/file-icons/calendar.svg'
import calendarCheck from '@icons/file-icons/calendarCheck.svg'
import calendarEvent from '@icons/file-icons/calendarEvent.svg'
import calendarMinus from '@icons/file-icons/calendarMinus.svg'
import calendarXFill from '@icons/file-icons/calendarXFill.svg'
import upc from '@icons/file-icons/upc.svg'
import Utils from '@/helpers/utils'

export default {
  name: 'MinhaConta',
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    const { appCarregando } = $themeConfig.app
    const { empresaId } = JSON.parse(localStorage.getItem('userName'))

    const faturasScc4Store = createNamespacedHelpers('faturasScc4')
    const { findAll } = faturasScc4Store.useActions(['findAll'])

    const search = ref('')
    const sortBy = ref('vencimento')
    const isSortDirDesc = ref(true)

    const state = reactive({
      dados: faturasScc4Store.useGetters(['getFaturas']).getFaturas,
      carregando: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
    })

    watch(() => state.carregando, value => {
      if (value) {
        appCarregando.show()
      } else {
        appCarregando.hide()
      }
    })

    watch(() => state.dados, value => {
      state.currentPage = 1
      if (value && value.length) {
        state.totalRows = value.length
      } else {
        state.totalRows = 0
      }
    })

    const checarAtraso = data => {
      const hoje = new Date()
      const parts = data.split('-')
      const year = parseInt(parts[0], 10)
      const month = parseInt(parts[1], 10) - 1
      const day = parseInt(parts[2], 10)
      const dataBoleto = new Date(year, month, day)
      return hoje > dataBoleto
    }

    const retornoIconeSituacao = data => {
      const { situacao, vencimento } = data
      if (situacao === 'PAGO') {
        return calendarCheck
      }
      if (vencimento != null) { 
        const vencido = checarAtraso(vencimento)
        if (vencido) {
          return calendarEvent
        }
      }
      if (situacao === 'CANCELADO') {
        return calendarXFill
      }
      if (situacao === 'PENDENTE') {
        return calendarMinus
      }
      if (situacao === 'FINALIZADO') {
        return calendar
      } return calendar
    }

    const fetchXML = async url => {
      try {
        const response = await fetch(url)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        return await response.json()
      } catch (error) {
        return null
      }
    }

    const extractBase64FromXML = async urlXml => {
      const base64 = await fetchXML(urlXml)
      if (base64) {
        return base64.pdf
      }
      return base64
    }

    const leituraBase64 = async valor => {
      const urlXml = `https://api.adm.scc4.com.br${valor}`
      const base64 = await extractBase64FromXML(urlXml)
      if (base64) {
        const source = `data:application/pdf;base64,${base64}`
        const link = document.createElement('a')
        link.href = source
        link.download = `Fatura-${valor.notaFiscal}.pdf`
        link.click()
      } else {
        swal.fire({
          icon: 'error',
          title: 'Erro ao baixar a fatura.',
          text: 'Entre em contato com nosso atendimento',
          showConfirmButton: true,
        })
      }
    }

    const resultsTableColumns = [
      { key: 'situacaoPag', label: '', thStyle: { width: '4rem' } },
      { key: 'vencimento', sortable: true, thStyle: { width: '12rem' } },
      { key: 'situacao', label: 'Situação', sortable: true, thStyle: { width: '10rem' } },
      { key: 'notaFiscal', label: 'Número NFE', sortable: true },
      { key: 'id', label: 'Número Pedido', sortable: true },
      { key: 'valor', sortable: true },
      { key: 'linkFaturaBase64', label: 'Pedido', thClass: 'text-center' },
      { key: 'linkNFEPS', label: 'Nota Fiscal', thClass: 'text-center' },
      { key: 'linkFaturaLocacaoBase64', label: 'Fatura', thClass: 'text-center' },
      { key: 'boletos', label: 'Boleto', thClass: 'text-center' },
    ]

    const ajusteSituacao = data => {
      const { situacao, vencimento } = data
      if (situacao === 'PENDENTE') {
        if (vencimento != null) { 
          const vencido = checarAtraso(vencimento)
          if (vencido) {
            return 'VENCIDO'
          }
        }
        return 'A VENCER'
      }
      return situacao
    }

    const onSubmit = () => {
      findAll(empresaId).then(() => {
        state.carregando = false
      })
    }

    const onFiltered = filteredItems => {
      state.currentPage = 1
      state.totalRows = filteredItems.length
    }

    onSubmit()
    return {
      ...toRefs(state),
      ...Utils,
      resultsTableColumns,
      retornoIconeSituacao,
      leituraBase64,
      ajusteSituacao,
      isDark,
      search,
      sortBy,
      onFiltered,
      upc,
      filetypePdf,
      isSortDirDesc,
    }
  },
}
</script>
<style lang="scss">
.icons-grid {
  width: 25px;
}
</style>