// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
const appLogoImageVermelho = require('@/assets/images/logo/vermelho_horizontal.png')
const appLogoImageVermelhoVertical = require('@/assets/images/logo/vermelho_vertical.png')
const appLogoBranco = require('@/assets/images/logo/branco_horizontal.png')
const appLogoBrancoVertical = require('@/assets/images/logo/branco_vertical.png')

const divLoadingImage = document.getElementById('loading-transparent')
const divCarregando = document.getElementById('div-loading-submits')
const appLoadingImage = {
  show: () => { divLoadingImage.style.display = 'block' },
  hide: () => { divLoadingImage.style.display = 'none' },
}
const appCarregando = {
  show: () => { divCarregando.style.display = 'block' },
  hide: () => { divCarregando.style.display = 'none' },
}

export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: '', // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImageVermelho, // Will update logo in navigation menu (Branding)
    appLogoImageVermelhoVertical,
    appLogoBranco,
    appLogoBrancoVertical,
    appLoadingImage,
    appCarregando,
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'primary', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'hidden', // static, sticky, hidden
    },
    customizer: false,
    enableScrollToTop: true,
  },
}
