import PesquisaService from '@/service/PesquisaService'

export default {
  namespaced: true,
  state: {
    dados: {
      lista: [],
    },
    movimentacaoIds: [],
  },
  getters: {
    dados(state) {
      return state.dados
    },
    movimentacaoIds(state) {
      return state.movimentacaoIds
    },
  },
  mutations: {
    SET_DADOS(state, dados) {
      state.dados = {
        ...dados,
      }
    },
    RESET_DADOS(state) {
      state.dados = {
        lista: [],
      }
    },
    RESET_MOVIMENTACAO_IDS(state) {
      state.movimentacaoIds = []
    },
    SET_MOVIMENTACAO_IDS(state, movimentacaoIds) {
      state.movimentacaoIds = movimentacaoIds
    },
    SELECT_ALL_OBJETOS(state, bool) {
      state.dados.movimentacaoIdsSelecionados.forEach(mov => {
        // eslint-disable-next-line no-param-reassign
        mov.selecionado = bool
      })
    },
  },
  actions: {
    async pesquisa({ commit }, obj) {
      if (obj.tipoPesquisa === 'NOVA_PESQUISA') {
        commit('RESET_DADOS')
        commit('RESET_MOVIMENTACAO_IDS')
      }
      const dados = await PesquisaService.pesquisa(obj)
      if (obj.tipoPesquisa === 'NOVA_PESQUISA' || obj.tipoPesquisa === 'ORDENACAO') {
        commit('SET_MOVIMENTACAO_IDS', dados.movimentacaoIds)
      }
      commit('SET_DADOS', dados)
    },
    async pesquisaExportar(_, obj) {
      const dados = await PesquisaService.pesquisaExportar(obj)
      return dados
    },
    selectAll({ commit }, bool) {
      commit('SELECT_ALL_OBJETOS', bool)
    },
  },
}