import ImpostoService from '@/service/ImpostoService'

export default {
  namespaced: true,
  state: {
    impostos: [],
    selected: {},
    impostosValor: [],
    impostosOptions: [
      { label: 'ICMS', value: 'ICMS' },
    ],
  },
  getters: {
    impostos(state) {
      return state.impostos
    },
    selected(state) {
      return state.selected
    },
    impostosOptions(state) {
      return state.impostosOptions
    },
  },
  mutations: {
    UPDATE_IMPOSTOS(state, impostos) {
      state.impostos = impostos
    },
    UPDATE_IMPOSTOS_VALOR(state, impostos) {
      state.impostosValor = impostos
    },
    UPDATE_SELECTED(state, selected) {
      state.selected = selected
    },
    SAVE_IMPOSTO(state, imposto) {
      state.impostos.push(imposto)
    },
    EDIT_IMPOSTO(state, imposto) {
      const index = state.impostos.findIndex(tar => tar.impostoId === imposto.impostoId)
      Object.assign(state.impostos[index], imposto)
    },
    DELETE_IMPOSTO(state, impostoId) {
      const index = state.impostos.findIndex(tar => tar.impostoId === impostoId)
      state.impostos.splice(index, 1)
    },
    UPDATE_IMPOSTOS_OPTIONS(state, impostosOptions) {
      state.impostosOptions = impostosOptions
    },
  },
  actions: {
    async findAll({ commit }) {
      const impostos = await ImpostoService.findAll()
      commit('UPDATE_IMPOSTOS', impostos)
    },
    async findById({ commit }, impostoId) {
      const imposto = await ImpostoService.findById(impostoId)
      commit('UPDATE_SELECTED', imposto)
    },
    async findValorByNome({ commit }, nome) {
      const imposto = await ImpostoService.findValorByNome(nome)
      commit('UPDATE_IMPOSTOS_VALOR', imposto)
    },
    async save(_, multipart) {
      const data = await ImpostoService.uploadMultipart(multipart)
      return data
    },
    async desativarImposto({ commit }, tipoImposto) {
      const imposto = await ImpostoService.desativarImposto(tipoImposto)
      commit('UPDATE_IMPOSTOS', imposto)
    },
    setOptions({ getters, commit }) {
      const options = getters.impostos.map(imposto => ({
        label: imposto.nomeimposto,
        impostoId: imposto.impostoId,
        servicoId: imposto.servicoId,
      }))
      commit('UPDATE_IMPOSTOS_OPTIONS', options)
    },
    setOptionsComNomeServico({ getters, commit }, servicos) {
      const impostos = getters.impostos.map(imposto => ({
        ...imposto,
        nomeServico: servicos.find(servico => servico.id === imposto.servicoId).nomeServico,
      }))
      commit('UPDATE_IMPOSTOS', impostos)
    },
  },
}