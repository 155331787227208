<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar Novo Cliente
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ validateWithInfo, handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="validaCampos(validateWithInfo), handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <h5 class="pb-1">
            Dados Gerais
          </h5>

          <validation-provider
            #default="validationContext"
            name="Razão Social"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Razão Social *"
              label-for="razaoSocial"
            >
              <b-form-input
                id="razao-social"
                v-model="cliente.razaoSocial"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Razão Social"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Nome Fantasia -->
          <validation-provider
            #default="validationContext"
            name="Nome Fantasia"
            rules="required"
          >
            <b-form-group
              label="Nome Fantasia *"
              label-for="nomeFantasia"
            >
              <b-form-input
                id="nome-fantasia"
                v-model="cliente.nomeFantasia"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nome Fantasia"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="cliente.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="cliente@dominio.com"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="validationContext"
            name="Telefone"
            rules="phone"
          >
            <b-form-group
              label="Telefone"
              label-for="telefone"
            >
              <b-form-input
                id="telefone"
                :key="cleaveKey"
                v-model="cliente.telefone"
                v-cleave="cleaveOptions.phone"
                :state="getValidationState(validationContext)"
                trim
                placeholder="(00) 00000-0000"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="validationContext"
            name="Tipo de Documento"
            rules="required"
          >
            <b-form-group
              label="Tipo de Documento *"
              label-for="tipoDocumento"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="cliente.tipoDocumento"
                :reduce="val=>val.value"
                :options="tipoDocumentoOptions"
                :clearable="false"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="validationContext"
            name="Documento"
            rules="required|noAlpha"
          >
            <b-form-group
              label="Documento *"
              label-for="documento"
            >
              <b-form-input
                id="documento"
                :key="cleaveKey+1"
                v-model="cliente.numeroDocumento"
                v-cleave="cliente.tipoDocumento ? cleaveOptions[cliente.tipoDocumento.toLowerCase()] : cleaveOptions.cpf"
                :state="getValidationState(validationContext)"
                trim
                placeholder="000.000.000-00 ou 00.000.000/0000-00"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="validationContext"
            name="Inscrição Estadual"
            rules="noAlpha|noSpecial"
          >
            <b-form-group
              label="Inscrição Estadual"
              label-for="inscricaoEstadual"
            >
              <b-form-input
                id="inscricaoEstadual"
                v-model="cliente.inscricaoEstadual"
                :state="getValidationState(validationContext)"
                trim
                placeholder="000000000"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <h5 class="py-1">
            Endereço
          </h5>

          <validation-provider
            v-slot="validationContext"
            name="CEP"
            rules="required|cep"
          >
            <b-form-group
              label="CEP *"
              label-for="cep"
            >
              <b-form-input
                id="cep"
                v-model="cliente.cep"
                v-cleave="cleaveOptions.cep"
                :state="getValidationState(validationContext)"
                placeholder="00000-000"
                @blur="buscaEndereco()"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="validationContext"
            name="Logradouro"
            rules="required"
          >
            <b-form-group
              label="Logradouro *"
              label-for="logradouro"
            >
              <b-form-input
                id="logradouro"
                v-model="cliente.logradouro"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Avenida Brasil"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="validationContext"
            name="Número"
            rules="required"
          >
            <b-form-group
              label="Número"
              label-for="numero"
            >
              <b-form-input
                id="numero"
                v-model="cliente.numero"
                :state="getValidationState(validationContext)"
                trim
                placeholder="100"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="validationContext"
            name="Complemento"
          >
            <b-form-group
              label="Complemento"
              label-for="complemento"
            >
              <b-form-input
                id="complemento"
                v-model="cliente.complemento"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Bloco B - Sala 210"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="validationContext"
            name="Bairro"
            rules="required"
          >
            <b-form-group
              label="Bairro *"
              label-for="bairro"
            >
              <b-form-input
                id="bairro"
                v-model="cliente.bairro"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Centro"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="validationContext"
            name="Cidade"
            rules="required"
          >
            <b-form-group
              label="Cidade *"
              label-for="cidade"
            >
              <b-form-input
                id="cidade"
                v-model="cliente.cidade"
                :state="getValidationState(validationContext)"
                trim
                placeholder="São Paulo"
                readonly
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="validationContext"
            name="UF"
            rules="required"
          >
            <b-form-group
              label="UF *"
              label-for="uf"
            >
              <b-form-input
                id="uf"
                v-model="cliente.uf"
                :state="getValidationState(validationContext)"
                trim
                placeholder="SP"
                readonly
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="logo"
            rules="size:100"
          >
            <b-form-group
              label="Logo do cliente"
              label-for="logo"
            >
              <b-form-file
                id="logo"
                v-model="logoFile"
                placeholder="Selecione uma imagem"
                :state="getValidationState(validationContext)"
                rop-placeholder="Solte o arquivo aqui"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <template #description>
                <small>
                  <feather-icon
                    icon="AlertTriangleIcon"
                  /> A imagem a ser importada deve possuir tamanho máximo de 100kB
                </small>
              </template>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import formValidation from '@core/comp-functions/forms/form-validation'
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import CepService from '@/service/CepService'
import cleaveOptions from '@/libs/cleave/options'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import _ from 'lodash'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'ClienteAddSidebar',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankCliente = {
      razaoSocial: '',
      nomeFantassia: '',
      telefone: '',
      email: '',
      tipoDocumento: '',
      numeroDocumento: null,
      inscricao: null,
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
      observacao: '',
      urlLogo: null,
      ativo: true,
    }
    const state = reactive({
      cliente: JSON.parse(JSON.stringify(blankCliente)),
      logoFile: null,
    })
    const cleaveKey = ref(0)
    const resetCliente = () => {
      state.cliente = JSON.parse(JSON.stringify(blankCliente))
    }

    const tipoDocumentoOptions = [
      { label: 'CPF', value: 'CPF' },
      { label: 'CNPJ', value: 'CNPJ' },
    ]

    const appLoading = $themeConfig.app.appLoadingImage

    const onSubmit = () => {
      appLoading.show()
      const multipart = new FormData()
      multipart.append('logoFile', state.logoFile)
      multipart.append('cliente', JSON.stringify(
        { 
          ...state.cliente,
          numeroDocumento: state.cliente.numeroDocumento ? state.cliente.numeroDocumento.replaceAll(/[.,()/\s-]+/g, '') : '',
          telefone: state.cliente.telefone.replaceAll(/[.,()/\s-]+/g, ''),
          cep: state.cliente.cep ? state.cliente.cep.replaceAll(/[.,()/\s-]+/g, '') : '',
        },
      ))
      store.dispatch('clientes/save', multipart)
        .then(data => {
          appLoading.hide()
          if (data.success) {
            emit('refresh')
            emit('update:active', false)
            Vue.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Cliente inserido com sucesso!',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              },
              {
                timeout: 5000,
                draggable: true,
                hideProgressBar: false,
              },
            )
          } else {
            swal.fire({
              icon: 'warning',
              title: 'Atenção!',
              text: `${data.body}`,
              showConfirmButton: true,
            })
          }
        })
    }
    function incrementCleaveKey() {
      cleaveKey.value += 1
    }

    watch(() => state.cliente.tipoDocumento, () => {
      incrementCleaveKey()
    })

    const buscaEndereco = () => {
      CepService.buscarEndereco(state.cliente.cep).then(endereco => {
        if (endereco) {
          if (endereco.success) {
            const dadosEndereco = JSON.parse(endereco.body)
            dadosEndereco.cep = state.cliente.cep
            delete dadosEndereco.nome
            delete dadosEndereco.id
            delete dadosEndereco.latitude
            delete dadosEndereco.longitude
            delete dadosEndereco.ibgeCidade
            delete dadosEndereco.ibgeCodUf
            delete dadosEndereco.ddd
            delete dadosEndereco.areaCodadeKm2
            state.cliente = { ...state.cliente, ...dadosEndereco }
          }
        }
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCliente)

    return {
      ...toRefs(state),
      onSubmit,
      tipoDocumentoOptions,
      cleaveKey,
      cleaveOptions: _.cloneDeep(cleaveOptions),
      //  Functions
      buscaEndereco,
      //  formValidation
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    validaCampos(validateWithInfo) {
      validateWithInfo().then(validate => {
        if (!validate.isValid) {
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Há erros no formulário. Verifique!',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
