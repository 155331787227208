import HttpClient from '@/service/http-clients/portaljad'

const baseUrl = 'faturas-scc4'

export default class PerfilService {
  static async findDiasVencidos(id) {
    try {
      const { data } = await HttpClient.get([baseUrl, id].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAll(id) {
    try {
      const { data } = await HttpClient.get([baseUrl, 'faturas', id].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}