import RegioesService from '@/service/RegiaoService'

export default {
  namespaced: true,
  state: {
    regioes: [],
  },
  getters: {
    regioes(state) {
      return state.regioes
    },
  },
  mutations: {
    SAVE_REGIAO(state, regiao) {
      state.regioes.push(regiao)
    },
    UPDATE_REGIOES(state, regioes) {
      state.regioes = regioes
    },
  },
  actions: {
    async findAll({ commit }) {
      const regioes = await RegioesService.findAll()
      commit('UPDATE_REGIOES', regioes)
      return regioes
    },

    async save({ commit }, multipart) {
      const data = await RegioesService.uploadMultipart(multipart)
      if (data.success) {
        commit('SAVE_REGIAO', data.body)
      }
      return data
    },
  },
}