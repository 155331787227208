<template>
  <div>
    <b-card>
      <small class="d-inline-block mb-2">
        *Cliente deverá enviar os arquivos NOTFIS 3.1 ou 5.0 para um FTP cadastrado
      </small>
      <b-form-row class="align-items-center">
        <b-col
          md="3"
        >
          <validation-provider
            #default="validationContext"
            name="Serviço Padrão Ftp"
            :rules="{ required: getFtpsExterno.ativo === true || getFtpsPortal.ativo === true }"
          >
            <b-form-group
              label="Serviço Padrão Ftp"
              label-for="servicoPadraoFtp"
            >
              <v-select
                id="servicoPadrao"
                v-model="cliente.servicoPadraoFtp"
                :state="getValidationState(validationContext)"
                :reduce="val=>val.value"
                :class="validationContext.errors[0] ? 'border-danger' : ''"
                :options="servicosDisponiveis(cliente.clienteServicos ? cliente.clienteServicos.filter(c => c.normal).map(cs => cs.servicoId) : [])"
                placeholder="Selecione um serviço"
              />
            </b-form-group>
            <span
              v-if="validationContext.errors[0]"
              class="undermessage-error text-danger"
            >
              {{ validationContext.errors[0] }} quando um serviço estiver ativo.
            </span>
          </validation-provider>
        </b-col>
      </b-form-row>
    </b-card>
    <b-card>
      <h5 class="mb-2">
        Dados FTP Portal Envios
      </h5>
      <ftp-data
        v-if="Object.keys(ftpPortalData).length >= 4"
        :data="ftpPortalData"
        :index="1"
        :unico="Object.keys(ftpExternoData).length >= 4"
      />
      <small 
        v-else
        class="d-inline-block mb-2 ml-1"
      >
        *Para Ativar o serviço entre em contato com o suporte do Portal Envios
      </small>
    </b-card>
    <b-card>
      <h5 class="mb-2">
        Dados FTP Cliente
      </h5>
      <ftp-data
        :data="ftpExternoData"
        :index="2"
        :unico="Object.keys(ftpPortalData).length >= 4"
      />
    </b-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { mapGetters } from 'vuex'
import { reactive } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ClientesEditarComposition from '../clientes-editar.composition'
import FtpData from './components/ftpData'

export default {
  name: 'TabDadosFtp',
  components: { FtpData },
  props: { 
    data: {
      required: true,
      type: undefined,
    },
  },
  computed: {
    ...mapGetters('clienteFtp', ['getFtpsExterno', 'getFtpsPortal']),

    ftpExternoData() {
      return this.getFtpsExterno
    },
    ftpPortalData() {
      return this.getFtpsPortal
    },
  },
  setup() {
    const servicosStore = createNamespacedHelpers('servicos')
    const servicosState = reactive({
      servicosOptions: servicosStore.useGetters(['servicosOptions']).servicosOptions,
      dadosServico: [],
    })
    const servicosDisponiveis = ids => {
      const servList = []
      if (servicosState.servicosOptions) {
        servicosState.servicosOptions.forEach(s => {
          ids.forEach(id => {
            if (s.objValue.servicoId === id) {
              servList.push(s)
            }
          })
        })
      }
      return servList
    }
    return {
      ...ClientesEditarComposition(),
      servicosDisponiveis,
      avatarText,
    }
  },
}
</script>
