<template>
  <div>
    <importar-movimento-sidebar
      :active.sync="addMovimentoSidebarActive"
      :datas-ja-selecionadas="datasJaSelecionadas"
      @atualizaLista="atualizaLista"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row
          align-h="between"
        >
          <!-- Search -->
          <b-col
            md="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>
        <b-row
          align-h="between"
          class="mt-2"
        >
          <b-col
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>movimentos por página</label>
          </b-col>
          <b-col
            md="3"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="addMovimentoSidebarActive = true"
            >
              <span class="text-nowrap">Adicionar Movimento</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        id="my-table"
        ref="tableRef"
        responsive
        class="position-relative"
        :items="movimentos"
        :fields="tableColumns"
        :filter="search"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="movimentoId"
        :sort-by.sync="sortBy"
        show-empty
        stacked="md"
        empty-text="Nenhum movimento cadastrado"
        :sort-desc.sync="isSortDirDesc"
        empty-filtered-text="Nenhum resultado encontrado com os filtros informados."
        @filtered="onFiltered"
      >
        <template #cell(dataImportacao)="data">
          <span>{{ data.item.dataImportacao ? maskDateTime(data.item.dataImportacao) : "" }}</span>
        </template>
        <template #cell(dataSelecionada)="data">
          <span>{{ data.item.dataSelecionada ? maskDate(data.item.dataSelecionada) : "" }}</span>
        </template>
        <template #cell(nomeArquivoImportado)="data">
          <a
            v-if="data.item.urlArquivoImportado"
            :href="`${data.item.urlArquivoImportado}`"
            class="text-secondary"
            target="_self"
          >
            <feather-icon
              icon="DownloadIcon"
              size="18"
            /> {{ data.item.nomeArquivoImportado }}
          </a>
        </template>
        <template #cell(remover)="data">
          <feather-icon
            icon="TrashIcon"
            cursor="pointer"
            @click="removerImportacao(data.item.movimentoImportacaoId)"
          />
        </template>
      </b-table>
      <div class="m-2">
        <b-row v-if="totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import utils from '@/helpers/utils'
import { mapGetters } from 'vuex'
import store from '@/store'
import { $themeConfig } from '@themeConfig'
import MovimentosComposition from './movimentos.composition'
import ImportarMovimentoSidebar from './components/importar-movimento-sidebar'

export default {
  name: 'IndexVue',
  components: { ImportarMovimentoSidebar },
  computed: {
    ...mapGetters({
      firebaseUser: 'firebase/firebaseUser',
    }),
  },
  watch: {
    firebaseUser() {
      this.atualizaLista()
    },
  },
  setup() {
    const appLoading = $themeConfig.app.appLoadingImage
    const { atualizaLista } = MovimentosComposition()

    const errorMessage = quantidade => {
      if (quantidade === 0) {
        return '0 cte\'s deletados, podem ter sido deletados em arquivos repetidos'
      } return quantidade
    }

    const removerImportacao = importacaoId => {
      swal.fire({
        icon: 'warning',
        title: 'Atenção!',
        html: 'Os movimentos deste arquivo serão removidos do sistema. <br><br>Deseja continuar?',
        showCancelButton: true,
        confirmButtonText: 'Sim, eu desejo',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          appLoading.show()
          store.dispatch('movimentos/removerImportacoes', importacaoId).then(response => {
            if (response && response.success) {
              appLoading.hide()
              swal.fire({
                icon: 'success',
                html: `Deletado com sucesso! <br> ${errorMessage(response.body)} removidos`,
                showConfirmButton: true,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => {
                atualizaLista()
              })
            }
          }).catch(() => {
            appLoading.hide()
            swal.fire({
              icon: 'erro',
              title: 'Erro ao deletar!',
              showConfirmButton: true,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    return { 
      ...MovimentosComposition(), 
      ...utils,
      removerImportacao,
    }
  },
}
</script>

<style scoped>

</style>