import HttpClient from './http-clients/portaljad'

const baseUrl = 'movimentos'

export default class MovimentoService {
  static async findAll() {
    try {
      const { data } = await HttpClient.get(baseUrl)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async uploadMultipart(multipartForm) {
    try {
      const { data } = await HttpClient.post([baseUrl, 'with-sheet'].join('/'), multipartForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (!data.success) {
        throw Error(data.body)
      }
      return data.body
    } catch (error) {
      throw error.message
    }
  }

  static async deleteImport(importacaoId) {
    try {
      const { data } = await HttpClient.delete([baseUrl, importacaoId].join('/'))
      return data
    } catch (error) {
      throw error.response.body
    }
  }
}