<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-blg"
    shadow
    no-header
    right
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Tracking CTE
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <div class="test">
        <div class="headerInfo">
          <label>REMESSA: {{ cte }} </label>
          <label v-if="eventos[0]">STATUS: {{ eventos[0].ultimoEvento }}</label>
          <label v-if="eventos[0]">DATA: {{ eventos[0].dataUltimoEvento ? maskDateHour(eventos[0].dataUltimoEvento) : '' }}</label>
          <label v-if="eventos[0]">RECEBEDOR: {{ eventos[0].nomeRecebedor ? eventos[0].nomeRecebedor : (eventos[0].arquivoRecebedor != null ? 'vide foto comprovante' : 'Não informado') }}</label>
        </div>
        <div class="px-2 pb-1">
          <b-table
            ref="tableRef"
            responsive
            class="position-relative"
            :items="eventos"
            :fields="resultsTableColumns"
            show-empty
            stacked="md"
            empty-text="Nenhum resultado encontrado."
          >
            <template #cell(dataEvento)="data">
              <div>
                {{ data.item.dataEvento ? maskDateHour(data.item.dataEvento) : '' }}
              </div>
            </template>
          </b-table>
        </div>
        <div
          v-if="eventos && eventos[0] && eventos[0].arquivoRecebedor != null"
          class="px-2"
        >
          <h5>Imagem</h5>
          <img
            :src="eventos[0].arquivoRecebedor"
            style="width: 100%; height: 200px;"
          >
        </div>
      </div>
      <template>
        <div class="d-flex text-light align-items-center px-3 py-2">
          <b-button
            size="md"
            variant="primary"
            class="px-3"
            @click="hide"
          >Ok</b-button>
        </div>
      </template>
    </template>
  </b-sidebar>

</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import { reactive, toRefs, watch } from '@vue/composition-api'
import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import utils from '@/helpers/utils'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'CteTrackingSidebar',
  emits: ['aoFechar'],
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const movimentacaoEventosStore = createNamespacedHelpers('movimentacaoEvento')

    const { findByCte } = movimentacaoEventosStore.useActions(['findByCte'])

    const appLoading = $themeConfig.app.appLoadingImage
    
    const resultsTableColumns = [
      { key: 'dataEvento', sortable: true, label: 'Data / Hora', thStyle: { whiteSpace: 'nowrap' } },
      { key: 'unidade', sortable: true, label: 'Ponto Origem', thStyle: { whiteSpace: 'nowrap' } },
      { key: 'evento', sortable: true, label: 'Status' },
      { key: 'destino', sortable: true, label: 'Destino', thStyle: { whiteSpace: 'nowrap' } },
      { key: 'documento', sortable: true, label: 'Documento' },
    ]

    const blankEventos = []

    const state = reactive({
      eventos: _.cloneDeep(blankEventos),
      cte: movimentacaoEventosStore.useGetters(['getCte']).getCte,
      dataEnvio: movimentacaoEventosStore.useGetters(['getDataEnvio']).getDataEnvio,
      eventosApi: movimentacaoEventosStore.useGetters(['getEventos']).getEventos,
    })

    watch(() => state.cte, value => {
      if (value) {
        appLoading.show()
        try {
          findByCte(value).then(() => {
            appLoading.hide()
          })
        } catch {
          appLoading.hide()
          swal.fire({
            icon: 'warning',
            title: 'Atenção!',
            text: 'Erro ao pesquisar por CTE',
            showConfirmButton: true,
          })
        }
      }
    })

    watch(() => props.active, value => {
      if (value) {
        state.eventos = _.cloneDeep(blankEventos)
      } else {
        emit('aoFechar')
      }
    })

    watch(() => state.eventosApi, value => {
      state.eventos = value
    })

    return {
      ...toRefs(state),
      ...utils,
      resultsTableColumns,
    }
  },
}
</script>
<style lang="scss" scoped>
.headerInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 30px;
  label {
    padding-bottom: 5px;
  }
}

.test {
  flex-grow: 1;
  height: 82%;
  overflow-y: auto;
}
</style>