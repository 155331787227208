//  ESSA INSTANCIA DO FIREBASE É UTILIZADA PARA CADASTRAR USUÁRIOS COM SENHA
//  SEM EXECUTAR O LOGIN AUTOMÁTICO DO USUÁRIO CADASTRADO
//  fonte: https://stackoverflow.com/questions/37517208/firebase-kicks-out-current-user/38013551#38013551
//  fonte adicional: https://groups.google.com/g/firebase-talk/c/zYatdVy1QfU/m/b-qI7iWeAAAJ
import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAhXK8cUdakXkuk1v_6zy37NWJdCtaIqhA',
  authDomain: 'portal-jadlog.firebaseapp.com',
  projectId: 'portal-jadlog',
  storageBucket: 'portal-jadlog.appspot.com',
  messagingSenderId: '915583125161',
  appId: '1:915583125161:web:9d71f960c1d4046d278bb8',
  measurementId: 'G-W65NER3Z62',
}

export default firebase.initializeApp(firebaseConfig, 'Secondary')
