export default class MenuAdapter {
  static toStore(menuItems) {
    const response = []
    menuItems.forEach(item => response.push(...MenuAdapter.toStoreItem(item)))
    return response
  }

  static toStoreItem(menuItem) {
    if (menuItem.obs === 'HEADER') {
      const children = menuItem.children && menuItem.children.length > 0 ? MenuAdapter.toStore(menuItem.children) : []
      return [{ header: menuItem.nomeItem }, ...children]
    }
    return [{
      title: menuItem.nomeItem,
      icon: menuItem.icon,
      route: menuItem.nomePagina,
      children: menuItem.children && menuItem.children.length > 0
        ? MenuAdapter.toStore(menuItem.children)
        : null,
    }]
  }
}
