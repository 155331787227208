<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          sm="2"
        >
          <validation-provider
            #default="validationContext"
            name="CEP"
            rules="required|cep"
          >
            <b-form-group
              label="CEP"
              label-for="cep"
            >
              <b-form-input
                id="cep"
                v-model="cliente.cep"
                v-cleave="cleaveOptions.cep"
                :state="getValidationState(validationContext)"
                trim
                placeholder="00000-000"
                @blur="buscaEndereco()"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          sm="5"
        >
          <validation-provider
            #default="validationContext"
            name="Logradouro"
            rules="required"
          >
            <b-form-group
              label="Logradouro"
              label-for="logradouro"
            >
              <b-form-input
                id="logradouro"
                v-model="cliente.logradouro"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Av. Brasil"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          sm="2"
        >
          <validation-provider
            #default="validationContext"
            name="Número"
            rules="required"
          >
            <b-form-group
              label="Número"
              label-for="numero"
            >
              <b-form-input
                id="numero"
                v-model="cliente.numero"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="10"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          sm="3"
        >
          <validation-provider
            #default="validationContext"
            name="Complemento"
          >
            <b-form-group
              label="Complemento"
              label-for="complemento"
            >
              <b-form-input
                id="complemento"
                v-model="cliente.complemento"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Bloco B Apartamento 300"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          sm="5"
        >
          <validation-provider
            #default="validationContext"
            name="Bairro"
            rules="required"
          >
            <b-form-group
              label="Bairro"
              label-for="bairro"
            >
              <b-form-input
                id="bairro"
                v-model="cliente.bairro"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Centro"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          sm="3"
        >
          <validation-provider
            #default="validationContext"
            name="UF"
            rules="required"
          >
            <b-form-group
              label="UF"
              label-for="uf"
            >
              <b-form-input
                id="uf"
                v-model="cliente.uf"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="SP"
                readonly
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          sm="5"
        >
          <validation-provider
            #default="validationContext"
            name="Cidade"
            rules="required"
          >
            <b-form-group
              label="Cidade"
              label-for="cidade"
            >
              <b-form-input
                id="cidade"
                v-model="cliente.cidade"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="São Paulo"
                readonly
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <!-- TODO: Separar esse mapa em um componente reutilizável-->
      <l-map
        v-if="mapData && mapData.center && tabIndex === 1"
        ref="refLeafletMap"
        :key="leafletKey"
        :center="mapData.center"
        :zoom="mapData.zoom"
      >
        <l-tile-layer
          :url="mapData.url"
          :attribution="mapData.attribution"
        />
        <l-marker
          :lat-lng="mapData.center"
          :icon="mapData.icon"
        />
      </l-map>
    </b-card>
  </div>
</template>

<script>
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import { LMap, LMarker, LTileLayer } from 'vue2-leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import { icon } from 'leaflet'
import CepService from '@/service/CepService'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import ClientesEditarComposition from '../clientes-editar.composition'

export default {
  name: 'Endereco',
  components: {
    LMap,
    LMarker,
    LTileLayer,
  },
  props: {
    data: {
      type: undefined,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const clientesStore = createNamespacedHelpers('clientes')
    const { setSelected } = clientesStore.useActions(['setSelected'])
    const refLeafletMap = ref(null)
    const mapState = reactive({
      mapData: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        icon: icon({
          // eslint-disable-next-line global-require
          iconUrl: require('leaflet/dist/images/marker-icon.png'),
          iconSize: [20, 35],
          iconAnchor: [16, 37],
        }),
      },
      leafletKey: 0,
    })
    const geoSearchProvider = new OpenStreetMapProvider()
    const searchMap = () => {
      const queryStreet = `${props.data.numero || ''} ${props.data.logradouro || ''}`
      geoSearchProvider.search(
        {
          // https://nominatim.org/release-docs/latest/api/Search/#examples
          query: { 
            postcode: props.data.cep, 
            street: queryStreet,
            city: props.data.cidade, 
            country: 'brazil',
          },
        },
      ).then(results => {
        if (results.length > 0) {
          mapState.mapData = {
            ...mapState.mapData,
            center: [results[0].y, results[0].x],
            zoom: 17,
          }
        }
      })
    }
    searchMap()
    watch(() => props.data.logradouro, () => {
      searchMap()
    })

    const buscaEndereco = () => {
      CepService.buscarEndereco(props.data.cep).then(endereco => {
        if (endereco) {
          if (endereco.success) {
            const dadosEndereco = JSON.parse(endereco.body)
            dadosEndereco.cep = props.data.cep
            let { data } = props
            data = { ...data, ...dadosEndereco }
            setSelected(data).then(() => {
              searchMap()
            })
          }
        }
      })
    }

    return {
      // Data:
      ...ClientesEditarComposition(),
      ...toRefs(mapState),
      buscaEndereco,
      // refs
      refLeafletMap,
    }
  },
}
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
