import HttpClient from '@/service/http-clients/portaljad'

const baseUrl = 'perfis'

export default class PerfilService {
  static async findById(perfilId) {
    try {
      const { data } = await HttpClient.get([baseUrl, perfilId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAll(empresaId) {
    try {
      const { data } = await HttpClient.get([baseUrl, 'empresa', empresaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async findAllComPermissao(empresaId) {
    try {
      const { data } = await HttpClient.get([baseUrl, 'com-permissao', empresaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async save(perfil) {
    try {
      const { data } = await HttpClient.post(baseUrl, perfil)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async edit(perfilId, perfil) {
    try {
      const { data } = await HttpClient.put([baseUrl, perfilId].join('/'), perfil)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async delete(perfilId) {
    try {
      await HttpClient.delete([baseUrl, perfilId].join('/'))
      return perfilId
    } catch (error) {
      throw error.response.data
    }
  }
}