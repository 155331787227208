<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Inserir um usuário
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ validateWithInfo, handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          v-if="active"
          class="p-2"
          @submit.prevent="validaCampos(validateWithInfo), handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- E-mail -->
          <validation-provider
            #default="validationContext"
            name="E-mail"
            rules="required|email"
          >
            <b-form-group
              label="E-mail"
              label-for="email-usuario"
            >
              <b-form-input
                id="email-usuario"
                v-model="user.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="usuario@dominio.com"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Senha -->
          <validation-provider
            #default="validationContext"
            name="senha"
            rules="required"
          >
            <b-form-group
              label="Senha"
              label-for="senha-usuario"
            >
              <b-form-input
                id="senha-usuario"
                v-model="user.senha"
                type="password"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Senha"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Repetir Senha -->
          <validation-provider
            #default="validationContext"
            name="Repetir Senha"
            rules="required|confirmed:senha"
          >
            <b-form-group
              label="Repetir Senha"
              label-for="repetirSenha-usuario"
            >
              <b-form-input
                id="repetirSenha-usuario"
                v-model="user.repetirSenha"
                type="password"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Repetir Senha"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Nível -->
          <validation-provider
            v-slot="validationContext"
            name="Nível"
            rules="required"
          >
            <b-form-group
              label="Nível"
              label-for="select-nivel"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="select-nivel"
                v-model="user.nivel"
                :reduce="val=>val.value"
                :options="nivelOptions"
                :clearable="false"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar usuário
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import CepService from '@/service/CepService'
import cleaveOptions from '@/libs/cleave/options'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import _ from 'lodash'
import { $themeConfig } from '@themeConfig'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import firebaseAlt from '@/libs/firebase/secundary-instance'
import utils from '@/helpers/utils'
import { verifyErrorCode } from '@/libs/firebase/functions'

export default {
  name: 'UserAddSidebar',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    clienteId: {
      type: undefined,
      required: true,
    },
  },
  computed: {
    senha() {
      return this.user.senha
    },
  },
  watch: {
    senha() {
      if (this.user.senha === '') {
        this.confirmarSenha = ''
      }
    },
  },
  setup(props, { emit }) {
    const currentUser = JSON.parse(localStorage.getItem('userName'))

    const blankUser = {
      nome: '',
      email: '',
      senha: '',
      confirmarSenha: '',
      nivel: '',
      departamentos: [],
      clienteId: props.clienteId,
      empresaId: currentUser.empresaId,
      ativo: 1,
    }

    const departamentosStore = createNamespacedHelpers('departamentos')

    const state = reactive({ 
      user: { ...blankUser },
      departamentos: departamentosStore.useGetters(['departamentos']).departamentos,
      departamentosOptions: departamentosStore.useGetters(['departamentosOptions']).departamentosOptions,
    })
    const cleaveKey = ref(0)
    const resetUser = () => {
      state.user = { ...blankUser }
    }

    const nivelOptions = [
      { label: 'Administrador', value: 'ADMINISTRADOR' },
      { label: 'Usuário', value: 'USUARIO' },
      { label: 'Master', value: 'MASTER' },
    ]

    const appLoading = $themeConfig.app.appLoadingImage

    const error = codigoErro => {
      Vue.$toast(
        {
          component: ToastificationContent,
          props: {
            title: verifyErrorCode(codigoErro),
            icon: 'AlertCircleIcon',
            variant: 'primary',
          },
        },
        {
          timeout: 5000,
          draggable: true,
          hideProgressBar: false,
        },
      )
    }

    const onSubmit = () => {
      appLoading.show()
      firebaseAlt.auth().createUserWithEmailAndPassword(state.user.email, state.user.senha)
        .then(userCredential => {
          localStorage.setItem('userCredential', JSON.stringify(userCredential))
          const departamentosList = []
          state.user.departamentos.forEach(dep => {
            state.departamentos.forEach(depto => {
              if (dep.value === depto.clienteDepartamentoId) {
                departamentosList.push({
                  departamentoId: depto.clienteDepartamentoId,
                  nomeDepartamento: depto.nomeDepartamento,
                  codigoReferencia: depto.codigoReferencia,
                })
              }
            })
          })
          const userTosave = {
            ...state.user,
            senha: utils.encrypt(state.user.senha, state.user.email),
            firebaseUid: userCredential.user.uid,
            departamentos: departamentosList,
          }
          store.dispatch('usuarios/save', userTosave)
          const { user } = userCredential
          user.sendEmailVerification()
        })
        .then(() => {
          emit('refresh')
          emit('update:active', false)
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Usuário inserido com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Email Enviado!',
              icon: 'MailIcon',
              variant: 'success',
            },
          })
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não esqueça de verificar sua caixa de spam!',
              icon: 'MailIcon',
              variant: 'warning',
            },
          })
        })
        .catch(erro => {
          error(erro.code)
        })
        .finally(() => {
          appLoading.hide()
        })
    }
    function incrementCleaveKey() {
      cleaveKey.value += 1
    }

    watch(() => state.user.tipoDocumento, () => {
      incrementCleaveKey()
    })

    const buscaEndereco = () => {
      CepService.buscarEndereco(state.user.cep).then(endereco => {
        if (endereco) {
          if (endereco.success) {
            const dadosEndereco = JSON.parse(endereco.body)
            dadosEndereco.cep = state.user.cep
            state.user = { ...state.user, ...dadosEndereco }
          }
        }
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUser)

    return {
      ...toRefs(state),
      onSubmit,
      nivelOptions,
      cleaveKey,
      cleaveOptions: _.cloneDeep(cleaveOptions),
      //  Functions
      buscaEndereco,
      //  formValidation
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    validaCampos(validateWithInfo) {
      validateWithInfo().then(validate => {
        if (!validate.isValid) {
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Há erros no formulário. Verifique!',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
