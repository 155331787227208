import servicoFreteService from '@/service/ServicoFreteService'

export default {
  namespaced: true,
  state: {
    servicoFrete: null,
  },
  getters: {
    servicoFrete(state) {
      return state.servicoFrete
    },
  },
  mutations: {
    UPDATE_SERVICO_FRETE(state, servicoFrete) {
      state.servicoFrete = servicoFrete
    },
  },
  actions: {
    async findAll({ commit }, empresaId) {
      const servicoFrete = await servicoFreteService.findAll(empresaId)
      commit('UPDATE_SERVICO_FRETE', servicoFrete)
    },
    async save({ commit }, servicoFrete) {
      const salvos = await servicoFreteService.save(servicoFrete)
      commit('UPDATE_SERVICO_FRETE', salvos)
    },
  },
}