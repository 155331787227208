import store from '@/store'
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default () => {
  const impostosStore = createNamespacedHelpers('impostos')
  const tableColumns = [
    { key: 'id', label: 'Id', sortable: true },
    { key: 'tipoImposto', label: 'Tipo Imposto', sortable: true },
    { key: 'dataVigencia', sortable: true, label: 'Data Vigência' },
    { key: 'dataImportacao', sortable: true, label: 'Data Importação' },
    { key: 'ativo', sortable: true },
    { key: 'ações', sortable: false },
  ]

  const state = reactive({
    impostosRef: impostosStore.useGetters(['impostos']).impostos,
    impostos: [],
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    perPageOptions: [10, 25, 50, 100],
  })

  watch(() => state.impostosRef, value => {
    state.currentPage = 1
    if (value && value.length) {
      state.totalRows = value.length
    } else {
      state.totalRows = 0
    }
  })

  const search = ref('')
  const sortBy = ref('ativo')
  const isSortDirDesc = ref(true)
  const addImpostoSidebarActive = ref(false)
  const mostrarInativos = ref(false)

  const refresh = () => {
    store.dispatch('impostos/findAll').then(() => {
      if (!mostrarInativos.value) {
        state.impostos = state.impostosRef.filter(item => item.ativo === true)
      } else {
        state.impostos = state.impostosRef
      }
    })
  }

  const onFiltered = filteredItems => {
    state.currentPage = 1
    state.totalRows = filteredItems.length
  }

  refresh()
  watch(mostrarInativos, () => {
    refresh()
  })

  return {
    ...toRefs(state),
    // TABLE CONFIGS,
    search,
    sortBy,
    isSortDirDesc,
    tableColumns,
    mostrarInativos,
    refresh,
    onFiltered,
    // SIDEBAR
    addImpostoSidebarActive,
  }
}