<template>
  <b-card 
    :class="loaded ? 'loaded' : ''"
    class="p-2 mb-0"
  >
    <div
      v-if="loaded"
      class="loading-on"
    >
      <img
        src="../../../../assets/images/logo/icon.png"
        class="rotate-vert-center loading-logo-card"
        alt="Logo"
      >
    </div>
    <div v-else>
      <b-row>
        <b-col md="8">
          <div class="titulo">
            Faturamento
          </div>
          <div class="subtitulo">
            <div class="faturamento">
              {{ faturamentoTratado }}
            </div>
          </div>
        </b-col>
        <b-col
          md="4"
          class="subtitulo"
        >
          <div class="media-area">
            <p>Média Diária</p>
            <div class="valor-medio">
              {{ mediaDiaria() }}
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <vue-apex-charts
            type="line"
            :options="chartOptions"
            :series="series"
            class="chart"
          />
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import utils from '@/helpers/utils'
import { computed, ref, watch } from '@vue/composition-api'

export default {
  name: 'GraficoLinha',
  components: { VueApexCharts },
  props: {
    faturamentos: {
      type: Number,
      required: true,
    },
    faturamentosPorDia: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const loaded = computed(() => props.loading)

    const mediaDiaria = () => {
      if (props.faturamentosPorDia.length === 0) return (0).toFixed(2).toString().replace('.', ',')
      return utils.maskCurrency(Number((props.faturamentos / props.faturamentosPorDia.length)))
    }

    const series = ref([
      {
        name: 'Faturamento',
        data: [],
      },
    ])

    const chartOptions = ref({
      chart: {
        zoom: {
          enabled: false,
        },
      },
      colors: ['#F55559'],
      stroke: {
        curve: 'smooth',
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: '',
      },
      noData: {
        text: 'Loading...',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        labels: {
          formatter: value => utils.maskCurrency(Number(value)),
        },
      },
    })

    watch(() => props.faturamentosPorDia, value => {
      chartOptions.value = {
        ...chartOptions.value,
        ...{
          xaxis: {
            categories: value.map(dia => dia.diaFaturamento),
          },
        },
      }
      series.value = [{
        name: 'Faturamento',
        data: props.faturamentosPorDia.map(valor => valor.faturamento),
      }]
    })

    const faturamentoTratado = computed(() => (props.faturamentos ? utils.maskCurrency(Number(props.faturamentos)) : '0,00'))

    return {
      series,
      chartOptions,
      loaded,
      mediaDiaria,
      faturamentoTratado,
    }
  },
}
</script>

<style scoped lang="scss">
.titulo {
  font-size: 20px;
  font-weight: 500;
  color: #5E5873;
}

.loaded {
  height: 12em;
}

.subtitulo {
  font-weight: 400;
  font-size: 13px;
  color: #5E5873;

  .faturamento {
    display: inline;
    font-weight: 700;
    font-size: 23px;
    color: #61AA82;
  }

  .valor-medio {
    font-weight: 600;
    font-size: 18px;
    color: #6A718B;
  }

  .media-area div {
    display: inline;
  }
}

.card {
  min-height: 100%;
}

.loading-on {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-logo-card {
  height: 80px;
}
</style>